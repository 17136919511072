import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { DIMENSION_LABEL_TYPE } from "src/app/app.config";
import { HomeComponent } from "src/app/containers/home";
import { DropDownColorInputComponent } from "../dropdown-color-input/dropdown-color-input.component";
import { HomeComponent as HomeComponentRibSpan } from "src/app-ribspan/containers/home";
import { Router } from "@angular/router";

@Component({
  selector: "dialog-dimension",
  templateUrl: "./dialog-dimension.component.html",
  styleUrls: ["./dialog-dimension.component.scss"],
})
export class DialogDimensionComponent implements OnInit {
  bayIndex: number = 0;
  public dimValue = 12000;
  public baySizeMax: number = 3000;
  @Output("onSubmit") onSubmitEvent = new EventEmitter();
  @Output("onCancel") onCancelEvent = new EventEmitter();
  @Output() clickOutside = new EventEmitter<void>();
  visible: boolean = false;
  labelType: string = "";
  title: string = "";
  valueType: string = "number";
  minValue = 1;
  maxValue = 999999;
  bayMaxValue = 8000;
  bayMinValue = 1000;
  step = 1;

  private homeComponent: any;

  constructor(private router: Router) {
    if (this.router.url == "/ribspan") {
      this.homeComponent = HomeComponentRibSpan.ins;
    } else {
      this.homeComponent = HomeComponent.ins;
    }
  }

  ngOnInit(): void {}
  onCancel() {
    this.onCancelEvent.emit();
    this.show(false, null);
  }
  onOK() {
    this.onSubmitEvent.emit();

    const value = this.dimValue;
    if (this.labelType === DIMENSION_LABEL_TYPE.BAY) {
      this.homeComponent.dialogEditBay.listBay[this.bayIndex].value = value;
      this.homeComponent.dialogEditBay.onOK();
    } else if (this.labelType === DIMENSION_LABEL_TYPE.LENGTH_1) {
      this.homeComponent.sldExistingLength.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.LENGTH_2) {
      this.homeComponent.sldExistingLength2.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.WIDTH_1) {
      this.homeComponent.sldExistingWidth1.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.WIDTH_2) {
      this.homeComponent.sldExistingWidth2.setValue(value);
    } else if (
      this.labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL
    ) {
      this.homeComponent.sldLeftCutHorizontal.setValue(value);
    } else if (
      this.labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL
    ) {
      this.homeComponent.sldRightCutHorizontal.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.FRONT_OVERHANG) {
      this.homeComponent.sldFrontOverhang.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.BACK_OVERHANG) {
      this.homeComponent.sldBackOverhang.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.LEFT_OVERHANG) {
      this.homeComponent.sldLeftOverhang.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.RIGHT_OVERHANG) {
      this.homeComponent.sldRightOverhang.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.SPAN) {
      this.homeComponent.sldSpan.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.MULTI_SPAN) {
      this.homeComponent.sldMultiSpan.setValue(value);
    } else if (
      this.labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL
    ) {
      this.homeComponent.sldRightCutVertical.setValue(value);
    } else if (this.labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL) {
      this.homeComponent.sldLeftCutVertical.setValue(value);
    }

    this.show(false, null);
  }
  public show(_visible: boolean, labelType, data?) {
    this.visible = _visible;

    if (!this.visible) {
      return;
    }
    this.labelType = labelType;
    this.valueType = "number";
    this.maxValue = 999999;
    this.minValue = 1;
    if (labelType === DIMENSION_LABEL_TYPE.BAY) {
      if (data && data.bayIndex != undefined) {
        this.maxValue = this.bayMaxValue;
        this.minValue = this.bayMinValue;
        this.bayIndex = data.bayIndex;
        this.dimValue =
          this.homeComponent.dialogEditBay.listBay[data.bayIndex].value;
        this.title = `Edit bay #${this.bayIndex + 1}`;
      }
    } else if (labelType === DIMENSION_LABEL_TYPE.LENGTH_1) {
      this.title = "Edit Length (L1)";
      this.dimValue = this.homeComponent.sldExistingLength.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.LENGTH_2) {
      this.title = "Edit Length (L2)";
      this.dimValue = this.homeComponent.sldExistingLength2.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.WIDTH_1) {
      this.title = "Edit Width (W1)";
      this.dimValue = this.homeComponent.sldExistingWidth1.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.WIDTH_2) {
      this.title = "Edit Width (W2)";
      this.dimValue = this.homeComponent.sldExistingWidth2.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_HORIZONTAL) {
      this.title = "Edit Rake Cut Left Horizontal";
      this.dimValue = this.homeComponent.sldLeftCutHorizontal.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_HORIZONTAL) {
      this.title = "Edit Rake Cut Right Horizontal";
      this.dimValue = this.homeComponent.sldRightCutHorizontal.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.FRONT_OVERHANG) {
      this.title = "Edit Front Overhang (O)";
      this.dimValue = this.homeComponent.sldFrontOverhang.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.BACK_OVERHANG) {
      this.title = "Edit Back Overhang (O2)";
      this.dimValue = this.homeComponent.sldBackOverhang.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.LEFT_OVERHANG) {
      this.title = "Edit Left Overhang";
      this.dimValue = this.homeComponent.sldLeftOverhang.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.RIGHT_OVERHANG) {
      this.title = "Edit Right Overhang";
      this.dimValue = this.homeComponent.sldRightOverhang.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.SPAN) {
      this.title = "Edit Span (S)";
      this.dimValue = this.homeComponent.sldSpan.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.MULTI_SPAN) {
      this.title = "Edit Multi Span (S1)";
      this.dimValue = this.homeComponent.sldMultiSpan.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_RIGHT_VERTICAL) {
      this.title = "Edit Rake Cut Right Vertical";
      this.dimValue = this.homeComponent.sldRightCutVertical.currentValue;
    } else if (labelType === DIMENSION_LABEL_TYPE.RAKE_CUT_LEFT_VERTICAL) {
      this.title = "Edit Rake Cut Left Vertical";
      this.dimValue = this.homeComponent.sldLeftCutVertical.currentValue;
    }
  }
}
