import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { BUILDING_SIDE } from "src/app/app.constants";
import { HomeComponent } from "src/app/containers/home";
import { HomeComponent as HomeComponentRibSpan } from "src/app-ribspan/containers/home";

@Component({
  selector: "dialog-confirm",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.scss"],
})
export class DialogConfirmComponent implements OnInit {
  bayIndex: number = 0;
  public dimValue = 12000;
  public baySizeMax: number = 3000;
  @Output("onSubmit") onSubmitEvent = new EventEmitter();
  @Output("onCancel") onCancelEvent = new EventEmitter();
  @Output() clickOutside = new EventEmitter<void>();
  visible: boolean = false;
  labelType: number = 0;
  title: string = "";
  valueType: string = "number";
  isShowBuilding: boolean = false;

  private homeComponent: any;

  constructor(private router: Router) {
    if (this.router.url == "/ribspan") {
      this.homeComponent = HomeComponentRibSpan.ins;
    } else {
      this.homeComponent = HomeComponent.ins;
    }
  }

  ngOnInit(): void {}
  onCancel() {
    this.onCancelEvent.emit();
    this.show(false, null);
  }
  onOK() {
    this.onSubmitEvent.emit();
    if (this.isShowBuilding) {
      if (this.labelType === BUILDING_SIDE.LEFT) {
        if (this.homeComponent.sltExistingType.currentValue == 2) {
          this.homeComponent.sltExistingType.setSelected(3);
        } else {
          this.homeComponent.sltExistingType.setSelected(1);
        }
      } else if (this.labelType === BUILDING_SIDE.RIGHT) {
        if (this.homeComponent.sltExistingType.currentValue == 1) {
          this.homeComponent.sltExistingType.setSelected(3);
        } else {
          this.homeComponent.sltExistingType.setSelected(2);
        }
      }
    } else {
      if (this.labelType === BUILDING_SIDE.LEFT) {
        if (this.homeComponent.sltExistingType.currentValue == 3) {
          this.homeComponent.sltExistingType.setSelected(2);
        } else {
          this.homeComponent.sltExistingType.setSelected(0);
        }
      } else if (this.labelType === BUILDING_SIDE.RIGHT) {
        if (this.homeComponent.sltExistingType.currentValue == 3) {
          this.homeComponent.sltExistingType.setSelected(1);
        } else {
          this.homeComponent.sltExistingType.setSelected(0);
        }
      }
    }
    this.show(false, null);
  }
  public show(_visible: boolean, labelType) {
    this.visible = _visible;

    if (!this.visible) {
      return;
    }
    this.labelType = labelType;
    let isShow = true;
    if (labelType === BUILDING_SIDE.LEFT) {
      if (
        this.homeComponent.sltExistingType.currentValue == 1 ||
        this.homeComponent.sltExistingType.currentValue == 3
      ) {
        isShow = false;
      } else {
        isShow = true;
      }
    } else if (labelType === BUILDING_SIDE.RIGHT) {
      if (
        this.homeComponent.sltExistingType.currentValue == 2 ||
        this.homeComponent.sltExistingType.currentValue == 3
      ) {
        isShow = false;
      } else {
        isShow = true;
      }
    }

    this.isShowBuilding = isShow;
    if (labelType === BUILDING_SIDE.LEFT) {
      this.title = `Are you sure you want to ${
        isShow ? "show" : "hide"
      } left wall`;
    } else if (labelType === BUILDING_SIDE.RIGHT) {
      this.title = `Are you sure you want to ${
        isShow ? "show" : "hide"
      } right wall`;
    }
  }
}
