export const APP_VERSION = "v2.0.7";
export const RELEASE_DATE = "2024-12-02";
import { BUILDING_SIDE, CONNECTION_TYPE } from "./app.constants";

export const CONFIG = {
  eaveWidth: {
    min: 50,
    max: 900,
    default: 600,
  },
  eaveHeight: {
    min: 2000,
    max: 6000,
    default: 2400,
  },
  fasciaDepth: {
    min: 150,
    max: 400,
    default: 190,
  },
  baySize: {
    min: 1500,
    max: 8000,
    default: 3810,
  },
  span: {
    min: 1000,
    max: 8000,
    default: 4000,
  },
  multiSpan: {
    min: 0,
    max: 8000,
    default: 0,
  },
  height: {
    min: 1000,
    max: 5000,
    free_standing_max: 4000,
    default: 2500,
  },
  frontOverhang: {
    min: 100,
    max: 2000,
    default: 200,
  },
  backOverhang: {
    min: 0,
    max: 2000,
    default: 0,
  },
  leftOverhang: {
    min: 0,
    max: 2000,
    default: 0,
  },
  rightOverhang: {
    min: 0,
    max: 2000,
    default: 0,
  },
  flyOverBracketHeight: {
    min: 300,
    max: 700,
    default: 500,
  },
  fasciaUpstandBrackets: {
    min: 150,
    max: 600,
    default: 0,
  },
  noOfBay: {
    min: 1,
    max: 30,
    default: 1,
  },
  patiosPitch: {
    default: 2,
  },
  existingRoofPitch: {
    default: 22.5,
  },
  rakeCutVertical: {
    min: 500,
    max: 8000,
    default: 500,
  },
  rakeCutHorizontal: {
    min: 500,
    max: 8000,
    default: 500,
  },
  roofOverallLength: {
    min: 1000,
    max: 80000,
    default: 0,
  },
  roofOverallWidth: {
    min: 0,
    max: 20000,
    default: 0,
  },
  minHeight: {
    min: 0,
    max: 5000,
    default: 0,
  },
  existingLength: {
    min: 1500,
    max: 80000,
    default: 10000,
  },
  existingLength2: {
    min: 0,
    max: 20000,
    default: 0,
  },
  existingWidth: {
    min: 0,
    max: 20000,
    default: 0,
  },
  existingWidth2: {
    min: 0,
    max: 20000,
    default: 0,
  },
  columnType: {
    default: 0,
  },
};

export const GEOMETRY_CATEGORY = {
  PATIOS: "PATOIS",
};
export const GEOMETRY_TYPE = {
  ROOF_COVER: "ROOF_COVER",
  EXISTING_WALL: "EXISTING_WALL",
  EAVE: "EAVE",
  FASCIA: "FASCIA",
  EXISTING_ROOF: "EXISTING_ROOF1",
  FLY_OVER_BRACKET: "FLY_OVER_BRACKET",
  SUPERIOR_BEAM: "SUPERIOR_BEAM",
  SUPERIOR_INTERNAL_BEAM: "SUPERIOR_INTERNAL_BEAM",
  SUPERIOR_RAFTER_BEAM: "SUPERIOR_RAFTER_BEAM",
  SUPERIOR_BEAM_END_CAP: "SUPERIOR_BEAM_END_CAP",
  SUPERIOR_RAFTER_BEAM_END_CAP: "SUPERIOR_RAFTER_BEAM_END_CAP",
  SUPERIOR_POST: "SUPERIOR_POST",
  SUPERIOR_CENTER_POST: "SUPERIOR_CENTER_POST",
  ROOF_PANEL: "ROOF_PANEL",
  ROOF_PANEL_BASE: "ROOF_PANEL_BASE",
  ROOF_PANEL_BARGE_CAPPING: "ROOF_PANEL_BARGE_CAPPING",
  ROOF_PANEL_DRIP_BARGE_CAPPING: "ROOF_PANEL_DRIP_BARGE_CAPPING",
  ZFLASHING: "ZFLASHING",
  RECEIVER_CHANEL: "RECEIVER_CHANEL",
  GUTTER: "GUTTER",
  GUTTER_PATIOS: "GUTTER_PATIOS",
  ROOF_PATIOS: "ROOF_PATIOS",
  RIDGE_CAPPING: "RIDGE_CAPPING",
  DIMENSION: "DIMENSION",
  DIMENSION_CAP: "DIMENSION_CAP",
  DIMENSION_LINE: "DIMENSION_LINE",
  DIMENSION_LINE_RAKECUT: "DIMENSION_LINE_RAKECUT",
  DIRECTION_TEXT: "DIRECTION_TEXT",
  DIMENSION_TEXT: "DIMENSION_TEXT",
  MESH_OUTLINE: "MESH_OUTLINE",
  GROUND_BASE: "GROUND_BASE",
  DOWNPIPE: "DOWNPIPE",
  DOWNPIPE_NOZZLE: "DOWNPIPE_NOZZLE",
  SHEET_OUTLINE: "SHEET_OUTLINE",
  HIGHLIGHT_BOX: "HIGHLIGHT_BOX",
  TEXT_COVER: "TEXT_COVER",
  UPSTAND_BRAKET: "UPSTAND_BRAKET",
  UPSTAND_BRAKET_EXT: "UPSTAND_BRAKET_EXT",
  UPSTAND_BRAKET_CUTOUT: "UPSTAND_BRAKET_CUTOUT",
  UPSTAND_BRAKET_BEAM: "UPSTAND_BRAKET_BEAM",
  BEAM_OUTLINE: "BEAM_OUTLINE",
  INTERNAL_JOINT: "INTERNAL_JOINT",
};
export const DIMENSION_LABEL_TYPE = {
  LENGTH_1: "LENGTH_1",
  LENGTH_2: "LENGTH_2",
  WIDTH_1: "WIDTH_1",
  WIDTH_2: "WIDTH_2",
  BAY: "BAY",
  RAKE_CUT_LEFT_HORIZONTAL: "RAKE_CUT_LEFT_HORIZONTAL",
  RAKE_CUT_LEFT_VERTICAL: "RAKE_CUT_LEFT_VERTICAL",
  RAKE_CUT_RIGHT_HORIZONTAL: "RAKE_CUT_RIGHT_HORIZONTAL",
  RAKE_CUT_RIGHT_VERTICAL: "RAKE_CUT_RIGHT_VERTICAL",
  FRONT_OVERHANG: "FRONT_OVERHANG",
  BACK_OVERHANG: "BACK_OVERHANG",
  LEFT_OVERHANG: "LEFT_OVERHANG",
  RIGHT_OVERHANG: "RIGHT_OVERHANG",
  SPAN: "SPAN",
  MULTI_SPAN: "MULTI_SPAN",
  EAVE_WIDTH: "EAVE_WIDTH",

  // FlyOver: Dim from ground to bottom of beam front.
  LOWER_HEIGHT_DIM: "LOWER_HEIGHT_DIM",
  // Braket height dim
  BRAKET_HEIGHT_DIM: "BRAKET_HEIGHT_DIM",
  // Dimension of existing wall height
  EAVE_HEIGHT_DIM: "EAVE_HEIGHT_DIM",
  // Free standing dim back.
  HIGHER_HEIGHT_DIM: "HIGHER_HEIGHT_DIM",
};
export const HIGHLIGHT_BOX_COLOR = {
  GREEN: "green",
};
export const DIMENSION_LOCATION = {
  FRONT_RIGHT: 1,
  PLAN_LEFT: 2,
  PLAN_RIGHT: 3,
  PLAN_BOTTOM: 4,
  LEFT_RIGHT: 5,
  RIGHT_LEFT: 6,
};
export const INIT = {
  CONNECTION_TYPE: CONNECTION_TYPE.EXISTING,
  EXISTING_TYPE: BUILDING_SIDE.NONE,
  DEFAULT_BRACKET_NUMBER: 2,
  DEFAULT_BRACKET_CUTOUT_NUMBER: 2,
};
export const ACTIVE_LEFT_MENU = {
  existingHouse: false,
  patiosInformation: true,
};
export const PATIOS_ROOF_TYPE = {
  FLAT_ROOF: "flat",
  GABLE_ROOF: "gable",
};
export const OFFSET_GUTTER_TO_WALL = 200;
export const STATUS_USER = [
  {
    key: "ACTIVE",
    text: "Active",
  },
  {
    key: "DISABLE",
    text: "Disable",
  },
  {
    key: "SOFTWARE_ONLY",
    text: "Software Only",
  },
  {
    key: "ORDER_ONLY",
    text: "Online Ordering Only",
  },
];

export const PANEL_TYPE = {
  INSULATED: "Insulated",
  RIBSPAN: "RibSpan",
};

export const STATUS_TEXT = {
  SAVE: "Saved",
  NEW: "New",
  OPEN: "Opening",
  COPY: "Copying",
  REVISE: "Revising",
};
export const TEMPLATE = [
  {
    type: 0, // standard
    existingLength: 10000,
    existingLength2: 0,
    existingWidth1: 0,
    existingWidth2: 0,
    existingType: {
      value: 0,
    },
    baySize: 4572,
    noOfBay: 1,
    span: 2100,
    listBay: [{ index: 1, value: 4572, isCut: false }],
    cutOut: {
      value: 0,
    },
  },
  {
    type: 1, // L-shape Left
    existingLength: 10000,
    existingLength2: 0,
    existingWidth1: 3000,
    existingWidth2: 0,
    existingType: {
      value: 1,
    },
    baySize: 4572,
    noOfBay: 1,
    span: 2100,
    listBay: [{ index: 1, value: 4572, isCut: false }],
    cutOut: {
      value: 0,
    },
  },
  {
    type: 2, // L-shape Right
    existingLength: 10000,
    existingLength2: 0,
    existingWidth1: 3000,
    existingWidth2: 0,
    existingType: {
      value: 2,
    },
    baySize: 4572,
    noOfBay: 1,
    span: 2100,
    listBay: [{ index: 1, value: 4572, isCut: false }],
    cutOut: {
      value: 0,
    },
  },
  {
    type: 3, // U-shape
    existingLength: 10000,
    existingLength2: 0,
    existingWidth1: 3000,
    existingWidth2: 0,
    existingType: {
      value: 3,
    },
    baySize: 5700,
    noOfBay: 1,
    span: 2100,
    listBay: [{ index: 1, value: 6000, isCut: false }],
    cutOut: {
      value: 0,
    },
  },
  {
    type: 5, //CutOut left
    existingLength: 10000,
    existingLength2: 3000,
    existingWidth1: 3000,
    existingWidth2: 0,
    existingType: {
      value: 1,
    },
    baySize: 4500,
    span: 6000,
    noOfBay: 2,
    listBay: [
      { index: 1, value: 4500, isCut: false },
      { index: 2, value: 4500, isCut: false },
    ],
    cutOut: {
      value: 1,
    },
  },
  {
    type: 6, // Cutout Right
    existingLength: 10000,
    existingLength2: 3000,
    existingWidth1: 3000,
    existingWidth2: 0,
    existingType: {
      value: 2,
    },
    baySize: 4500,
    span: 6000,
    noOfBay: 2,
    listBay: [
      { index: 1, value: 4500, isCut: false },
      { index: 2, value: 4500, isCut: false },
    ],
    cutOut: {
      value: 1,
    },
  },
  {
    type: 7, //Cutout Left 2
    existingLength: 10000,
    existingLength2: 3000,
    existingWidth1: 3000,
    existingWidth2: 3500,
    existingType: {
      value: 1,
    },
    baySize: 4500,
    span: 6000,
    noOfBay: 2,
    listBay: [
      { index: 1, value: 4500, isCut: false },
      { index: 2, value: 4500, isCut: false },
    ],
    cutOut: {
      value: 1,
    },
  },
  {
    type: 8, //Cutout Right  2
    existingLength: 10000,
    existingLength2: 3000,
    existingWidth1: 3000,
    existingWidth2: 3500,
    existingType: {
      value: 2,
    },
    baySize: 4500,
    span: 6000,
    noOfBay: 2,
    listBay: [
      { index: 1, value: 4500, isCut: false },
      { index: 2, value: 4500, isCut: false },
    ],
    cutOut: {
      value: 1,
    },
  },
];
