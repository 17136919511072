import { GEOMETRY_CATEGORY } from "src/app/app.config";
import {
  BUILDING_SIDE,
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  RAKECUT_TYPE,
} from "src/app/app.constants";
import { Box3, Group, Vector3 } from "three";
import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import { Printing2DGeometry } from "../models";
import { PatiosManager } from "../patios.manager";
import { ColumnAndPurlinManager } from "./column-and-purlin.manager";
import { DimensionManager } from "./dimension.manager";
import { RoofManager } from "./roof.manager";

export class PatiosBackFlyOverManager implements PatiosManager {
  public columnAndPurlinManager: ColumnAndPurlinManager;
  private roofManager: RoofManager;
  public patiosGroup: Group;
  public boundingBox: Box3;
  private updateEventHandle: any;
  //private existingTypeChangedEventtHandle: any;
  public dimesionManager: DimensionManager;
  private controlsToRegisterEvent: Array<any>;
  public ignoreBayEvent: boolean = false;
  patiosWidth: number;
  patiosLength: number;
  public get cutoutCondition(): boolean {
    return (
      [this.APP.sldSpan, this.APP.sldMultiSpan, this.APP.sldFrontOverhang]
        .map((x) => x.currentValue)
        .reduce((a, b) => a + b, 0) -
        this.APP.sldExistingWidth1.currentValue >=
      EXISTING_BUILDING_CONFIG.CUTOUT_ALLOW
    );
  }
  public get rakecutCondition(): boolean {
    let patiosWidth =
      this.APP.dialogEditBay.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let patiosLength =
      this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue;

    return false;
  }
  private APP: AppComponent;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;
  public firstLoad = true;

  constructor(app: AppComponent) {
    this.APP = app;

    this.patiosGroup = new Group();
    this.patiosGroup.userData = {
      isGroup: true,
      category: GEOMETRY_CATEGORY.PATIOS,
    };
    app.scene.add(this.patiosGroup);

    this.columnAndPurlinManager = new ColumnAndPurlinManager(app, this);
    this.roofManager = new RoofManager(app, this);
    this.dimesionManager = new DimensionManager(app, this);

    this.registerEvents();
  }

  load(): Promise<any> {
    return Promise.all([
      this.columnAndPurlinManager.optimize(),
      this.roofManager.optimize(),
      this.dimesionManager.optimize(),
    ]).then(() => {
      this.columnAndPurlinManager.load();
      this.roofManager.load();
      this.dimesionManager.load();
    });
  }
  loadBracket(bracketQuantity: number): void {
    this.columnAndPurlinManager.numberOfBracketBack = bracketQuantity;
    this.columnAndPurlinManager.addPostOrBracketBack();
  }
  loadBracketCutout(bracketQuantity: number): void {
    this.columnAndPurlinManager.numberOfBracketCutout = bracketQuantity;
    this.columnAndPurlinManager.addPostOrBracketBackCutout();
  }
  loadSideGableBracket(bracketQuantity: number): void {}
  getOutlines(): Printing2DGeometry {
    let dimensionOutline = this.dimesionManager.getOutlines();
    return {
      lines: [
        ...this.columnAndPurlinManager.getSection().lines,
        ...this.roofManager.getOutLines().lines,
        ...dimensionOutline.lines,
      ],
      texts: dimensionOutline.texts,
    };
  }

  public destroy(): void {
    this.columnAndPurlinManager.destroy();
    this.roofManager.destroy();
    this.dimesionManager.destroy();
    this.unRegisterEvent();
  }

  registerEvents() {
    this.updateEventHandle = this.updateDefer.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sltExistingType,
      this.APP.sltCutOut,
      this.APP.sldFrontOverhang,
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltHouseBeamSize,
      this.APP.sltColourBarge,
      this.APP.sltColourGutter,
      this.APP.sltColourDownpipe,
      this.APP.sltColourRoof,
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldFasciaDepth,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,
      this.APP.dialogEditBay,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldBackOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,
      this.APP.sltColourBeam,
      this.APP.sltColourPost,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
      this.APP.sltLeftCutType,
      this.APP.sldLeftCutVertical,
      this.APP.sldLeftCutHorizontal,
      this.APP.sltRightCutType,
      this.APP.sldRightCutVertical,
      this.APP.sldRightCutHorizontal,
      this.APP.sltPanelDirection,
      this.APP.sltPanelDirectionShow,
      this.APP.sltBeamLayoutShow,
      this.APP.sltExistingRoofPitch,
      this.APP.btnTurnOnPanel,
    ];
    this.controlsToRegisterEvent.forEach((c) => {
      c.addAction(this.updateEventHandle);
    });
  }
  unRegisterEvent() {
    this.controlsToRegisterEvent.forEach((c) =>
      c.removeAction(this.updateEventHandle)
    );
  }

  updateDefer(previousValue: number, currentValue: number) {
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES ||
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE
    ) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.update(previousValue, currentValue);
      }, this.deferTimeout);
    } else {
      this.update(previousValue, currentValue);
    }
  }
  update(preVal: number, curVal: number) {
    let totalBaySize = this.APP.dialogEditBay.totalBaySize;
    let diff = this.APP.sldExistingLength.currentValue - totalBaySize;
    this.patiosWidth =
      totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    this.patiosLength =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue;
    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          this.patiosGroup.position.setX(
            -diff / 2 -
              this.APP.sldExistingLength2.currentValue -
              extraWallWidth
          );
        } else {
          this.patiosGroup.position.setX(
            -diff / 2 - this.APP.sldExistingLength2.currentValue
          );
        }
      } else {
        this.patiosGroup.position.setX(-diff / 2 - extraWallWidth);
      }
    } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        this.patiosGroup.position.setX(
          diff / 2 + this.APP.sldExistingLength2.currentValue
        );
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          this.patiosGroup.position.setX(
            diff / 2 + this.APP.sldExistingLength2.currentValue + extraWallWidth
          );
        } else {
          this.patiosGroup.position.setX(
            diff / 2 + this.APP.sldExistingLength2.currentValue
          );
        }
      } else {
        this.patiosGroup.position.setX(diff / 2 + extraWallWidth);
      }
    } else {
      this.patiosGroup.position.setX(0);
    }

    this.patiosGroup.position.setZ(
      this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        this.APP.eaveManager.backOverhang -
        (this.columnAndPurlinManager.geo_bracket?.width || 0)
    );

    Promise.all([
      this.roofManager.load(),
      this.columnAndPurlinManager.load(),
    ]).then(() => {
      // this.boundingBox = this.APP.utils.getObjectsBoundingBox(
      //     this.patiosGroup.children.filter(c => c.userData.type != GEOMETRY_TYPE.DIMENSION && c.userData.type != GEOMETRY_TYPE.ROOF_PATIOS)
      // );
      //test
      // this.APP.scene.remove(...this.APP.scene.children.filter(c => c.userData.type == 'BOX_HELPRE'));
      // let boxHelper = new Box3Helper(this.boundingBox);
      // boxHelper.userData = { type: 'BOX_HELPRE' };
      // this.APP.scene.add(boxHelper);

      this.boundingBox = this.getPatiosBox();
      this.dimesionManager.draw();
    });
  }
  public getPatiosBox(): Box3 {
    let left =
      -this.APP.dialogEditBay.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue;
    let right =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue;
    let front =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue;
    let back = 0;
    let height = this.APP.sldBuildingHeight.currentValue;
    let extraOffsetZ =
      this.APP.existingWallManager.geo_existingWallL1.width +
      this.APP.eaveManager.backOverhang;

    let box = new Box3();
    box = box.expandByPoint(new Vector3(left, 0, front));
    box = box.expandByPoint(new Vector3(right, height, back));
    box.translate(new Vector3(0, 0, -extraOffsetZ));

    if (this.APP.sltExistingType.currentValue == 1) {
      let diff =
        (this.APP.sldExistingLength.currentValue -
          this.APP.dialogEditBay.totalBaySize) /
        2;
      box.translate(new Vector3(-diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(this.APP.sldLeftOverhang.currentValue, 0, 0));
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(-this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 2) {
      let diff =
        (this.APP.sldExistingLength.currentValue -
          this.APP.dialogEditBay.totalBaySize) /
        2;
      box.translate(new Vector3(diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(-this.APP.sldRightOverhang.currentValue, 0, 0));
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 3) {
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
    }
    // if (!environment.production) {
    //     this.APP.scene.remove(...this.APP.scene.children.filter(o => o.userData.type == 'BOXHELPER'));
    //     let boxHelper = new Box3Helper(box);
    //     boxHelper.userData = { type: "BOXHELPER" };
    //     this.APP.scene.add(boxHelper);
    // }

    return box;
  }
}
