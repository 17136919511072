import { HomeComponent } from "../containers/home";
import { utils } from "./utils";
export class UI {
  public static readonly Controls = {
    SPAN: "sldSpan",
    MULTI_SPAN: "sldMultiSpan",
    OVERHANG_FRONT: "sldFrontOverhang",
    OVERHANG_LEFT: "sldLeftOverhang",
    OVERHANG_RIGHT: "sldRightOverhang",
    OVERHANG_BACK: "sldBackOverhang",
    BAYSZIE: "sldBaySize",
    RAKECUT_LEFT_TYPE: "sltLeftCutType",
    LENGTH1: "sldExistingLength",
    LENGTH2: "sldExistingLength2",
    WIDTH1: "sldExistingWidth1",
    WIDTH2: "sldExistingWidth2",
    EXISTING_TYPE: "sltExistingType",
    BAYS: "dialogEditBay",
    EAVE_WIDTH: "sldEaveWidth",
    STRUCTURE_TYPE: "sltStructureType",
    CUTOUT: "sltCutOut",
    PATIOS_ROOF_PITCH: "sltRoofPitch",
    FASCIA_DEPTH: "sldFasciaDepth",
    EXISTING_ROOF_PITCH: "existingRoofPitch",
    FLYOVER_BRAKET_HEIGHT: "sldFlyOverBracketHeight",
    FASCIA_UPSTAND_BRAKET: "sltFasciaUpstandBrackets",
    EXISTING_WALL_HEIGHT: "sldExistingWallHeight",
    ROOF_SHEETING_TYPE: "sltRoofSheetingType",
    ROOF_THICKNESS: "sltRoofThickness",
  };
  static getHeightByRoofPitch(isSpan): number {
    if (isSpan) {
      return (
        utils.tan(HomeComponent.ins.sltRoofPitch.currentValue) *
        HomeComponent.ins.sldSpan.currentValue
      );
    } else {
      return (
        utils.tan(HomeComponent.ins.sltRoofPitch.currentValue) *
        (HomeComponent.ins.sldSpan.currentValue +
          HomeComponent.ins.sldMultiSpan.currentValue)
      );
    }
  }

  static get beamSize() {
    return +HomeComponent.ins.sltBeamSize.currentValue;
  }
  static get houseBeamSize() {
    return +HomeComponent.ins.sltHouseBeamSize.currentValue;
  }

  static get beamType() {
    return +HomeComponent.ins.sltBeamType.currentValue;
  }
  static get postType() {
    return +HomeComponent.ins.sltColumnType.currentValue;
  }
  static get windClass() {
    return +HomeComponent.ins.sltWindClass.currentValue;
  }

  static get rakeCutLeftType() {
    return HomeComponent.ins.sltLeftCutType.currentValue;
  }
  static get rakeCutRightType() {
    return HomeComponent.ins.sltRightCutType.currentValue;
  }
  static get eaveHeight() {
    return HomeComponent.ins.sldExistingWallHeight.currentValue;
  }
  static get existingLength1() {
    return HomeComponent.ins.sldExistingLength.currentValue;
  }
  static get existingLength2() {
    return HomeComponent.ins.sldExistingLength2.currentValue;
  }
  static get existingWidth1() {
    return HomeComponent.ins.sldExistingWidth1.currentValue;
  }
  static get existingWidth2() {
    return HomeComponent.ins.sldExistingWidth2.currentValue;
  }
  static get existingType() {
    return HomeComponent.ins.sltExistingType.currentValue;
  }
  static get height() {
    return HomeComponent.ins.sldBuildingHeight.currentValue;
  }
  static get frontHeight() {
    return (
      utils.tan(HomeComponent.ins.sltRoofPitch.currentValue) *
      (HomeComponent.ins.sldSpan.currentValue +
        HomeComponent.ins.sldMultiSpan.currentValue)
    );
  }
  static get totalBayLength() {
    return HomeComponent.ins.dialogEditBay.totalBaySize;
  }
  static get overhangLeft() {
    return HomeComponent.ins.sldLeftOverhang.currentValue;
  }
  static get overhangRight() {
    return HomeComponent.ins.sldRightOverhang.currentValue;
  }
  static get overhangFront() {
    return HomeComponent.ins.sldFrontOverhang.currentValue;
  }
  static get overhangBack() {
    return HomeComponent.ins.sldBackOverhang.currentValue;
  }
  static get span() {
    return HomeComponent.ins.sldSpan.currentValue;
  }
  static get multiSpan() {
    return +HomeComponent.ins.sldMultiSpan.currentValue;
  }
  static get rakeCutLeftHor() {
    return HomeComponent.ins.sldLeftCutHorizontal.currentValue;
  }
  static get rakeCutLeftVer() {
    return HomeComponent.ins.sldLeftCutVertical.currentValue;
  }
  static get rakeCutRightHor() {
    return HomeComponent.ins.sldRightCutHorizontal.currentValue;
  }
  static get rakeCutRightVer() {
    return HomeComponent.ins.sldRightCutVertical.currentValue;
  }
  static get structureType() {
    return +HomeComponent.ins.sltStructureType.currentValue;
  }
  static get patiosRoofType() {
    return HomeComponent.ins.patiosRoofType;
  }
  static get listBay() {
    return HomeComponent.ins.dialogEditBay.listBay;
  }
  static get cutOutType() {
    return HomeComponent.ins.sltCutOut.currentValue;
  }
  static get upstandBraketType() {
    return +HomeComponent.ins.sltFasciaUpstandBrackets.currentValue;
  }
  static get existingWallHeight() {
    return HomeComponent.ins.sldExistingWallHeight.currentValue;
  }
  static get eaveWidth() {
    return HomeComponent.ins.sldEaveWidth.currentValue;
  }
  static get patiosPitch() {
    return HomeComponent.ins.sltRoofPitch.currentValue;
  }
  static get existingRoofPitch() {
    return HomeComponent.ins.sltExistingRoofPitch.currentValue;
  }
  static get isUpFasciaUpstandardBracket() {
    return HomeComponent.ins.sltUpFasciaUpstandBracket.currentValue;
  }
  static get existingRoofType() {
    return HomeComponent.ins.sltExistingRoofType.currentValue;
  }
  static get braketHeight() {
    return HomeComponent.ins.sldFlyOverBracketHeight.currentValue;
  }
  static get existingWallLength1() {
    return HomeComponent.ins.existingWallManager.geo_existingWallL1.width;
  }
  static get existingWallW1() {
    return HomeComponent.ins.existingWallManager.geo_existingWallW1;
  }
  static get existingWallL2() {
    return HomeComponent.ins.existingWallManager.geo_existingWallL2;
  }
  static get panelDirection() {
    return HomeComponent.ins.sltPanelDirection.currentValue;
  }
  static get beamColor() {
    return HomeComponent.ins.sltColourBeam.currentValue;
  }
  static get postColor() {
    return HomeComponent.ins.sltColourPost.currentValue;
  }
  static set oldBackOverhang(value: number) {
    HomeComponent.ins.oldOverhangBack = value;
  }
  static get oldBackOverhang() {
    return HomeComponent.ins.oldOverhangBack;
  }

  static get fasciaDepth() {
    return HomeComponent.ins.sldFasciaDepth.currentValue;
  }

  static get showRoof() {
    return HomeComponent.ins.btnTurnOnPanel.currentValue;
  }

  static get beamLayoutShow() {
    return HomeComponent.ins.sltBeamLayoutShow.currentValue;
  }

  static get panelColor() {
    return HomeComponent.ins.sltColourRoof.currentValue;
  }

  static get baySize() {
    return HomeComponent.ins.sldBaySize.currentValue;
  }
  static get noOfBay() {
    return HomeComponent.ins.sldNoOfBay.currentValue;
  }

  static get roofSheetingType() {
    return HomeComponent.ins.sltRoofSheetingType.currentValue;
  }

  static get enableControl() {
    return HomeComponent.ins.btnEnableControl.currentValue;
  }
}
