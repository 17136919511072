import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import { ColumnAndBeamManager } from "./column-and-beam.manager";
import { RoofManager } from "./roof.manager";
import { Group, Box3, Vector3, Box3Helper } from "three";
import { GEOMETRY_CATEGORY, CONFIG, GEOMETRY_TYPE } from "src/app/app.config";
import {
  PatiosManager,
  RafterBeamAndInternalBeamInfo,
} from "../patios.manager";
import { Printing2DGeometry } from "src/app/core/models";
import { DimensionManager } from "./dimension.manager";
import {
  EXISTING_BUILDING_CONFIG,
  CUTOUT_ENABLE,
  RAKECUT_TYPE,
  BUILDING_SIDE,
} from "src/app/app.constants";
import { environment } from "src/environments/environment";
import { UI } from "../ui";
// import { environment } from 'src/environments/environment';

export class PatiosGableFBFlyOverManager implements PatiosManager {
  public columnAndBeamManager: ColumnAndBeamManager;
  public roofManager: RoofManager;
  private APP: AppComponent;
  public patiosGroup: Group;
  public boundingBox: Box3;
  private updateEventHandle: any;
  public dimensionManager: DimensionManager;
  private controlsToRegisterEvent: Array<any>;
  public ignoreBayEvent: boolean = false;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;
  public patiosWidth: number;
  public patiosLength: number;
  public get cutoutCondition(): boolean {
    return (
      [this.APP.sldSpan, this.APP.sldMultiSpan, this.APP.sldFrontOverhang]
        .map((x) => x.currentValue)
        .reduce((a, b) => a + b, 0) -
        this.APP.sldExistingWidth1.currentValue >=
      EXISTING_BUILDING_CONFIG.CUTOUT_ALLOW
    );
  }
  public get rakecutCondition(): boolean {
    this.patiosWidth =
      this.APP.dialogEditBay.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    this.patiosLength =
      this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue;

    return false;
  }

  public firstLoad = true;

  constructor(app: AppComponent) {
    this.APP = app;

    this.patiosGroup = new Group();
    this.patiosGroup.userData = {
      isGroup: true,
      category: GEOMETRY_CATEGORY.PATIOS,
    };
    app.scene.add(this.patiosGroup);

    this.roofManager = new RoofManager(app, this);
    this.columnAndBeamManager = new ColumnAndBeamManager(app, this);
    this.dimensionManager = new DimensionManager(app, this);

    this.registerEvents();
  }

  load(): Promise<any> {
    return Promise.all([
      this.roofManager.optimize(),
      this.columnAndBeamManager.optimize(),
      this.dimensionManager.optimize(),
    ]).then(() => {
      this.roofManager.load();
      this.columnAndBeamManager.load();
      this.dimensionManager.load();
    });
  }
  loadBracket(bracketQuantity: number): void {
    this.columnAndBeamManager.numberOfBracketsBack = bracketQuantity;
    this.columnAndBeamManager.addPostOrBracketBack();
  }
  loadBracketCutout(bracketQuantity: number): void {
    this.columnAndBeamManager.numberOfBracketCutout = bracketQuantity;
    this.columnAndBeamManager.addPostOrBracketBackCutout();
  }
  loadSideGableBracket(bracketQuantity: number): void {
    this.columnAndBeamManager.numberOfBracketsSide = bracketQuantity;
    this.columnAndBeamManager.addBraketOrBeamSideRoof();
  }
  loadRafterBeamAndInternalBeam(info: RafterBeamAndInternalBeamInfo): void {}
  getOutlines(): Printing2DGeometry {
    let dimensionOutline = this.dimensionManager.getOutlines();
    return {
      lines: [
        ...this.columnAndBeamManager.getSection().lines,
        ...this.roofManager.getOutLines().lines,
        ...dimensionOutline.lines,
      ],
      texts: dimensionOutline.texts,
    };
  }
  public destroy(): void {
    this.columnAndBeamManager.destroy();
    this.roofManager.destroy();
    this.dimensionManager.destroy();
    this.unRegisterEvent();
  }
  registerEvents() {
    this.updateEventHandle = this.updateDefer.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sltExistingType,
      this.APP.sltCutOut,
      this.APP.sldFrontOverhang,
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColourBarge,
      this.APP.sltColourGutter,
      this.APP.sltColourDownpipe,
      this.APP.sltColourRoof,
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldFasciaDepth,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,
      this.APP.dialogEditBay,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldBackOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,
      this.APP.sltColourBeam,
      this.APP.sltColourPost,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
      this.APP.sltLeftCutType,
      this.APP.sldLeftCutVertical,
      this.APP.sldLeftCutHorizontal,
      this.APP.sltRightCutType,
      this.APP.sldRightCutVertical,
      this.APP.sldRightCutHorizontal,
      this.APP.sltPanelDirection,
      this.APP.sltPanelDirectionShow,
      this.APP.sltBeamLayoutShow,
      this.APP.sltExistingRoofPitch,
      this.APP.btnTurnOnPanel,
    ];
    this.controlsToRegisterEvent.forEach((c) => {
      c.addAction(this.updateEventHandle);
    });
  }
  unRegisterEvent() {
    this.controlsToRegisterEvent.forEach((c) =>
      c.removeAction(this.updateEventHandle)
    );
  }

  updateDefer(previousValue: number, currentValue: number) {
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES ||
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE
    ) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.update(previousValue, currentValue);
      }, this.deferTimeout);
    } else {
      this.update(previousValue, currentValue);
    }
  }

  update(preVal: number, curVal: number) {
    let totalBaySize = this.APP.dialogEditBay.totalBaySize;
    this.patiosLength = totalBaySize + UI.overhangFront;
    this.patiosWidth = UI.span + UI.overhangLeft + UI.overhangRight;

    let diff = this.APP.sldExistingLength.currentValue - UI.span;

    let extraWallWidth =
      this.APP.existingWallManager.geo_existingWallW1.width +
      EXISTING_BUILDING_CONFIG.EAVE_OFFSET_BACK;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          this.patiosGroup.position.setX(
            -diff / 2 -
              this.APP.sldExistingLength2.currentValue -
              extraWallWidth
          );
        } else {
          this.patiosGroup.position.setX(
            -diff / 2 - this.APP.sldExistingLength2.currentValue
          );
        }
      } else {
        this.patiosGroup.position.setX(-diff / 2 - extraWallWidth);
      }
    } else if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.RIGHT) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        if (this.APP.sldExistingWidth2.currentValue > 0) {
          this.patiosGroup.position.setX(
            diff / 2 + this.APP.sldExistingLength2.currentValue + extraWallWidth
          );
        } else {
          this.patiosGroup.position.setX(
            diff / 2 + this.APP.sldExistingLength2.currentValue
          );
        }
      } else {
        this.patiosGroup.position.setX(diff / 2 + extraWallWidth);
      }
    } else {
      this.patiosGroup.position.setX(0);
    }

    this.patiosGroup.position.setZ(
      this.APP.geometryManager.EXISTING_WALL.EXISTING_WALL.width +
        this.APP.eaveManager.backOverhang -
        (this.columnAndBeamManager.geo_bracket?.width || 0)
    );

    Promise.all([
      this.roofManager.load(),
      this.columnAndBeamManager.load(),
    ]).then(() => {
      this.boundingBox = this.getPatiosBox();
      this.dimensionManager.draw();
    });
  }
  public getPatiosBox(): Box3 {
    let left = -UI.span / 2 - UI.overhangLeft;
    let right = UI.span / 2 + UI.overhangRight;
    let front =
      this.APP.dialogEditBay.totalBaySize + UI.overhangFront + UI.overhangBack;
    let back = 0;
    let height = this.APP.sldBuildingHeight.currentValue;
    let extraOffsetZ =
      this.APP.existingWallManager.geo_existingWallL1.width +
      this.APP.eaveManager.backOverhang;

    let box = new Box3();
    box = box.expandByPoint(new Vector3(left, 0, front));
    box = box.expandByPoint(new Vector3(right, height, back));
    box.translate(new Vector3(0, 0, -extraOffsetZ));

    if (this.APP.sltExistingType.currentValue == 1) {
      let diff = (this.APP.sldExistingLength.currentValue - UI.span) / 2;
      box.translate(new Vector3(-diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(this.APP.sldLeftOverhang.currentValue, 0, 0));

      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(-this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 2) {
      let diff = (this.APP.sldExistingLength.currentValue - UI.span) / 2;
      box.translate(new Vector3(diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(-this.APP.sldRightOverhang.currentValue, 0, 0));

      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 3) {
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
    }

    // if (!environment.production) {
    //     this.APP.scene.remove(...this.APP.scene.children.filter(o => o.userData.type == 'BOXHELPER'));
    //     let boxHelper = new Box3Helper(box);
    //     boxHelper.userData = { type: "BOXHELPER" };
    //     this.APP.scene.add(boxHelper);
    // }

    return box;
  }
}
