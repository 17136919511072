import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import {
  Scene,
  Material,
  Mesh,
  Vector3,
  BufferGeometry,
  BoxHelper,
  Geometry,
  LineSegments,
  BoxBufferGeometry,
  Group,
  Object3D,
  Matrix4,
  Line,
  Points,
  PointsMaterial,
  Box3,
  Box3Helper,
  Plane,
} from "three";
import { Util, getRafterBeamEndCapCode } from "../utils";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  CONFIG as env,
  GEOMETRY_TYPE,
  GEOMETRY_CATEGORY,
} from "../../app.config";
import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG as CONST,
  EXISTING_BUILDING_CONFIG,
  RAKECUT_TYPE,
} from "../../app.constants";
import {
  GeometryInfo,
  Printing2DGeometryType,
  Printing2DLine,
  Printing2DGeometry,
  Print2DView,
  ViewType,
  LineType,
} from "../models";
import { GableFBExistingBuildingManager } from ".";
import { group } from "console";
import { UI } from "../ui";
import _ from "lodash";

export class PostAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private material: Material;
  private utils: Util;
  private geometryManager: GeometryManager;
  private postInfo: GeometryInfo;
  private beamInfo: GeometryInfo;
  private beamRakecutInfoLeft: GeometryInfo;
  private beamRakecutInfoRight: GeometryInfo;
  private geo_centerSuperiorPost: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private geo_RafterBeam: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;
  private eventHandleId: any;
  private roofWidth: number;
  private frontPostHeight: number;
  private totalBaySize: number;
  private downpipeGroupRight: Group;
  private downpipeGroupLeft: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  private geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private geo_roofBase: GeometryInfo;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;
  private MANAGER: GableFBExistingBuildingManager;
  private enableCutout: boolean;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;

  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;

  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  constructor(
    app: AppComponent,
    existingManager: GableFBExistingBuildingManager
  ) {
    this.APP = app;
    this.MANAGER = existingManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.scene = existingManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  uiCHangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiCHangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldBuildingHeight,
      this.APP.sltRoofPitch,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldExistingWidth1,
      this.APP.sltExistingType,
      //this.APP.dialogEditBay,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
      this.APP.sltCutOut,
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.beamInfo = this.geometryManager.getBeam();
      this.beamInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.beamInfo.length / 2, -this.beamInfo.height / 2, 0);
      //.scale(this.APP.sldBaySize.currentValue / this.beamInfo.length, 1, 1);

      this.geoBeamJoint = this.geometryManager.getBeamJoint();
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint();

      this.beamRakecutInfoLeft = this.geometryManager.getBeam();
      this.beamRakecutInfoLeft.geometry.translate(
        this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.beamRakecutInfoRight = this.geometryManager.getBeam();
      this.beamRakecutInfoRight.geometry.translate(
        -this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          -this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          -this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        -this.geo_beamEndCap.height / 2,
        0
      );

      this.postInfo = this.geometryManager.getPost();
      this.postInfo.geometry
        .rotateX(Math.PI / 2)
        .translate(0, this.postInfo.height / 2, 0);
      //.scale(1, env.height.default / this.postInfo.height, 1);

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        this.geo_groundBase.width / 2,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();
      this.geo_roofBase = this.geometryManager.getRoofBase();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroupRight = new Group();
      this.downpipeGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.downpipeGroupRight.rotateY(Math.PI / 2);
      this.scene.add(this.downpipeGroupRight);

      this.downpipeGroupLeft = new Group();
      this.downpipeGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.downpipeGroupLeft.rotateY(-Math.PI / 2);
      this.scene.add(this.downpipeGroupLeft);

      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);

      this.geo_centerSuperiorPost = this.geometryManager.getCenterPost();
      this.geo_centerSuperiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_centerSuperiorPost.width / 2,
          this.geo_centerSuperiorPost.height / 2,
          this.geo_centerSuperiorPost.width / 2
        );

      this.geo_RafterBeam = this.geometryManager.getRafterBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          -this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_rafterBeamEndCap = this.geometryManager.getRafterBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        -this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === "COLUMN_OUTLINE"
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.downpipeGroupRight.children = [];
      this.downpipeGroupLeft.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );

      this.totalBaySize = this.APP.dialogEditBay.totalBaySize;
      this.roofWidth =
        this.APP.sldLeftOverhang.currentValue +
        this.APP.sldRightOverhang.currentValue +
        UI.span;
      this.enableCutout = this.MANAGER.cutoutCondition;

      let offsetZ = 0;
      let idx = 0;

      this.addGround();
      this.addCenterSuperiorBeam();

      this.addHorSuperiorBeam(-UI.span / 2 + this.beamInfo.width / 2, {
        left: true,
        first: true,
        last: false,
      });
      this.addHorSuperiorBeam(UI.span / 2 - this.beamInfo.width / 2, {
        right: true,
        first: false,
        last: true,
      });

      this.APP.dialogEditBay.listBay.forEach((m) => {
        offsetZ += m.value;

        this.addBeam(offsetZ, {
          back: idx + 1 == 0,
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          front: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });

        this.addCenterPost(offsetZ, {
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          first: idx + 1 == 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });

        this.addPost(offsetZ, {
          back: idx + 1 == 0,
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          front: idx + 1 == this.APP.dialogEditBay.listBay.length,
          first: idx + 1 == 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
          left: true,
          right: false,
        });
        this.addPost(offsetZ, {
          back: idx + 1 == 0,
          center:
            !(idx + 1 == this.APP.dialogEditBay.listBay.length) &&
            !(idx + 1 == 0),
          front: idx + 1 == this.APP.dialogEditBay.listBay.length,
          first: idx + 1 == 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
          left: false,
          right: true,
        });

        let fitBeam = 0;
        if (idx + 1 > 0 && idx + 1 < this.APP.dialogEditBay.listBay.length) {
          fitBeam = this.geo_RafterBeam.width / 2;
        } else if (idx + 1 == this.APP.dialogEditBay.listBay.length) {
          fitBeam = this.geo_RafterBeam.width;
        }

        this.addRafterSuperiorBeam(offsetZ - fitBeam - UI.existingWidth1 / 2, {
          front: idx + 1 == this.APP.dialogEditBay.listBay.length,
          right: true,
          first: idx + 1 === 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });
        this.addRafterSuperiorBeam(offsetZ - fitBeam - UI.existingWidth1 / 2, {
          back: idx + 1 == 0,
          left: true,
          first: idx + 1 === 0,
          last: idx + 1 == this.APP.dialogEditBay.listBay.length,
        });

        idx++;
      });

      this.addDownPipeRight();
      this.addDownPipeLeft();
      this.showBeamOutline();
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = [];
    objs.forEach((el) => {
      if (el.type == "Group") {
        meshes.push(
          ...el.children.filter(
            (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
          )
        );
      }
    });

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private addDownPipeRight() {
    let offsetZ = this.totalBaySize - 50;
    let offsetX = UI.span / 2 + 100;

    if (this.APP.sltExistingType.currentValue != 0) {
      offsetZ -= this.APP.sldExistingWidth1.currentValue / 2;
    }

    let offsetY =
      this.utils.getHeightByAngle(
        UI.height,
        UI.overhangRight,
        UI.patiosPitch,
        -1
      ) +
      this.geo_roofBase.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroupRight,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      offsetY,
      offsetZ,
      0,
      0,
      UI.overhangRight,
      offsetY,
      true
    );
  }
  private addDownPipeLeft() {
    let offsetZ = this.totalBaySize - 50;
    let offsetX = -UI.span / 2 - 100;

    if (this.APP.sltExistingType.currentValue != 0) {
      offsetZ -= this.APP.sldExistingWidth1.currentValue / 2;
    }

    let offsetY =
      this.utils.getHeightByAngle(
        UI.height,
        UI.overhangLeft,
        UI.patiosPitch,
        -1
      ) +
      this.geo_roofBase.height +
      90;

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroupLeft,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      offsetY,
      offsetZ,
      0,
      0,
      UI.overhangLeft,
      offsetY,
      true
    );
  }
  private updateUI() {
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 0));
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "OUTLINE"};
      // //line.position.set(o.position.x - 5000, o.position.y, o.position.z);
      // this.scene.add( line );
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };
        //this.APP.scene.add(box);

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(
          g.userData.views,
          (el) => el.viewType == ViewType.PLAN
        );
        const anotherViews = _.filter(
          g.userData.views,
          (el) => el.viewType != ViewType.PLAN
        );
        if (anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        }
        if (planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices
              .slice(0, 8)
              .filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    return lineGeo;
  }
  private addGround() {
    let offsetX = -(UI.span / 2 + this.APP.sldLeftOverhang.currentValue);
    let offsetZ = -this.APP.sldExistingWidth1.currentValue / 2;
    let width = UI.span + UI.overhangLeft + UI.overhangRight;
    let length = this.totalBaySize + UI.overhangFront + UI.overhangBack;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  public addHorSuperiorBeam(offsetX, userDataPos: any) {
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength = this.totalBaySize + UI.overhangFront;

    let views: Print2DView[];
    offsetY = UI.height;
    offsetZ = this.totalBaySize + UI.overhangFront - UI.existingWidth1 / 2;

    if (userDataPos.left) {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetX, offsetY, offsetZ),
      new Vector3(0, Math.PI / 2, 0),
      views,
      1,
      userDataPos,
      0,
      "x",
      "EB",
      this.geoBeamJoint
    );
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    beamName = "",
    jointGeo: GeometryInfo
  ) {
    // Map list cut beam here
    const beamStartZ = pos.z - length;
    const beamEndZ = pos.z;

    let endOfBayZ = -UI.existingWidth1 / 2;
    let startCutBeamZ = beamStartZ;
    let endCutBeamZ = beamStartZ;

    const beams = this.utils.getListSeperateBeamsByBaysForFbGable(
      endOfBayZ,
      beamStartZ,
      beamEndZ,
      endCutBeamZ,
      startCutBeamZ
    );

    for (let i = 0; i < beams.length; i++) {
      endCutBeamZ = beams[i][1];
      startCutBeamZ = beams[i][0];
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        endCutBeamZ - startCutBeamZ,
        new Vector3(pos.x, pos.y, endCutBeamZ),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap:
            UI.beamType == 0 &&
            (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasEndCap: UI.beamType == 0 && i == 0,
          hasStartJoint: false,
          hasEndJoint: i !== 0,
          jointGeo: jointGeo,
        }
      );
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }
  public addPost(offsetZ: number, userDataPos: any) {
    const condition = offsetZ + this.APP.sldEaveWidth.currentValue;
    if (
      (UI.existingType === 1 || UI.existingType === 3) &&
      userDataPos.left &&
      (UI.existingWidth1 >= condition + UI.multiSpan ||
        (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
          UI.cutOutType == 1))
    ) {
      return;
    }
    if (
      (UI.existingType === 2 || UI.existingType === 3) &&
      userDataPos.right &&
      (UI.existingWidth1 >= condition + UI.multiSpan ||
        (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
          UI.cutOutType == 1))
    ) {
      return;
    }

    let views: Print2DView[] = [];
    if (userDataPos.back || userDataPos.center) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    const mesh = new Mesh(
      this.postInfo.geometry,
      MaterialManager.Instance().POST
    );
    let posZ = offsetZ - UI.existingWidth1 / 2;
    if (userDataPos.back) {
      posZ = offsetZ + this.postInfo.width / 2 - UI.existingWidth1 / 2;
    } else if (userDataPos.front) {
      posZ = offsetZ - this.postInfo.width / 2 - UI.existingWidth1 / 2;
    }

    let scaleY = 0;
    let length = 0;

    if (userDataPos.isMultiSpan) {
      length = this.APP.sldBuildingHeight.currentValue;
      scaleY = length / this.postInfo.height;
    } else {
      length = this.APP.sldBuildingHeight.currentValue;
      scaleY = length / this.postInfo.height;
    }

    let offsetX = 0;
    if (userDataPos.left) {
      offsetX = -UI.span / 2 + this.postInfo.width / 2;
    } else if (userDataPos.right) {
      offsetX = UI.span / 2 - this.postInfo.width / 2;
    }

    mesh.position.set(offsetX, 0, posZ);
    mesh.scale.setY(scaleY);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      position: userDataPos,
      views: views,
    };
    this.scene.add(mesh);
  }
  public addCenterSuperiorBeam() {
    let beamLength = this.totalBaySize + UI.overhangFront;

    let offsetY =
      UI.height +
      (UI.span / 2 - this.beamInfo.width / 2) * this.utils.tan(UI.patiosPitch);
    let offsetZ = this.totalBaySize + UI.overhangFront - UI.existingWidth1 / 2;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(0, offsetY, offsetZ),
      new Vector3(0, Math.PI / 2, 0),
      views,
      1,
      { center: true },
      0,
      "x",
      "RB",
      this.geoBeamJoint
    );
  }
  private addCenterPost(offsetZ, userDataPos) {
    let meshPost = new Mesh(
      this.geo_centerSuperiorPost.geometry,
      MaterialManager.Instance().BEAM
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_CENTER_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let scalePostY = 1;

    let views: Print2DView[];

    this.frontPostHeight = UI.height;

    scalePostY =
      ((UI.span / 2 - this.beamInfo.width / 2) *
        this.utils.tan(UI.patiosPitch) -
        this.beamInfo.height) /
      this.geo_centerSuperiorPost.height;

    if (userDataPos.last) {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    let postOffsetX = -this.geo_centerSuperiorPost.width / 2;

    let postOffsetZ =
      offsetZ - this.geo_centerSuperiorPost.width / 2 - UI.existingWidth1 / 2;
    if (userDataPos.first) {
      postOffsetZ =
        offsetZ - this.geo_centerSuperiorPost.width / 2 - UI.existingWidth1 / 2;
    } else if (userDataPos.last) {
      postOffsetZ =
        offsetZ - this.geo_centerSuperiorPost.width - UI.existingWidth1 / 2;
    }

    meshPost.userData.views = views;
    meshPost.position.set(postOffsetX, UI.height, postOffsetZ);
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }
  public addRafterSuperiorBeam(offsetZ, userDataPos: any) {
    if (
      this.APP.sltWindClass.currentValue == 0 ||
      this.APP.sltWindClass.currentValue == 1
    ) {
      if (this.totalBaySize <= 6000 && this.APP.sldSpan.currentValue <= 7000) {
        return;
      }
    }
    if (
      this.APP.sltWindClass.currentValue == 3 ||
      this.APP.sltWindClass.currentValue == 4 ||
      this.APP.sltWindClass.currentValue == 5
    ) {
      return;
    }
    let mesh;

    let offsetXL = 0;
    let offsetXR =
      (UI.span / 2 - this.beamInfo.width / 2) / this.utils.cos(UI.patiosPitch);
    let offsetY = 0;

    let scaleX =
      (UI.span / 2 - this.beamInfo.width / 2) /
      this.utils.cos(UI.patiosPitch) /
      this.geo_RafterBeam.length;

    let views: Print2DView[];

    let fitZ = 0;

    if (userDataPos.left) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(0, UI.height, 0);
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let beamOffsetX = 0;

      let nVer = new Vector3(-1, 0, 0).normalize();
      let pVer = new Vector3(
        -this.beamInfo.width / 2 + beamOffsetX + this.scene.position.x + fitZ,
        0,
        0
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.right) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(0, UI.height, 0);
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let beamOffsetX = 0;
      let nVer = new Vector3(1, 0, 0).normalize();
      let pVer = new Vector3(
        this.beamInfo.width / 2 + beamOffsetX + this.scene.position.x + fitZ,
        0,
        0
      );
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getRafterBeamEndCapCode(
      EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE,
      false
    );
    let higherBraketName = getRafterBeamEndCapCode(
      EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE,
      true
    );

    let capL;
    let capR;
    if (userDataPos.left) {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.height + (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        -translateZ / 2,
        heightPlane,
        0
      );
      let matrixRotateTop = new Matrix4().makeRotationZ(
        this.utils.degreesToRadians(UI.patiosPitch)
      );
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        -translateZBottom / 2,
        heightPlaneBottom,
        0
      );
      let matrixRotateBottom = new Matrix4().makeRotationZ(
        this.utils.degreesToRadians(UI.patiosPitch)
      );
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      let beamOffsetX = this.scene.position.x;

      planeTop.translate(new Vector3(beamOffsetX, 0, 0));
      planeBottom.translate(new Vector3(beamOffsetX, 0, 0));

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };
    } else {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        UI.height + (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlane = heightReal * this.utils.cos(UI.patiosPitch);
      let translateZ = this.utils.sin(UI.patiosPitch) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        translateZ / 2,
        heightPlane,
        0
      );
      let matrixRotateTop = new Matrix4().makeRotationZ(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        UI.height -
        this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
        (this.utils.tan(UI.patiosPitch) * UI.span) / 2;
      let heightPlaneBottom = heightRealBottom * this.utils.cos(UI.patiosPitch);
      let translateZBottom = this.utils.sin(UI.patiosPitch) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        translateZBottom / 2,
        heightPlaneBottom,
        0
      );
      let matrixRotateBottom = new Matrix4().makeRotationZ(
        -this.utils.degreesToRadians(UI.patiosPitch)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      let beamOffsetX = this.scene.position.x;

      planeTop.translate(new Vector3(beamOffsetX, 0, 0));
      planeBottom.translate(new Vector3(beamOffsetX, 0, 0));

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height / this.utils.cos(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length * this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(-this.utils.degreesToRadians(UI.patiosPitch));
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height / this.utils.sin(UI.patiosPitch) +
          this.geo_rafterBeamEndCap.length / this.utils.tan(UI.patiosPitch)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(-Math.PI / 2 + this.utils.degreesToRadians(UI.patiosPitch));
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };
    }

    let offsetX = 0;
    if (userDataPos.left) {
      offsetX = -UI.span / 2;
      offsetY = UI.height;
    } else if (userDataPos.right) {
      offsetX = this.geo_centerSuperiorPost.width / 2;
      offsetY =
        UI.height +
        (UI.span / 2 - this.beamInfo.width / 2) *
          this.utils.tan(UI.patiosPitch);
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    if (userDataPos.left) {
      beamGroup.rotateZ(this.utils.degreesToRadians(UI.patiosPitch));
    } else if (userDataPos.right) {
      beamGroup.rotateZ(-this.utils.degreesToRadians(UI.patiosPitch));
    }

    this.scene.add(beamGroup);
  }
  public addBeam(offsetZ: number, userDataPos: any) {
    let offsetY = 0;

    let beamLength = UI.span - 2 * this.postInfo.width;
    if (userDataPos.back) {
      beamLength = UI.span - 2 * this.beamInfo.width;
    }

    let offsetX = -UI.span / 2 + this.postInfo.width;
    if (userDataPos.back) {
      offsetX = -UI.span / 2 + this.beamInfo.width;
    }

    let posZ = 0;
    let views: Print2DView[] = [];
    if (userDataPos.front) {
      offsetY = UI.height;
      posZ = offsetZ - this.beamInfo.width / 2 - UI.existingWidth1 / 2;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.center) {
      offsetY = UI.height;
      posZ = offsetZ - UI.existingWidth1 / 2;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      offsetY = UI.height;
      posZ = offsetZ + this.beamInfo.width / 2 - UI.existingWidth1 / 2;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    let beamGroup: Group;
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup = this.utils.createBeamGroup2(
        this.beamInfo,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, posZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        0,
        "x",
        false,
        {
          hasStartCap: UI.beamType == 0,
          hasEndCap: UI.beamType == 0,
          hasStartJoint: false,
          hasEndJoint: false,
          jointGeo: null,
        }
      );
    } else {
      beamGroup = this.utils.createBeamGroup2(
        this.beamInfo,
        this.geo_beamEndCap,
        beamLength,
        new Vector3(offsetX, offsetY, posZ),
        new Vector3(),
        views,
        1,
        userDataPos,
        0,
        "x",
        false,
        {
          hasStartCap: UI.beamType == 0,
          hasEndCap: UI.beamType == 0,
          hasStartJoint: false,
          hasEndJoint: false,
          jointGeo: null,
        }
      );
    }
    beamGroup.userData = {
      ...beamGroup.userData,
      name: "GB",
    };

    this.scene.add(beamGroup);
  }

  private getHeightByRoofPitch(isSpan: boolean): number {
    if (isSpan) {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        this.APP.sldSpan.currentValue
      );
    } else {
      return (
        this.utils.tan(this.APP.sltRoofPitch.currentValue) *
        (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue)
      );
    }
  }
}
