import { HomeComponent } from "src/app/containers/home";
import { DialogAddBOMComponent } from "./../dialog-add-bom/dialog-add-bom.component";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { HomeComponent as HomeComponentRibSpan } from "src/app-ribspan/containers/home";
import { Router } from "@angular/router";

@Component({
  selector: "panel-bom",
  templateUrl: "./panel-bom.component.html",
  styleUrls: ["./panel-bom.component.scss"],
})
export class PanelBOMComponent implements OnInit {
  private homeComponent: any;

  constructor(public message: NzMessageService, private router: Router) {
    if (this.router.url == "/ribspan") {
      this.homeComponent = HomeComponentRibSpan.ins;
    } else {
      this.homeComponent = HomeComponent.ins;
    }
  }
  @Input("boms") boms: [];
  @ViewChild("dialogAddBOM", { static: true })
  dialogAddBOM: DialogAddBOMComponent;
  isVisible: boolean = false;
  addBOMs: any[];
  ngOnInit(): void {}

  public show(_visible: boolean, id: string) {
    this.isVisible = _visible;

    if (!this.isVisible) {
      return;
    }
  }
  public onRefresh() {
    if (this.homeComponent.currentBuildingInfo.additionalBOM) {
      this.addBOMs = [...this.homeComponent.currentBuildingInfo.additionalBOM];
    }
  }
  onNew(): void {
    this.dialogAddBOM.onShow(true);
  }

  onCancel(): void {
    this.isVisible = false;
  }

  onDeleteBOM(data) {
    if (!this.homeComponent.currentBuildingInfo.isOpen) {
      this.homeComponent.onDeleteBOM(data);
      this.onRefresh();
    }
  }
  onCopyBOM(data) {
    if (!this.homeComponent.currentBuildingInfo.isOpen) {
      this.dialogAddBOM.onShow(true, data);
    }
  }

  onAddBOM(price) {
    if (!this.homeComponent.currentBuildingInfo.isOpen) {
      this.homeComponent.onAddBOM(price);
      this.onRefresh();
    }
  }
}
