import {
  HomeComponent as AppComponent,
  HomeComponent,
} from "../../containers/home/home.component";
import {
  Scene,
  Material,
  Mesh,
  Vector3,
  BufferGeometry,
  BoxHelper,
  Geometry,
  LineSegments,
  BoxBufferGeometry,
  Group,
  Object3D,
  Matrix4,
  Line,
  Points,
  PointsMaterial,
  Box3,
  Box3Helper,
} from "three";
import { Util, getBeamEndCapCode } from "../utils";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  CONFIG as env,
  GEOMETRY_TYPE,
  GEOMETRY_CATEGORY,
} from "../../app.config";
import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG as CONST,
  EXISTING_BUILDING_CONFIG,
  RAKECUT_TYPE,
} from "../../app.constants";
import {
  GeometryInfo,
  Printing2DGeometryType,
  Printing2DLine,
  Printing2DGeometry,
  Print2DView,
  ViewType,
  LineType,
} from "../models";
import { ExistingBuildingManager } from ".";
import { group } from "console";
import { UI } from "../ui";
import _ from "lodash";

export class PostAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private material: Material;
  private utils: Util;
  private geometryManager: GeometryManager;
  private postInfo: GeometryInfo;
  public beamInfo: GeometryInfo;
  private beamRakecutInfoLeft: GeometryInfo;
  private beamRakecutInfoRight: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private eventHandleId: any;
  private roofWidth: number;
  private frontPostHeight: number;
  private totalBaySize: number;
  private downpipeGroup: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  private geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private geo_roofBase: GeometryInfo;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;
  private MANAGER: ExistingBuildingManager;
  private enableCutout: boolean;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;

  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;

  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  constructor(app: AppComponent, existingManager: ExistingBuildingManager) {
    this.APP = app;
    this.MANAGER = existingManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.scene = existingManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  uiCHangedDefer(previousValue: number, currentValue: number) {
    if (this.APP.sltCutOut.currentValue == 1) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.load();
      }, this.deferTimeout);
    } else {
      this.load();
    }
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiCHangedDefer.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldBuildingHeight,
      this.APP.sltRoofPitch,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldExistingWidth1,
      this.APP.sltExistingType,
      //this.APP.dialogEditBay,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
      this.APP.sltCutOut,
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sldExistingWidth1,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.beamInfo = this.geometryManager.getBeam();
      this.beamInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.beamInfo.length / 2, -this.beamInfo.height / 2, 0);
      //.scale(this.APP.sldBaySize.currentValue / this.beamInfo.length, 1, 1);

      this.geoBeamJoint = this.geometryManager.getBeamJoint();
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint();

      this.beamRakecutInfoLeft = this.geometryManager.getBeam();
      this.beamRakecutInfoLeft.geometry.translate(
        this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.beamRakecutInfoRight = this.geometryManager.getBeam();
      this.beamRakecutInfoRight.geometry.translate(
        -this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          -this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          -this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        -this.geo_beamEndCap.height / 2,
        0
      );

      this.postInfo = this.geometryManager.getPost();
      this.postInfo.geometry
        .rotateX(Math.PI / 2)
        .translate(0, this.postInfo.height / 2, 0);
      //.scale(1, env.height.default / this.postInfo.height, 1);

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        this.geo_groundBase.width / 2,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();
      this.geo_roofBase = this.geometryManager.getRoofBase();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);

      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === "COLUMN_OUTLINE"
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.downpipeGroup.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );

      this.totalBaySize = UI.totalBayLength;
      this.roofWidth = UI.overhangLeft + UI.overhangRight + this.totalBaySize;
      this.enableCutout = this.MANAGER.cutoutCondition;
      this.addBeam({ isMultiSpan: false });
      this.addBeam({ isMultiSpan: true });
      this.addBeamAngleRakecut(BUILDING_SIDE.LEFT);
      this.addBeamAngleRakecut(BUILDING_SIDE.RIGHT);
      this.addBeamStepRakecut();
      this.addGround();
      let offsetX = -(this.totalBaySize / 2) + this.postInfo.width / 2;
      let offsetXOrigin = -(this.totalBaySize / 2) + this.postInfo.width / 2;

      let idx = 0;
      this.addPost(offsetX, { isMultiSpan: false, left: true });
      this.addPost(offsetX, { isMultiSpan: true, left: true });

      this.APP.dialogEditBay.listBay.forEach((m) => {
        offsetX += m.value;
        if (idx === this.APP.dialogEditBay.listBay.length - 1) {
          offsetX -= this.postInfo.width;
          this.addPost(offsetX, { isMultiSpan: false, right: true });
          this.addPost(offsetX, { isMultiSpan: true, right: true });
          this.addPostAngleRakecut(offsetXOrigin, { left: true });
          this.addPostAngleRakecut(offsetX, { right: true });
        } else {
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: false,
          });
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: true,
          });
        }
        idx++;
      });

      this.addPostStepRakecut();
      this.addDownPipe();
      this.addDownPipeStepRakecut();
      this.showBeamOutline();
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = [];
    objs.forEach((el) => {
      if (el.type == "Group") {
        meshes.push(
          ...el.children.filter(
            (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
          )
        );
      }
    });

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private addDownPipeStepRakecut() {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.STEP &&
      UI.rakeCutRightType != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetX = -this.totalBaySize / 2 + 50;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.height,
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutLeftVer,
          UI.patiosPitch,
          -1
        ) +
        this.geometryManager.getRoofBase().height -
        30;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront +
        40 -
        UI.existingWidth1 / 2 -
        UI.rakeCutLeftVer;

      this.downpipeStepRakeCutGroupLeft.position.set(offsetX, 0, offsetZ);

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

      let scaleY = offsetY / this.geo_downPipe.height;

      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = userData;
      meshPipe.scale.setY(scaleY);

      this.downpipeStepRakeCutGroupLeft.add(meshPipe);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetX = this.totalBaySize / 2 - 50;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.height,
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutRightVer,
          UI.patiosPitch,
          -1
        ) +
        this.geometryManager.getRoofBase().height -
        30;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront +
        40 -
        UI.existingWidth1 / 2 -
        UI.rakeCutRightVer;

      this.downpipeStepRakeCutGroupRight.position.set(offsetX, 0, offsetZ);

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

      let scaleY = offsetY / this.geo_downPipe.height;

      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = userData;
      meshPipe.scale.setY(scaleY);

      this.downpipeStepRakeCutGroupRight.add(meshPipe);
    }
  }
  private addDownPipe() {
    let offsetZ = UI.span + UI.multiSpan + 100 - UI.existingWidth1 / 2;
    let offsetX = -this.totalBaySize / 2 + 50;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      offsetX = this.totalBaySize / 2 - 50;
    }

    //Rakecut
    //Downpipe will be in left
    if (UI.rakeCutLeftType != RAKECUT_TYPE.NONE) {
      if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetX = -this.totalBaySize / 2 + this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetX =
          -this.totalBaySize / 2 +
          UI.rakeCutLeftHor +
          EXISTING_BUILDING_CONFIG.FIT_FROM_CORNER_TO_CENTER_GUTTER;
      }
    }
    //Downpipe will be in right
    else {
      if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
        offsetX = this.totalBaySize / 2 - this.beamRightCutSizeInfo.l;
      } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
        offsetX =
          this.totalBaySize / 2 -
          UI.rakeCutRightHor -
          EXISTING_BUILDING_CONFIG.FIT_FROM_CORNER_TO_CENTER_GUTTER;
      }
    }

    let pipeLength =
      this.utils.getHeightByAngle(
        UI.height,
        UI.span + UI.multiSpan + UI.overhangFront,
        UI.patiosPitch,
        -1
      ) + this.geo_roofBase.height;

    this.downpipeGroup = this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroup,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      0,
      offsetZ,
      offsetZ,
      0,
      UI.overhangFront,
      pipeLength,
      true
    );
  }
  private updateUI() {
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 0));
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "OUTLINE"};
      // //line.position.set(o.position.x - 5000, o.position.y, o.position.z);
      // this.scene.add( line );
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };
        //this.APP.scene.add(box);

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(
          g.userData.views,
          (el) => el.viewType == ViewType.PLAN
        );
        const anotherViews = _.filter(
          g.userData.views,
          (el) => el.viewType != ViewType.PLAN
        );
        if (anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        }
        if (planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices
              .slice(0, 8)
              .filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    return lineGeo;
  }
  private addGround() {
    let offsetX = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetZ = -UI.existingWidth1 / 2;
    let width = this.totalBaySize + UI.overhangLeft + UI.overhangRight;
    let length =
      UI.span +
      UI.multiSpan +
      UI.overhangFront +
      this.APP.sldBackOverhang.currentValue;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  public addPost(offsetX: number, userDataPos: any) {
    if (userDataPos.isMultiSpan && UI.multiSpan === 0) {
      return;
    }

    const _existingType = +this.APP.sltExistingType.currentValue;

    let _flag = false;
    if (userDataPos.left && UI.overhangLeft > 0) {
      _flag = true;
    } else if (userDataPos.right && UI.overhangRight > 0) {
      _flag = true;
    }

    if (!userDataPos.isMultiSpan && !_flag) {
      if (
        (_existingType === 1 || _existingType === 3) &&
        userDataPos.left &&
        UI.existingWidth1 >= UI.span
      ) {
        return;
      }
      if (
        (_existingType === 2 || _existingType === 3) &&
        userDataPos.right &&
        UI.existingWidth1 >= UI.span
      ) {
        return;
      }
    } else if (!_flag) {
      if (
        (_existingType === 1 || _existingType === 3) &&
        userDataPos.left &&
        UI.existingWidth1 >= UI.span + UI.multiSpan
      ) {
        return;
      }
      if (
        (_existingType === 2 || _existingType === 3) &&
        userDataPos.right &&
        UI.existingWidth1 >= UI.span + UI.multiSpan
      ) {
        return;
      }
    }
    let views: Print2DView[] = [];

    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    const mesh = new Mesh(
      this.postInfo.geometry,
      MaterialManager.Instance().POST
    );
    let offsetZ = -UI.existingWidth1 / 2;
    if (userDataPos.isMultiSpan) {
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }
    // offsetZ += this.geometryManager.EXISTING_WALL.EXISTING_WALL.width;
    let scaleY = 0;
    let length = 0;

    if (userDataPos.isMultiSpan) {
      length =
        UI.height - this.getHeightByRoofPitch(false) - this.beamInfo.height;
      scaleY = length / this.postInfo.height;
    } else {
      length =
        UI.height - this.getHeightByRoofPitch(true) - this.beamInfo.height;
      scaleY = length / this.postInfo.height;
    }
    this.frontPostHeight = length;
    // scaleY -= this.geometryManager.SUPERIOR_BEAM.S65x160.height;

    let exceedMoveBackLimit = false;

    //Rakecut
    if (
      UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutLeftType == RAKECUT_TYPE.STEP
    ) {
      //Move front post to back
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.isMultiSpan && userDataPos.left) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.isMultiSpan && userDataPos.left) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
          offsetX -= UI.overhangLeft;
          offsetZ -= this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_);
          scaleY =
            this.utils.getHeightByAngle(
              UI.height - this.beamInfo.height,
              length,
              UI.patiosPitch,
              -1
            ) / this.postInfo.height;

          if (
            UI.rakeCutLeftVer + this.beamLeftCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            exceedMoveBackLimit = true;
          }
        } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutLeftVer;
          if (moveBack > 0) {
            offsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scaleY =
              this.utils.getHeightByAngle(
                UI.height - this.beamInfo.height,
                length,
                UI.patiosPitch,
                -1
              ) / this.postInfo.height;
          }
        }
      }
    }
    if (
      UI.rakeCutRightType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutRightType == RAKECUT_TYPE.STEP
    ) {
      //Move front post to back
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.isMultiSpan && userDataPos.right) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.isMultiSpan && userDataPos.right) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
          offsetX += UI.overhangRight;
          offsetZ -= this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_);
          scaleY =
            this.utils.getHeightByAngle(
              UI.height - this.beamInfo.height,
              length,
              UI.patiosPitch,
              -1
            ) / this.postInfo.height;

          if (
            UI.rakeCutRightVer + this.beamRightCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            exceedMoveBackLimit = true;
          }
        } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutRightVer;
          if (moveBack > 0) {
            offsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scaleY =
              this.utils.getHeightByAngle(
                UI.height - this.beamInfo.height,
                length,
                UI.patiosPitch,
                -1
              ) / this.postInfo.height;
          }
        }
      }
    }
    //End rakecut

    //post can not go behind existing wall
    if (exceedMoveBackLimit) {
      return;
    }

    mesh.position.set(offsetX, 0, offsetZ);
    mesh.scale.setY(scaleY);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      position: userDataPos,
      views: views,
    };
    this.scene.add(mesh);
  }
  public addPostAngleRakecut(offsetX: number, userDataPos: any) {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE &&
      UI.rakeCutRightType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let height =
      UI.height - this.getHeightByRoofPitch(true) - this.beamInfo.height;
    let offsetZ =
      -UI.existingWidth1 / 2 + UI.span + UI.multiSpan - this.beamInfo.width / 2;

    let scaleY = height / this.postInfo.height;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.left) {
        let postLeft = new Mesh(
          this.postInfo.geometry,
          MaterialManager.Instance().POST
        );
        postLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postLeft.position.set(offsetX + this.beamLeftCutSizeInfo.l, 0, offsetZ);
        postLeft.scale.setY(scaleY);

        let isIntersect = false;
        let boxPost = new Box3().setFromObject(postLeft);
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersect = true;
            }
          });

        if (!isIntersect) {
          this.scene.add(postLeft);
        }
      }
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.right) {
        let postRight = new Mesh(
          this.postInfo.geometry,
          MaterialManager.Instance().POST
        );
        postRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postRight.position.set(
          offsetX - this.beamRightCutSizeInfo.l,
          0,
          offsetZ
        );
        postRight.scale.setY(scaleY);

        let isIntersect = false;
        let boxPost = new Box3().setFromObject(postRight);
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersect = true;
            }
          });

        if (!isIntersect) {
          this.scene.add(postRight);
        }
      }
    }
  }
  private addPostStepRakecut() {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userDataPos = { rakecut: true };

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetXL = -UI.totalBayLength / 2;
      let offsetZF =
        UI.span +
        UI.multiSpan -
        UI.existingWidth1 / 2 -
        this.postInfo.width / 2;

      let scaleYF =
        this.utils.getHeightByAngle(
          UI.height - this.beamInfo.height,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) / this.postInfo.height;

      let scaleYB = scaleYF;
      let offsetZB = offsetZF;

      let moveRight = UI.rakeCutLeftHor - UI.overhangLeft;
      if (moveRight > 0) {
        offsetXL += moveRight;
      }

      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          this.utils.getHeightByAngle(
            UI.height - this.beamInfo.height,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) / this.postInfo.height;
      }

      let postFront = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postFront.position.set(offsetXL + this.postInfo.width / 2, 0, offsetZF);
      postFront.scale.setY(scaleYF);
      this.scene.add(postFront);

      let postBack = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postBack.position.set(offsetXL + this.postInfo.width / 2, 0, offsetZB);
      postBack.scale.setY(scaleYB);
      this.scene.add(postBack);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetXR = UI.totalBayLength / 2 - this.postInfo.width;
      let offsetZF =
        UI.span +
        UI.multiSpan -
        UI.existingWidth1 / 2 -
        this.postInfo.width / 2;

      let scaleYF =
        this.utils.getHeightByAngle(
          UI.height - this.beamInfo.height,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) / this.postInfo.height;

      let scaleYB = scaleYF;
      let offsetZB = offsetZF;

      let moveLeft = UI.rakeCutRightHor - UI.overhangRight;
      if (moveLeft > 0) {
        offsetXR -= moveLeft;
      }

      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          this.utils.getHeightByAngle(
            UI.height - this.beamInfo.height,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) / this.postInfo.height;
      }

      let postFront = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postFront.position.set(offsetXR + this.postInfo.width / 2, 0, offsetZF);
      postFront.scale.setY(scaleYF);
      this.scene.add(postFront);

      let postBack = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postBack.position.set(offsetXR + this.postInfo.width / 2, 0, offsetZB);
      postBack.scale.setY(scaleYB);
      this.scene.add(postBack);
    }
  }
  public addBeam(userDataPos: any) {
    if (userDataPos.isMultiSpan && UI.multiSpan === 0) {
      return;
    }
    //const mesh = new Mesh(this.beamInfo.geometry, MaterialManager.Instance().BEAM);

    let offsetZ = -UI.existingWidth1 / 2;
    let offsetY = 0;
    let scaleX = this.roofWidth / this.beamInfo.length;
    let beamLength = this.roofWidth;

    if (userDataPos.isMultiSpan) {
      offsetY = UI.height - this.getHeightByRoofPitch(false);
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetY = UI.height - this.getHeightByRoofPitch(true);
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }

    let offsetXL = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetXR = this.totalBaySize / 2 + UI.overhangRight;

    let views: Print2DView[] = [];
    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    //Cutout - in case of multi span & apply only the back beam
    if (
      this.APP.sltCutOut.currentValue == 1 &&
      this.enableCutout &&
      !userDataPos.isMultiSpan
    ) {
      if (UI.span <= UI.existingWidth1) {
        if (this.APP.sltExistingType.currentValue == 1) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          scaleX =
            (this.roofWidth - this.APP.sldExistingLength2.currentValue) /
            this.beamInfo.length;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 2) {
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          scaleX =
            (this.roofWidth - this.APP.sldExistingLength2.currentValue) /
            this.beamInfo.length;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          scaleX =
            (this.roofWidth - this.APP.sldExistingLength2.currentValue * 2) /
            this.beamInfo.length;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue * 2;
        }
      }
    }

    //rake cut - apply only the front beam
    if (UI.multiSpan <= 0 || (UI.multiSpan > 0 && userDataPos.isMultiSpan)) {
      if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetXL += this.beamLeftCutSizeInfo.l;
        scaleX =
          (this.roofWidth - this.beamLeftCutSizeInfo.l) / this.beamInfo.length;
        beamLength -= this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetXL += UI.rakeCutLeftHor;
        scaleX = (this.roofWidth - UI.rakeCutLeftHor) / this.beamInfo.length;
        beamLength -= UI.rakeCutLeftHor;
      }

      if (UI.rakeCutRightType == RAKECUT_TYPE.ANGLE) {
        offsetXR -= this.beamRightCutSizeInfo.l;
        scaleX =
          (this.roofWidth - this.beamRightCutSizeInfo.l) / this.beamInfo.length;
        beamLength -= this.beamRightCutSizeInfo.l;
      } else if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
        offsetXR -= UI.rakeCutRightHor;
        scaleX = (this.roofWidth - UI.rakeCutRightHor) / this.beamInfo.length;
        beamLength -= UI.rakeCutRightHor;
      }
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      userDataPos,
      0,
      "x",
      this.geoBeamJoint
    );
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    jointGeo: GeometryInfo
  ) {
    // Map list cut beam here
    const beamStartX = pos.x;
    const beamEndX = pos.x + length;

    let endOfBayX = -UI.totalBayLength / 2;
    let startCutBeamX = beamStartX;
    let endCutBeamX = beamStartX;

    const beams = this.utils.getListSeperateBeamsByBays(
      endOfBayX,
      beamStartX,
      beamEndX,
      endCutBeamX,
      startCutBeamX
    );

    for (let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1];
      startCutBeamX = beams[i][0];
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && i == 0,
          hasEndCap:
            UI.beamType == 0 &&
            (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      );
      this.scene.add(beamGroup);
    }
  }
  private addBeamAngleRakecut(cutSide: BUILDING_SIDE) {
    if (
      cutSide == BUILDING_SIDE.LEFT &&
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    if (
      cutSide == BUILDING_SIDE.RIGHT &&
      UI.rakeCutRightType != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let beamSizeInfo = this.utils.getBeamRakeCutInfo(this.APP, cutSide);

    let offsetX = -this.totalBaySize / 2 - UI.overhangLeft + beamSizeInfo.l;
    let offsetZ = UI.span + UI.multiSpan - UI.existingWidth1 / 2;

    let offsetY = this.utils.getHeightByAngle(
      UI.height,
      UI.span + UI.multiSpan,
      UI.patiosPitch,
      -1
    );
    let beamLength = beamSizeInfo.m;
    let scaleZ = beamLength / this.beamRakecutInfoLeft.length;

    //beam lenght can not go behind existing wall
    if (
      cutSide == BUILDING_SIDE.LEFT &&
      UI.rakeCutLeftVer + beamSizeInfo.k_ > this.MANAGER.patiosLength
    ) {
      beamLength -= beamSizeInfo.b;
      scaleZ = beamLength / this.beamRakecutInfoLeft.length;
    } else if (
      cutSide == BUILDING_SIDE.RIGHT &&
      UI.rakeCutRightVer + beamSizeInfo.k_ > this.MANAGER.patiosLength
    ) {
      beamLength -= beamSizeInfo.b;
      scaleZ = beamLength / this.beamRakecutInfoLeft.length;
    }

    let offsetXStart = 0;
    let offsetXEnd = beamLength;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    let rotYL = Math.atan(UI.rakeCutLeftHor / UI.rakeCutLeftVer);
    let rotYR = Math.atan(UI.rakeCutRightHor / UI.rakeCutRightVer);
    let rotX = this.utils.degreesToRadians(UI.patiosPitch);

    if (cutSide == BUILDING_SIDE.LEFT) {
      const mesh = new Mesh(
        this.beamRakecutInfoLeft.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      beamGroup.position.set(offsetX, offsetY, offsetZ);
      beamGroup.rotation.set(0, rotYL, 0);
      beamGroup.updateMatrixWorld();
      let matrix = beamGroup.matrixWorld;

      if (beamLength > 8000) {
        this.addPostForBeamAngelRakecut(cutSide, matrix);
      }

      beamGroup.rotation.set(rotX, rotYL, 0);
      if (UI.beamType == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }

      this.scene.add(beamGroup);
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      offsetX = this.totalBaySize / 2 + UI.overhangRight - beamSizeInfo.l;
      const mesh = new Mesh(
        this.beamRakecutInfoRight.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      beamGroup.position.set(offsetX, offsetY, offsetZ);
      beamGroup.rotation.set(0, -rotYR, 0);
      beamGroup.updateMatrixWorld();
      let matrix = beamGroup.matrixWorld;
      if (beamLength > 8000) {
        this.addPostForBeamAngelRakecut(cutSide, matrix);
      }

      beamGroup.rotation.set(rotX, -rotYR, 0);
      if (UI.beamType == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }
      this.scene.add(beamGroup);
    }
  }
  private addBeamStepRakecut() {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetXL = -UI.totalBayLength / 2 - UI.overhangLeft;
      let offsetZ = UI.span + UI.multiSpan - UI.existingWidth1 / 2;
      let offsetY = this.utils.getHeightByAngle(
        UI.height,
        UI.span + UI.multiSpan,
        UI.patiosPitch,
        -1
      );

      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY = this.utils.getHeightByAngle(
          UI.height,
          UI.span + UI.multiSpan - moveBack,
          UI.patiosPitch,
          -1
        );
      }

      let beamLength = UI.rakeCutLeftHor + this.postInfo.width;
      if (UI.rakeCutLeftHor < UI.overhangLeft) {
        beamLength = UI.overhangLeft + this.postInfo.width;
      }

      let beamGroup;

      if (UI.beamType == 0) {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutLeft,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXL, offsetY, offsetZ),
          new Vector3(),
          views,
          1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      } else {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutLeft,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXL, offsetY, offsetZ),
          new Vector3(),
          views,
          1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      }

      this.scene.add(beamGroup);
    }
    if (UI.rakeCutRightType == RAKECUT_TYPE.STEP) {
      let offsetXR = UI.totalBayLength / 2 + UI.overhangRight;
      let offsetZ = UI.span + UI.multiSpan - UI.existingWidth1 / 2;
      let offsetY = this.utils.getHeightByAngle(
        UI.height,
        UI.span + UI.multiSpan,
        UI.patiosPitch,
        -1
      );

      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY = this.utils.getHeightByAngle(
          UI.height,
          UI.span + UI.multiSpan - moveBack,
          UI.patiosPitch,
          -1
        );
      }
      let beamLength = UI.rakeCutRightHor + this.postInfo.width;
      if (UI.rakeCutRightHor < UI.overhangRight) {
        beamLength = UI.overhangRight + this.postInfo.width;
      }

      let beamGroup;

      if (UI.beamType == 0) {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutRight,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXR, offsetY, offsetZ),
          new Vector3(),
          views,
          -1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      } else {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutRight,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXR, offsetY, offsetZ),
          new Vector3(),
          views,
          -1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      }

      this.scene.add(beamGroup);
    }
  }
  private addPostForBeamAngelRakecut(
    cutSide: BUILDING_SIDE,
    beamMatrix: Matrix4
  ) {
    let inf = this.utils.getBeamRakeCutInfo(this.APP, cutSide);

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (cutSide == BUILDING_SIDE.LEFT) {
      let p1 = new Vector3(
        -UI.totalBayLength / 2 - UI.overhangLeft,
        2500,
        -UI.existingWidth1 / 2 + UI.span + UI.multiSpan
      );
      let p2 = new Vector3().addVectors(
        p1,
        new Vector3(0, 0, -1).multiplyScalar(inf.j)
      );
      let p3 = new Vector3().addVectors(
        p1,
        new Vector3(1, 0, 0).multiplyScalar(inf.l)
      );
      let angle = Math.atan(UI.rakeCutLeftHor / UI.rakeCutLeftVer);

      let ver = (Math.cos(angle) * inf.m) / 2;
      let pMid = new Vector3().addVectors(
        p2,
        new Vector3(0, 0, 1).multiplyScalar(ver)
      );
      let pOri = new Vector3(
        -UI.totalBayLength / 2 + UI.overhangLeft,
        2500,
        -UI.existingWidth1 / 2
      );

      let dir = new Vector3().subVectors(p2, p3).normalize();
      let pMid2 = new Vector3().addVectors(p3, dir.multiplyScalar(inf.m / 2));

      let length = new Vector3().subVectors(pMid, pOri).length();
      let height = this.utils.getHeightByAngle(
        UI.height - this.beamInfo.height,
        length,
        UI.patiosPitch,
        -1
      );

      let scaleY = height / this.postInfo.height;

      let postLeft = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { rakecut: true },
        views: views,
      };
      postLeft.position.set(pMid2.x, 0, pMid2.z);
      postLeft.scale.setY(scaleY);
      this.scene.add(postLeft);
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      let p1 = new Vector3(
        UI.totalBayLength / 2 + UI.overhangRight,
        2500,
        -UI.existingWidth1 / 2 + UI.span + UI.multiSpan
      );
      let p2 = new Vector3().addVectors(
        p1,
        new Vector3(0, 0, -1).multiplyScalar(inf.j)
      );
      let p3 = new Vector3().addVectors(
        p1,
        new Vector3(-1, 0, 0).multiplyScalar(inf.l)
      );
      let angle = Math.atan(UI.rakeCutRightHor / UI.rakeCutRightVer);

      let ver = (Math.cos(angle) * inf.m) / 2;
      let pMid = new Vector3().addVectors(
        p2,
        new Vector3(0, 0, 1).multiplyScalar(ver)
      );
      let pOri = new Vector3(
        UI.totalBayLength / 2 + UI.overhangRight,
        2500,
        -UI.existingWidth1 / 2
      );

      let dir = new Vector3().subVectors(p2, p3).normalize();
      let pMid2 = new Vector3().addVectors(p3, dir.multiplyScalar(inf.m / 2));

      let length = new Vector3().subVectors(pMid, pOri).length();
      let height = this.utils.getHeightByAngle(
        UI.height - this.beamInfo.height,
        length,
        UI.patiosPitch,
        -1
      );

      let scaleY = height / this.postInfo.height;

      let postRight = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { rakecut: true },
        views: views,
      };
      postRight.position.set(pMid2.x, 0, pMid2.z);
      postRight.scale.setY(scaleY);
      this.scene.add(postRight);
    }
  }
  private getHeightByRoofPitch(isSpan: boolean): number {
    if (isSpan) {
      return this.utils.tan(UI.patiosPitch) * UI.span;
    } else {
      return this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan);
    }
  }
}
