import { PostAndBeamManager } from "./post-and-beam.manager";
import { RoofManager } from "./roof.manager";
import { HomeComponent as AppComponent } from "../../containers/home/home.component";
import {
  PatiosManager,
  RafterBeamAndInternalBeamInfo,
} from "../patios.manager";
import { Printing2DGeometry } from "src/app/core/models";
import { Group, Box3, Box3Helper, BoxHelper, Vector3 } from "three";
import { GEOMETRY_CATEGORY, CONFIG, GEOMETRY_TYPE } from "src/app/app.config";
import { DimensionManager } from "./dimension.manager";
import {
  CUTOUT_ENABLE,
  EXISTING_BUILDING_CONFIG,
  RAKECUT_TYPE,
} from "src/app/app.constants";
import { environment } from "src/environments/environment";

export class ExistingBuildingManager implements PatiosManager {
  private APP: AppComponent;
  patiosGroup: Group;
  public boundingBox: Box3;
  public postAndBeamManager: PostAndBeamManager;
  private roofManager: RoofManager;
  public dimesionManager: DimensionManager;
  public ignoreBayEvent: boolean = false;
  public rafterBeamAndInternalBeamInfo: RafterBeamAndInternalBeamInfo = {
    numOfInternalRafter: 0,
    numOfSpanBeam: 0,
    numOfMultiSpanBeam: 0,
  };

  private updateEventHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private deferHandle;
  private deferTimeout = EXISTING_BUILDING_CONFIG.CUTOUT_DEFFER_TIME_OUT;
  public get cutoutCondition(): boolean {
    return (
      [this.APP.sldSpan, this.APP.sldMultiSpan, this.APP.sldFrontOverhang]
        .map((x) => x.currentValue)
        .reduce((a, b) => a + b, 0) -
        this.APP.sldExistingWidth1.currentValue >=
      EXISTING_BUILDING_CONFIG.CUTOUT_ALLOW
    );
  }
  public get rakecutCondition(): boolean {
    let patiosWidth =
      this.APP.dialogEditBay.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let patiosLength =
      this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue;

    return false;
  }

  public patiosLength: number;
  public patiosWidth: number;
  public firstLoad = true;

  constructor(app: AppComponent) {
    this.APP = app;
    this.patiosGroup = new Group();
    this.patiosGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS };
    app.scene.add(this.patiosGroup);

    this.postAndBeamManager = new PostAndBeamManager(app, this);
    this.roofManager = new RoofManager(app, this);
    this.dimesionManager = new DimensionManager(app, this);

    this.registerEvents();
  }

  load(): Promise<any> {
    return Promise.all([
      this.postAndBeamManager.optimize(),
      this.roofManager.optimize(),
      this.dimesionManager.optimize(),
    ]).then(() => {
      this.postAndBeamManager.load();
      this.roofManager.load();
      this.dimesionManager.load();
    });
  }
  loadBracket(bracketQuantity: number): void {}
  loadBracketCutout(bracketQuantity: number): void {}
  loadSideGableBracket(bracketQuantity: number): void {}
  loadRafterBeamAndInternalBeam(info: RafterBeamAndInternalBeamInfo): void {
    this.rafterBeamAndInternalBeamInfo = info;
    this.postAndBeamManager.addEndRafterBeam(info);
  }
  public destroy(): void {
    this.postAndBeamManager.destroy();
    this.roofManager.destroy();
    this.dimesionManager.destroy();
    this.unRegisterEvent();
  }

  registerEvents(): void {
    this.updateEventHandle = this.updateDefer.bind(this);
    this.controlsToRegisterEvent = [
      this.APP.sldExistingLength,
      this.APP.sldExistingLength2,
      this.APP.sltExistingType,
      this.APP.sltCutOut,
      this.APP.sldFrontOverhang,
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColourBarge,
      this.APP.sltColourGutter,
      this.APP.sltColourDownpipe,
      this.APP.sltColourRoof,
      this.APP.sldSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sldEaveWidth,
      this.APP.sldFasciaDepth,
      this.APP.sldExistingWidth1,
      this.APP.sldExistingWidth2,
      this.APP.dialogEditBay,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldBackOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,
      this.APP.sltColourBeam,
      this.APP.sltColourPost,
      this.APP.sltColourBracket,
      this.APP.sltColourFasciaBracket,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
      this.APP.sltLeftCutType,
      this.APP.sldLeftCutVertical,
      this.APP.sldLeftCutHorizontal,
      this.APP.sltRightCutType,
      this.APP.sldRightCutVertical,
      this.APP.sldRightCutHorizontal,
      this.APP.sldBuildingHeight,
      this.APP.sltPanelDirection,
      this.APP.sltPanelDirectionShow,
      this.APP.sltBeamLayoutShow,
      this.APP.btnTurnOnPanel,
    ];
    this.controlsToRegisterEvent.forEach((c) => {
      c.addAction(this.updateEventHandle);
    });
  }
  unRegisterEvent(): void {
    this.controlsToRegisterEvent.forEach((c) =>
      c.removeAction(this.updateEventHandle)
    );
  }
  updateDefer(previousValue: number, currentValue: number) {
    if (
      this.APP.sltCutOut.currentValue == CUTOUT_ENABLE.YES ||
      this.APP.sltLeftCutType.currentValue != RAKECUT_TYPE.NONE ||
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.NONE
    ) {
      if (this.deferHandle) {
        clearTimeout(this.deferHandle);
      }
      this.deferHandle = setTimeout(() => {
        this.update(previousValue, currentValue);
      }, this.deferTimeout);
    } else {
      this.update(previousValue, currentValue);
    }
  }
  update(preVal: number, curVal: number) {
    let totalBaySize = this.APP.dialogEditBay.totalBaySize;

    let diff = this.APP.sldExistingLength.currentValue - totalBaySize;

    this.patiosWidth =
      totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    this.patiosLength =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue;

    //let cutoutCondition = this.cutoutCondition.map(x => x.currentValue).reduce((a,b) => a + b, 0) - this.APP.sldExistingWidth1.currentValue >= EXISTING_BUILDING_CONFIG.CUTOUT_ALLOW;
    if (this.APP.sltExistingType.currentValue == 1) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        this.patiosGroup.position.setX(
          -diff / 2 +
            this.APP.sldLeftOverhang.currentValue -
            this.APP.sldExistingLength2.currentValue
        );
      } else {
        this.patiosGroup.position.setX(
          -diff / 2 + this.APP.sldLeftOverhang.currentValue
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 2) {
      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        this.patiosGroup.position.setX(
          diff / 2 -
            this.APP.sldRightOverhang.currentValue +
            this.APP.sldExistingLength2.currentValue
        );
      } else {
        this.patiosGroup.position.setX(
          diff / 2 - this.APP.sldRightOverhang.currentValue
        );
      }
    } else {
      this.patiosGroup.position.setX(0);
    }

    Promise.all([this.roofManager.load(), this.postAndBeamManager.load()]).then(
      () => {
        this.loadRafterBeamAndInternalBeam(this.rafterBeamAndInternalBeamInfo);
        this.boundingBox = this.getPatiosBox();
        this.dimesionManager.draw();
      }
    );
  }
  getOutlines(): Printing2DGeometry {
    let dimensionOutline = this.dimesionManager.getOutlines();
    return {
      lines: [
        ...this.postAndBeamManager.getSection().lines,
        ...this.roofManager.getOutLines().lines,
        ...dimensionOutline.lines,
      ],
      texts: dimensionOutline.texts,
    };
  }
  public getPatiosBox(): Box3 {
    let left =
      -this.APP.dialogEditBay.totalBaySize / 2 -
      this.APP.sldLeftOverhang.currentValue;
    let right =
      this.APP.dialogEditBay.totalBaySize / 2 +
      this.APP.sldRightOverhang.currentValue;
    let front =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue;
    let back = 0;
    let height = this.APP.sldBuildingHeight.currentValue;

    let box = new Box3();
    box = box.expandByPoint(new Vector3(left, 0, front));
    box = box.expandByPoint(new Vector3(right, height, back));

    if (this.APP.sltExistingType.currentValue == 1) {
      let diff =
        (this.APP.sldExistingLength.currentValue -
          this.APP.dialogEditBay.totalBaySize) /
        2;
      box.translate(new Vector3(-diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(this.APP.sldLeftOverhang.currentValue, 0, 0));

      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(-this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 2) {
      let diff =
        (this.APP.sldExistingLength.currentValue -
          this.APP.dialogEditBay.totalBaySize) /
        2;
      box.translate(new Vector3(diff, 0, 0));
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
      box.translate(new Vector3(-this.APP.sldRightOverhang.currentValue, 0, 0));

      if (this.APP.sltCutOut.currentValue == 1 && this.cutoutCondition) {
        box.translate(
          new Vector3(this.APP.sldExistingLength2.currentValue, 0, 0)
        );
      }
    } else if (this.APP.sltExistingType.currentValue == 3) {
      box.translate(
        new Vector3(0, 0, -this.APP.sldExistingWidth1.currentValue / 2)
      );
    }

    // if(!environment.production){
    //     this.APP.scene.remove(...this.APP.scene.children.filter(o => o.userData.type == 'BOXHELPER'));
    //     let boxHelper = new Box3Helper(box);
    //     boxHelper.userData = { type: "BOXHELPER" };
    //     this.APP.scene.add(boxHelper);
    // }

    return box;
  }
}
