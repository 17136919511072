import _ from "lodash";
import UpstandBraket, {
  UpstandBraketOptions,
} from "src/app/models/UpstandBraket.model";
import {
  Box3,
  BoxBufferGeometry,
  BoxHelper,
  BufferGeometry,
  Geometry,
  Group,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  Vector3,
} from "three";
import { FasciaManager } from ".";
import { GEOMETRY_CATEGORY, GEOMETRY_TYPE } from "../../app.config";
import {
  BUILDING_SIDE,
  EXISTING_BUILDING_CONFIG,
  RAKECUT_TYPE,
} from "../../app.constants";
import {
  HomeComponent as AppComponent,
  HomeComponent,
} from "../../containers/home/home.component";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  GeometryInfo,
  LineType,
  Print2DView,
  Printing2DGeometry,
  Printing2DLine,
  ViewType,
} from "../models";
import { UI } from "../ui";
import { Util, getBeamEndCapCode } from "../utils";

export class PostAndBeamManager {
  private scene: Group;
  private APP: AppComponent;
  private MANAGER: FasciaManager;
  private material: Material;
  private utils: Util;
  private geometryManager: GeometryManager;
  private postInfo: GeometryInfo;
  public beamInfo: GeometryInfo;
  private beamRakecutInfoLeft: GeometryInfo;
  private beamRakecutInfoRight: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_groundBase: GeometryInfo;
  private eventHandleId: any;
  private roofWidth: number;
  private existingTypeLeftX: number;
  private existingTypeRightX: number;
  private existingType: number;
  private totalBaySize: number;
  private frontPostHeight: number;
  private downpipeGroup: Group;
  private downpipeStepRakeCutGroupLeft: Group;
  private downpipeStepRakeCutGroupRight: Group;
  public geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private geo_roofBase: GeometryInfo;
  private geoBraketExtL_2: GeometryInfo;
  private geoBraketExtR_2: GeometryInfo;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;

  private geo_beamStepRakecutLeft: GeometryInfo;
  private geo_beamStepRakecutRight: GeometryInfo;
  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private beamLeftCutSizeInfo: any;
  private beamRightCutSizeInfo: any;

  public geoBraket_1: GeometryInfo;
  public geoBraket_2: GeometryInfo;
  private geoBeam: GeometryInfo;
  private geoBeamEndCap: GeometryInfo;
  private geoHouseBeam: GeometryInfo;
  private geoHouseBeamEndCap: GeometryInfo;

  private offsetYBraket = 0;
  private offsetBraketZ = 0;

  k: number;
  k_: number;
  j: number;
  l: number;
  m: number;
  v: number;

  constructor(app: AppComponent, fasciaManager: FasciaManager) {
    this.APP = app;
    this.MANAGER = fasciaManager;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();
    this.scene = fasciaManager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    this.registerEvent();
  }
  public destroy(): void {
    this.unregisterEvent();
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldMultiSpan,
      this.APP.sldExistingWallHeight,
      this.APP.sltRoofPitch,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sldExistingWidth1,
      this.APP.sltExistingType,
      this.APP.dialogEditBay,
      this.APP.sldEaveWidth,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));
    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltHouseBeamSize,
      this.APP.sltColumnType,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    //this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    //this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.beamInfo = this.geometryManager.getBeam();
      this.beamInfo.geometry
        .rotateY(Math.PI / 2)
        .translate(this.beamInfo.length / 2, -this.beamInfo.height / 2, 0);
      //.scale(this.APP.sldBaySize.currentValue / this.beamInfo.length, 1, 1);

      this.geoBeamJoint = this.geometryManager.getBeamJoint();
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint();

      this.beamRakecutInfoLeft = this.geometryManager.getBeam();
      this.beamRakecutInfoLeft.geometry.translate(
        this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.beamRakecutInfoRight = this.geometryManager.getBeam();
      this.beamRakecutInfoRight.geometry.translate(
        -this.beamInfo.width / 2,
        -this.beamInfo.height / 2,
        -this.beamInfo.length / 2
      );

      this.geo_beamStepRakecutLeft = this.geometryManager.getBeam();
      this.geo_beamStepRakecutLeft.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_beamStepRakecutLeft.length / 2,
          -this.geo_beamStepRakecutLeft.height / 2,
          -this.geo_beamStepRakecutLeft.width / 2
        );

      this.geo_beamStepRakecutRight = this.geometryManager.getBeam();
      this.geo_beamStepRakecutRight.geometry
        .rotateY(Math.PI / 2)
        .translate(
          -this.geo_beamStepRakecutRight.length / 2,
          -this.geo_beamStepRakecutRight.height / 2,
          -this.geo_beamStepRakecutRight.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        -this.geo_beamEndCap.height / 2,
        0
      );

      this.postInfo = this.geometryManager.getPost();
      this.postInfo.geometry
        .rotateX(Math.PI / 2)
        .translate(0, this.postInfo.height / 2, 0);
      //.scale(1, scalePostHeight, 1);

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        this.geo_groundBase.width / 2,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();
      this.geo_roofBase = this.geometryManager.getRoofBase();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);

      this.downpipeStepRakeCutGroupLeft = new Group();
      this.downpipeStepRakeCutGroupLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupLeft);

      this.downpipeStepRakeCutGroupRight = new Group();
      this.downpipeStepRakeCutGroupRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeStepRakeCutGroupRight);

      this.geoBraket_1 = this.geometryManager.getUpstandBraket(1);
      this.geoBraket_1.geometry.rotateX(Math.PI / 2);
      this.geoBraket_1.geometry.translate(0, this.geoBraket_1.length / 2, 0);
      this.geoBraket_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraket_2.geometry.rotateY(Math.PI);
      this.geoBraket_2.geometry.rotateX(Math.PI / 2);
      this.geoBraket_2.geometry.translate(
        0,
        this.geoBraket_2.length / 2,
        -this.geoBraket_2.height / 4
      );

      this.geoBraketExtL_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtL_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateY(-Math.PI / 2);
      this.geoBraketExtL_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtL_2.geometry.translate(
        -this.geoBraketExtL_2.height / 2,
        this.geoBraketExtL_2.length / 2,
        this.geoBraketExtL_2.height / 3 - this.geoBraket_1.width / 2
      );
      //Right
      this.geoBraketExtR_2 = this.geometryManager.getUpstandBraket(2);
      this.geoBraketExtR_2.geometry.rotateX(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateY(Math.PI / 2);
      this.geoBraketExtR_2.geometry.rotateZ(-Math.PI);
      this.geoBraketExtR_2.geometry.translate(
        this.geoBraketExtR_2.height / 2,
        this.geoBraketExtR_2.length / 2,
        this.geoBraketExtR_2.height / 3 - this.geoBraket_1.width / 2
      );

      this.geoBeam = this.geometryManager.getBeam();
      this.geoBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(0, this.geoBeam.height / 2, 0);

      this.geoHouseBeam = this.geometryManager.getHouseBeam();
      this.geoHouseBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geoHouseBeam.length / 2,
          -this.geoHouseBeam.height / 2,
          0
        );

      this.geoBeamEndCap = this.geometryManager.getBeamEndCap();
      this.geoBeamEndCap.geometry
        .translate(
          0,
          this.geoBeamEndCap.height / 2,
          this.geoBeamEndCap.length / 2
        )
        .rotateY(Math.PI / 2);

      this.geoHouseBeamEndCap = this.geometryManager.getHouseBeamEndCap();
      this.geoHouseBeamEndCap.geometry.translate(
        0,
        -this.geoHouseBeamEndCap.height / 2,
        0
      );

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type === GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type === GEOMETRY_TYPE.UPSTAND_BRAKET_EXT
        )
      );
      this.downpipeGroup.children = [];
      this.downpipeStepRakeCutGroupLeft.children = [];
      this.downpipeStepRakeCutGroupRight.children = [];

      this.beamLeftCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.LEFT
      );
      this.beamRightCutSizeInfo = this.utils.getBeamRakeCutInfo(
        this.APP,
        BUILDING_SIDE.RIGHT
      );

      this.totalBaySize = this.APP.dialogEditBay.listBay.reduce(
        (total, val) => total + val.value,
        0
      );
      this.roofWidth = UI.overhangLeft + UI.overhangRight + this.totalBaySize;
      this.existingType = +this.APP.sltExistingType.currentValue;
      this.existingTypeLeftX = 0;
      this.existingTypeRightX = 0;
      if (this.existingType === 1 || this.existingType === 3) {
        this.existingTypeLeftX = UI.eaveWidth;
      }
      if (this.existingType === 2 || this.existingType === 3) {
        this.existingTypeRightX = UI.eaveWidth;
      }
      this.offsetYBraket = 0;
      if (+UI.upstandBraketType !== 0) {
        this.offsetYBraket =
          +UI.upstandBraketType +
          this.geoBeam.height +
          this.geoBraket_1.width * this.utils.tan(UI.patiosPitch);
      }
      if (UI.isUpFasciaUpstandardBracket) {
        this.offsetYBraket -=
          this.geoBraket_1.width * this.utils.tan(UI.patiosPitch);
      }
      this.offsetBraketZ = 0;
      if (+UI.upstandBraketType !== 0) {
        this.offsetBraketZ = this.geoBraket_2.height / 4;
      }
      this.addBeam({ isMultiSpan: false });
      this.addBeam({ isMultiSpan: true });
      this.addBeamAngelRakecut();
      this.addBeamStepRakecut();
      this.addGround();
      let offsetX = -(this.totalBaySize / 2) + this.postInfo.width / 2;
      let offsetXOrigin = -(this.totalBaySize / 2) + this.postInfo.width / 2;
      let idx = 0;
      this.addPost(offsetX, { isMultiSpan: false, left: true });
      this.addUpstandBraketExt({ isMultiSpan: false, left: true });
      this.addPost(offsetX, { isMultiSpan: true, left: true });
      this.addUpstandBraketExt({ isMultiSpan: true, left: true });

      this.APP.dialogEditBay.listBay.map((m) => {
        offsetX += m.value;
        if (idx === this.APP.dialogEditBay.listBay.length - 1) {
          offsetX -= this.postInfo.width;
          this.addPost(offsetX, { isMultiSpan: false, right: true });
          this.addUpstandBraketExt({ isMultiSpan: false, right: true });
          this.addPost(offsetX, { isMultiSpan: true, right: true });
          this.addUpstandBraketExt({ isMultiSpan: true, right: true });
          this.addPostAngelRakecut(offsetX, { right: true });
          this.addPostAngelRakecut(offsetXOrigin, { left: true });
        } else {
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: false,
          });
          this.addPost(offsetX - this.postInfo.width / 2, {
            isMultiSpan: true,
          });
        }
        idx++;
      });

      //this.addTest();
      this.addDownPipe();
      this.addDownPipeStepRakecut();
      this.addPostStepRakecut();
      //this.getSection();
      this.addUpstandBeam();
      this.addUpstandBraket();
      this.addUpstandBraketCutOut();
      this.showBeamOutline();
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = [];
    objs.forEach((el) => {
      if (el.type == "Group") {
        meshes.push(
          ...el.children.filter(
            (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
          )
        );
      }
    });

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private houseBeamHeight() {
    return this.geoBeam.height - this.geoHouseBeam.height;
  }

  public addUpstandBeam() {
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let pos = new Vector3(
      -UI.totalBayLength / 2 - UI.overhangLeft,
      UI.existingWallHeight + this.offsetYBraket + this.houseBeamHeight(),
      UI.eaveWidth +
        this.geoBraket_2.height / 4 +
        this.geoBraket_1.width / 2 -
        UI.existingWidth1 / 2
    );
    let totalLength = UI.totalBayLength + UI.overhangLeft + UI.overhangRight;
    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (
        UI.existingType == BUILDING_SIDE.LEFT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        totalLength -= UI.existingLength2;
        pos.setX(pos.x + UI.existingLength2);
      }
      if (
        UI.existingType == BUILDING_SIDE.RIGHT ||
        UI.existingType == BUILDING_SIDE.BOTH
      ) {
        totalLength -= UI.existingLength2;
      }
    }
    let views: Print2DView[];
    views = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    this.cutStandardBeamToEqualLengthBeam(
      this.geoHouseBeam,
      this.geoHouseBeamEndCap,
      totalLength,
      pos,
      new Vector3(),
      views,
      1,
      { back: true },
      0,
      "x",
      this.geoHouseBeamJoint
    );
    // Beam cutout
    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      let offsetHeightCutout = +this.offsetYBraket + this.houseBeamHeight();
      if (UI.isUpFasciaUpstandardBracket) {
        offsetHeightCutout +=
          UI.existingWidth1 * this.utils.tan(UI.patiosPitch);
      } else {
        offsetHeightCutout -=
          UI.existingWidth1 * this.utils.tan(UI.patiosPitch);
      }

      if (offsetHeightCutout < 0) {
        return;
      }
      if (UI.existingType == 1 || UI.existingType == 3) {
        this.cutStandardBeamToEqualLengthBeam(
          this.geoHouseBeam,
          this.geoHouseBeamEndCap,
          UI.existingLength2,
          new Vector3(
            -UI.totalBayLength / 2 - UI.overhangLeft,
            UI.existingWallHeight + offsetHeightCutout,
            UI.eaveWidth +
              this.geoBraket_2.height / 4 +
              this.geoBraket_1.width / 2 +
              UI.existingWidth1 / 2
          ),
          new Vector3(),
          views,
          1,
          { back: true },
          0,
          "x",
          this.geoHouseBeamJoint
        );
      }
      if (UI.existingType == 2 || UI.existingType == 3) {
        this.cutStandardBeamToEqualLengthBeam(
          this.geoHouseBeam,
          this.geoHouseBeamEndCap,
          UI.existingLength2,
          new Vector3(
            UI.totalBayLength / 2 - UI.existingLength2 + UI.overhangRight,
            UI.existingWallHeight + offsetHeightCutout,
            UI.eaveWidth +
              this.geoBraket_2.height / 4 +
              this.geoBraket_1.width / 2 +
              UI.existingWidth1 / 2
          ),
          new Vector3(),
          views,
          1,
          { back: true },
          0,
          "x",
          this.geoHouseBeamJoint
        );
      }
    }
  }

  public addUpstandBraket(numOfBraket = 2) {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET
      )
    );
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = UI.upstandBraketType + this.houseBeamHeight();

    const _bracketOffsetXExt = this.geoBraket_2.height / 5;
    let distance = 1000;
    let offsetX = -UI.existingLength1 / 2;
    let bayMainLength = UI.totalBayLength;
    if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        bayMainLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        bayMainLength -= 2 * UI.existingLength2;
      }
    }

    if (UI.existingType == 0) {
      let startX =
        -bayMainLength / 2 > -UI.existingLength1 / 2
          ? -bayMainLength / 2
          : -UI.existingLength1 / 2;
      let endX =
        bayMainLength / 2 < UI.existingLength1 / 2
          ? bayMainLength / 2
          : UI.existingLength1 / 2;
      distance = (endX - startX) / (numOfBraket - 1);
      offsetX = startX;
    } else if (UI.existingType == 1) {
      distance = (bayMainLength - _bracketOffsetXExt) / (numOfBraket - 1);
      offsetX = -bayMainLength / 2 + _bracketOffsetXExt;
      if (bayMainLength > UI.existingLength1 - UI.eaveWidth) {
        distance =
          (UI.existingLength1 - UI.eaveWidth - _bracketOffsetXExt) /
          (numOfBraket - 1);
      }
      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX =
          -bayMainLength / 2 + UI.existingLength2 / 2 + _bracketOffsetXExt;
      }
    } else if (UI.existingType == 2) {
      offsetX = -bayMainLength / 2;
      distance = (bayMainLength - _bracketOffsetXExt) / (numOfBraket - 1);

      if (bayMainLength > UI.existingLength1 - UI.eaveWidth) {
        offsetX =
          -bayMainLength / 2 +
          (bayMainLength - UI.existingLength1 + UI.eaveWidth);
        distance =
          (UI.existingLength1 - _bracketOffsetXExt - UI.eaveWidth) /
          (numOfBraket - 1);
      }

      if (!HomeComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
    } else if (UI.existingType == 3) {
      distance = (bayMainLength - _bracketOffsetXExt * 2) / (numOfBraket - 1);
      offsetX = -bayMainLength / 2 + _bracketOffsetXExt;
    }

    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2: this.geoBraket_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userData = {
      type: GEOMETRY_TYPE.UPSTAND_BRAKET,
      views: views,
    };
    for (let i = 0; i < numOfBraket; i++) {
      let posX = offsetX;
      if (i == 0) {
        posX = posX + this.geoBraket_1.width / 2;
      } else if (i == numOfBraket - 1) {
        posX = posX - this.geoBraket_1.width / 2;
      }
      new UpstandBraket({
        ...optionBrakets,
        position: new Vector3(
          posX,
          UI.existingWallHeight,
          UI.eaveWidth - UI.existingWidth1 / 2
        ),
        userData: userData,
      });
      offsetX += distance;
    }
  }

  public addUpstandBraketCutOut(numOfBraket = 2) {
    this.scene.remove(
      ...this.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
      )
    );
    if (HomeComponent.ins.sltCutOut._isDisable || UI.cutOutType == 0) {
      return;
    }
    if (UI.upstandBraketType == 0 || UI.listBay.length == 0) {
      return;
    }
    let height = UI.upstandBraketType + this.houseBeamHeight();

    if (UI.isUpFasciaUpstandardBracket) {
      height += UI.existingWidth1 * this.utils.tan(UI.patiosPitch);
    } else {
      height -= UI.existingWidth1 * this.utils.tan(UI.patiosPitch);
    }

    if (height < 0) {
      return;
    }
    const _bracketOffsetXExt = this.geoBraket_2.height / 5;
    if (UI.existingType == 1 || UI.existingType == 3) {
      let _offsetZ = UI.existingWidth2 > 0 ? UI.overhangLeft : 0;
      let distance =
        (UI.existingLength2 - UI.eaveWidth - UI.overhangLeft + _offsetZ) /
        (numOfBraket - 1);
      let offsetZ = -UI.totalBayLength / 2 - _offsetZ + _bracketOffsetXExt;

      let optionBrakets: UpstandBraketOptions = {
        container: this.scene,
        position: new Vector3(),
        geoBraket_1: this.geoBraket_1,
        geoBraket_2: this.geoBraket_2,
        height: height,
        material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
      };
      let views: Print2DView[] = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
      let userData = {
        type: GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT,
        views: views,
      };
      for (let i = 0; i < numOfBraket; i++) {
        let posX = offsetZ;
        if (i == 0) {
          posX = posX + this.geoBraket_1.width / 2;
        } else if (i == numOfBraket - 1) {
          posX = posX - this.geoBraket_1.width / 2;
        }
        new UpstandBraket({
          ...optionBrakets,
          position: new Vector3(
            posX,
            UI.existingWallHeight,
            UI.eaveWidth + UI.existingWidth1 / 2
          ),
          userData: userData,
        });
        offsetZ += distance;
      }
    }
    if (UI.existingType == 2 || UI.existingType == 3) {
      let _offsetZ = UI.existingWidth2 > 0 ? UI.overhangRight : 0;
      let distance =
        (UI.existingLength2 - (UI.overhangRight + UI.eaveWidth) + _offsetZ) /
        (numOfBraket - 1);
      let offsetZ =
        UI.totalBayLength / 2 -
        UI.existingLength2 +
        UI.eaveWidth +
        UI.overhangRight -
        _bracketOffsetXExt;
      let optionBrakets: UpstandBraketOptions = {
        container: this.scene,
        position: new Vector3(),
        geoBraket_1: this.geoBraket_1,
        geoBraket_2: this.geoBraket_2,
        height: height,
        material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
      };
      let views: Print2DView[] = [
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
      let userData = {
        type: GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT,
        views: views,
      };
      for (let i = 0; i < numOfBraket; i++) {
        let posX = offsetZ;
        if (i == 0) {
          posX = posX + this.geoBraket_1.width / 2;
        } else if (i == numOfBraket - 1) {
          posX = posX - this.geoBraket_1.width / 2;
        }
        new UpstandBraket({
          ...optionBrakets,
          position: new Vector3(
            posX,
            UI.existingWallHeight,
            UI.eaveWidth + UI.existingWidth1 / 2
          ),
          userData: userData,
        });
        offsetZ += distance;
      }
    }
  }
  private addDownPipe() {
    if (UI.isUpFasciaUpstandardBracket) {
      return;
    }
    let offsetZ =
      UI.span +
      UI.multiSpan +
      UI.eaveWidth +
      100 -
      UI.existingWidth1 / 2 +
      this.offsetBraketZ;
    let offsetX = -this.totalBaySize / 2 + 50;
    if (this.APP.sltExistingType.currentValue == BUILDING_SIDE.LEFT) {
      offsetX = this.totalBaySize / 2 - 50;
    }
    //Rakecut
    //Downpipe will be in left
    if (UI.rakeCutLeftType != RAKECUT_TYPE.NONE) {
      if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetX = -this.totalBaySize / 2 + this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetX = -this.totalBaySize / 2 + UI.rakeCutLeftHor + UI.overhangFront;
      }
    }
    //Downpipe will be in right
    else {
      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        offsetX = this.totalBaySize / 2 - this.beamRightCutSizeInfo.l;
      } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        offsetX = this.totalBaySize / 2 - UI.rakeCutRightHor - UI.overhangFront;
      }
    }

    this.downpipeGroup.position.set(offsetX, 0, offsetZ);

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight,
        UI.span + UI.multiSpan + UI.overhangFront,
        UI.patiosPitch,
        -1
      ) +
      this.geo_roofBase.height +
      this.offsetYBraket;

    this.downpipeGroup = this.utils.createDownpipeGroup(
      this.APP,
      this.downpipeGroup,
      this.geo_downPipe,
      this.geo_downPipeL,
      offsetX,
      0,
      offsetZ,
      offsetZ,
      0,
      UI.overhangFront,
      offsetY,
      true
    );
  }
  private addDownPipeStepRakecut() {
    if (UI.isUpFasciaUpstandardBracket) {
      return;
    }
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.STEP &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.STEP
    ) {
      return;
    }

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetX = -this.totalBaySize / 2 + 50;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight,
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutLeftVer,
          UI.patiosPitch,
          -1
        ) +
        this.geometryManager.getRoofBase().height -
        30 +
        this.offsetYBraket;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront +
        UI.eaveWidth +
        40 -
        UI.existingWidth1 / 2 -
        UI.rakeCutLeftVer +
        this.offsetBraketZ;

      this.downpipeStepRakeCutGroupLeft.position.set(offsetX, 0, offsetZ);

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

      let scaleY = offsetY / this.geo_downPipe.height;

      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = userData;
      meshPipe.scale.setY(scaleY);

      this.downpipeStepRakeCutGroupLeft.add(meshPipe);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetX = this.totalBaySize / 2 - 50;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight,
          UI.span + UI.multiSpan + UI.overhangFront - UI.rakeCutRightVer,
          UI.patiosPitch,
          -1
        ) +
        this.geometryManager.getRoofBase().height -
        30 +
        this.offsetYBraket;
      let offsetZ =
        UI.span +
        UI.multiSpan +
        UI.overhangFront +
        UI.eaveWidth +
        40 -
        UI.existingWidth1 / 2 -
        UI.rakeCutRightVer +
        this.offsetBraketZ;

      this.downpipeStepRakeCutGroupRight.position.set(offsetX, 0, offsetZ);

      if (this.APP.sltGutterType.currentValue == 0) {
        offsetY -= 80;
      } else {
        offsetY -= 60;
      }

      let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

      let scaleY = offsetY / this.geo_downPipe.height;

      let meshPipe = new Mesh(
        this.geo_downPipe.geometry,
        MaterialManager.Instance().DOWNPIPE
      );
      meshPipe.userData = userData;
      meshPipe.scale.setY(scaleY);

      this.downpipeStepRakeCutGroupRight.add(meshPipe);
    }
  }
  private addGround() {
    let offsetX = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetZ = -UI.existingWidth1 / 2 - UI.overhangBack;
    let width = this.totalBaySize + UI.overhangLeft + UI.overhangRight;
    let length =
      UI.span +
      UI.multiSpan +
      UI.overhangFront +
      this.APP.sldBackOverhang.currentValue +
      UI.eaveWidth;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ + this.offsetBraketZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private getRoofHeight() {
    const _height = this.utils.getHeightByAngle(
      UI.eaveHeight,
      UI.span + UI.multiSpan + UI.overhangFront,
      UI.patiosPitch,
      -1
    );
    return UI.eaveHeight - _height;
  }
  private updateUI() {
    this.APP.roofHeight = this.getRoofHeight();
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 0));
  }
  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST
    );

    objs = [...objs];

    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: o.userData.type,
        vertices: outlineGeo.vertices,
        views: o.userData.views,
      });

      // var line = new LineSegments( outlineGeo, MaterialManager.Instance().MESH_OUTLINE );
      // line.userData = {type: "OUTLINE"};
      // //line.position.set(o.position.x - 5000, o.position.y, o.position.z);
      // this.scene.add( line );
    }
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    for (let g of beamGroups) {
      for (let c of g.children) {
        c.updateMatrix();

        let objCloned = c.clone();
        objCloned.applyMatrix4(new Matrix4().getInverse(c.matrix));

        let box = new BoxHelper(objCloned);

        c.updateWorldMatrix(true, true);

        box.geometry.applyMatrix4(c.matrixWorld);
        box.geometry.translate(0, 5000, 0);
        box.userData = { type: "COLUMN_OUTLINE" };
        //this.APP.scene.add(box);

        let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
        const planView = _.find(
          g.userData.views,
          (el) => el.viewType == ViewType.PLAN
        );
        const anotherViews = _.filter(
          g.userData.views,
          (el) => el.viewType != ViewType.PLAN
        );
        if (anotherViews.length > 0) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices,
            views: anotherViews,
          });
        }
        if (planView) {
          lsGeometries.push({
            objectType: g.userData.type,
            vertices: outlineGeo.vertices
              .slice(0, 8)
              .filter((el, index) => [0, 1, 4, 5].includes(index)),
            views: [planView],
          });
        }
      }
    }

    let braketGroup = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET ||
        o.userData.type == GEOMETRY_TYPE.UPSTAND_BRAKET_CUTOUT
    );

    for (let g of braketGroup) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        g.children[1] as Mesh,
        60
      );
      g.children[1].updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(g.children[1].matrixWorld);
      outlineGeo.translate(0, 5000, 0);

      let simplifiedGeo = this.simplifyGeo(outlineGeo);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: simplifiedGeo.vertices,
        views: g.userData.views,
      });

      g.children[0].updateMatrix();
      let objCloned = g.children[0].clone();
      objCloned.applyMatrix4(new Matrix4().getInverse(g.children[0].matrix));

      let box = new BoxHelper(objCloned);

      g.children[0].updateWorldMatrix(true, true);

      box.geometry.applyMatrix4(g.children[0].matrixWorld);
      box.geometry.translate(0, 5000, 0);
      box.userData = { type: "COLUMN_OUTLINE" };
      //this.APP.scene.add(box);

      let outlineGeo1 = this.simplifyGeo(box.geometry as BufferGeometry);
      lsGeometries.push({
        objectType: g.userData.type,
        vertices: outlineGeo1.vertices,
        views: g.userData.views,
      });
    }

    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );

    return lineGeo;
  }
  public addPost(offsetX: number, userDataPos: any) {
    if (userDataPos.isMultiSpan && UI.multiSpan === 0) {
      return;
    }
    // // let _flag = false;
    // if (userDataPos.left && UI.overhangLeft > 0 && this.APP.sltExistingType.currentValue == 1) {
    //     // _flag = true;
    //     return;
    // } else if (userDataPos.right && UI.overhangRight > 0 && this.APP.sltExistingType.currentValue == 2) {
    //     // _flag = true;
    //     return;
    // }
    const condition = UI.span + UI.eaveWidth;
    if (!userDataPos.isMultiSpan) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        return;
      }
    } else {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        return;
      }
    }
    let views: Print2DView[] = [];
    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    const mesh = new Mesh(
      this.postInfo.geometry,
      MaterialManager.Instance().POST
    );
    let offsetZ = -UI.existingWidth1 / 2 + UI.eaveWidth;
    if (userDataPos.isMultiSpan) {
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }
    let scaleY = 0;
    let length = 0;
    if (userDataPos.isMultiSpan) {
      length =
        UI.eaveHeight -
        this.getHeightByRoofPitch(false) -
        this.beamInfo.height +
        this.offsetYBraket;
      scaleY = length / this.postInfo.height;
    } else {
      length =
        UI.eaveHeight -
        this.getHeightByRoofPitch(true) -
        this.beamInfo.height +
        this.offsetYBraket;
      scaleY = length / this.postInfo.height;
    }
    this.frontPostHeight = length;
    // scaleY -= this.geometryManager.SUPERIOR_BEAM.S65x160.height;

    //in case of multi span & apply to back post only
    if (
      !userDataPos.isMultiSpan &&
      this.APP.sltCutOut.currentValue == 1 &&
      this.MANAGER.cutoutCondition
    ) {
      if (
        UI.span + UI.eaveWidth > UI.existingWidth1 &&
        UI.span + UI.eaveWidth < UI.existingWidth1 + UI.eaveWidth
      ) {
        if (this.APP.sltExistingType.currentValue == 1 && userDataPos.left) {
          offsetX += this.APP.sldExistingLength2.currentValue;
        } else if (
          this.APP.sltExistingType.currentValue == 2 &&
          userDataPos.right
        ) {
          offsetX -= this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          if (userDataPos.left) {
            offsetX += this.APP.sldExistingLength2.currentValue;
          }
          if (userDataPos.right) {
            offsetX -= this.APP.sldExistingLength2.currentValue;
          }
        }
      }
    }

    let exceedMoveBackLimit = false;

    //Rakecut
    if (
      UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE ||
      UI.rakeCutLeftType == RAKECUT_TYPE.STEP
    ) {
      //Move front post to back
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.isMultiSpan && userDataPos.left) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.isMultiSpan && userDataPos.left) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
          offsetX -= UI.overhangLeft;
          offsetZ -= this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamLeftCutSizeInfo.v + this.beamLeftCutSizeInfo.k_);
          scaleY =
            (this.utils.getHeightByAngle(
              UI.eaveHeight - this.beamInfo.height,
              length,
              UI.patiosPitch,
              -1
            ) +
              this.offsetYBraket) /
            this.postInfo.height;
          if (UI.isUpFasciaUpstandardBracket) {
            scaleY =
              (this.utils.getHeightByAngle(
                UI.eaveHeight - this.beamInfo.height,
                length,
                UI.patiosPitch,
                1
              ) +
                this.offsetYBraket) /
              this.postInfo.height;
          }

          if (
            UI.rakeCutLeftVer + this.beamLeftCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            console.log(
              "EXCEED_LIMIT LEFT",
              UI.rakeCutLeftVer,
              this.beamLeftCutSizeInfo.k_,
              this.MANAGER.patiosLength
            );
            exceedMoveBackLimit = true;
          }
        } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutLeftVer;
          if (moveBack > 0) {
            offsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scaleY =
              (this.utils.getHeightByAngle(
                UI.eaveHeight - this.beamInfo.height,
                length,
                UI.patiosPitch,
                -1
              ) +
                this.offsetYBraket) /
              this.postInfo.height;
            if (UI.isUpFasciaUpstandardBracket) {
              scaleY =
                (this.utils.getHeightByAngle(
                  UI.eaveHeight - this.beamInfo.height,
                  length,
                  UI.patiosPitch,
                  1
                ) +
                  this.offsetYBraket) /
                this.postInfo.height;
            }
          }
        }
      }
    }

    if (
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE ||
      this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP
    ) {
      //Move front post to back
      let moveToBack = false;
      if (UI.multiSpan > 0) {
        if (userDataPos.isMultiSpan && userDataPos.right) {
          moveToBack = true;
        }
      } else {
        if (!userDataPos.isMultiSpan && userDataPos.right) {
          moveToBack = true;
        }
      }

      if (moveToBack) {
        if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
          offsetX += UI.overhangRight;
          offsetZ -= this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_;
          let length =
            UI.span +
            UI.multiSpan -
            (this.beamRightCutSizeInfo.v + this.beamRightCutSizeInfo.k_);
          scaleY =
            (this.utils.getHeightByAngle(
              UI.eaveHeight - this.beamInfo.height,
              length,
              UI.patiosPitch,
              -1
            ) +
              this.offsetYBraket) /
            this.postInfo.height;
          if (UI.isUpFasciaUpstandardBracket) {
            scaleY =
              (this.utils.getHeightByAngle(
                UI.eaveHeight - this.beamInfo.height,
                length,
                UI.patiosPitch,
                1
              ) +
                this.offsetYBraket) /
              this.postInfo.height;
          }
          if (
            UI.rakeCutRightVer + this.beamRightCutSizeInfo.k_ >
            this.MANAGER.patiosLength
          ) {
            console.log(
              "EXCEED_LIMIT RIGHT",
              UI.rakeCutRightVer,
              this.beamLeftCutSizeInfo.k_,
              this.MANAGER.patiosLength
            );
            exceedMoveBackLimit = true;
          }
        } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
          let moveBack = UI.rakeCutRightVer;
          if (moveBack > 0) {
            offsetZ -= moveBack;
            let length = UI.span + UI.multiSpan - moveBack;
            scaleY =
              (this.utils.getHeightByAngle(
                UI.eaveHeight - this.beamInfo.height,
                length,
                UI.patiosPitch,
                -1
              ) +
                this.offsetYBraket) /
              this.postInfo.height;
            if (UI.isUpFasciaUpstandardBracket) {
              scaleY =
                (this.utils.getHeightByAngle(
                  UI.eaveHeight - this.beamInfo.height,
                  length,
                  UI.patiosPitch,
                  1
                ) +
                  this.offsetYBraket) /
                this.postInfo.height;
            }
          }
        }
      }
    }
    //End rakecut

    if (exceedMoveBackLimit) {
      //console.log("EXCEED_LIMIT");
      return;
    }

    mesh.position.set(offsetX, 0, offsetZ + this.offsetBraketZ);
    mesh.scale.setY(scaleY);
    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      position: userDataPos,
      views: views,
    };
    this.scene.add(mesh);
  }

  private addUpstandBraketExt(userDataPos: any) {
    if (
      UI.upstandBraketType == 0 ||
      UI.listBay.length == 0 ||
      UI.existingType == 0 ||
      (userDataPos.isMultiSpan && UI.multiSpan == 0)
    ) {
      return;
    }
    if (
      (this.existingType == 1 && userDataPos.right) ||
      (this.existingType == 2 && userDataPos.left)
    ) {
      return;
    }
    let _flag = false;

    const condition = UI.span + UI.eaveWidth;
    if (!userDataPos.isMultiSpan && !_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    } else if (!_flag) {
      if (
        (this.existingType === 1 || this.existingType === 3) &&
        userDataPos.left &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
      if (
        (this.existingType === 2 || this.existingType === 3) &&
        userDataPos.right &&
        (UI.existingWidth1 >= condition + UI.multiSpan ||
          (UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan &&
            UI.cutOutType == 1))
      ) {
        _flag = true;
        // return;
      }
    }
    if (!_flag) {
      return;
    }
    // if (userDataPos.left && UI.overhangLeft > 0 || userDataPos.right && UI.overhangRight > 0)
    //     return;

    const _bracketOffsetXExt = this.geoBraket_2.height / 5; // + this.geoBraket_1.width / 4;
    let height = 0;
    let offsetX = -UI.existingLength1 / 2;
    let bayMainLength = UI.totalBayLength;
    if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
      if (UI.existingType == 1 || UI.existingType == 2) {
        bayMainLength -= UI.existingLength2;
      }
      if (UI.existingType == 3) {
        bayMainLength -= 2 * UI.existingLength2;
      }
    }
    const _cond1 =
      UI.existingWidth1 < condition &&
      UI.existingWidth1 + UI.existingWidth2 > condition;
    const _cond2 =
      userDataPos.isMultiSpan &&
      UI.existingWidth1 < condition + UI.multiSpan &&
      UI.existingWidth1 + UI.existingWidth2 > condition + UI.multiSpan;

    if (UI.existingType == 1) {
      offsetX = -bayMainLength / 2;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX = -bayMainLength / 2 + UI.existingLength2 / 2;
      }
      if (
        UI.existingWidth1 < condition ||
        (userDataPos.isMultiSpan &&
          UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan)
      ) {
        offsetX -= UI.existingLength2;
      }
      offsetX += _bracketOffsetXExt;
    } else if (UI.existingType == 2) {
      offsetX = bayMainLength / 2 - this.geoBraket_1.width;
      if (!AppComponent.ins.sltCutOut._isDisable && UI.cutOutType == 1) {
        offsetX -= UI.existingLength2 / 2;
      }
      if (
        UI.existingWidth1 < condition ||
        (userDataPos.isMultiSpan &&
          UI.existingWidth1 + UI.existingWidth2 >= condition + UI.multiSpan)
      ) {
        offsetX += UI.existingLength2;
      }
      offsetX -= _bracketOffsetXExt;
    } else if (UI.existingType == 3) {
      offsetX =
        (-bayMainLength / 2) * (userDataPos.left ? 1 : -1) -
        (userDataPos.right ? this.geoBraket_1.width : 0);
      if ((!userDataPos.isMultiSpan && _cond1) || _cond2) {
        offsetX -=
          UI.existingLength2 * (userDataPos.left ? 1 : -1) +
          (userDataPos.right ? this.geoBraket_1.width : 0);
      }
      offsetX += _bracketOffsetXExt * (userDataPos.left ? 1 : -1);
    }
    let offsetZ =
      -UI.existingWidth1 / 2 +
      UI.eaveWidth -
      (this.geoBraket_2.height / 4 + this.geoBraket_1.width / 2);
    if (userDataPos.isMultiSpan) {
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }
    if (userDataPos.isMultiSpan) {
      height = this.offsetYBraket - this.getHeightByRoofPitch(false);
    } else {
      height = this.offsetYBraket - this.getHeightByRoofPitch(true);
    }
    let optionBrakets: UpstandBraketOptions = {
      container: this.scene,
      position: new Vector3(),
      geoBraket_1: this.geoBraket_1,
      geoBraket_2:
        UI.existingType == 2 ? this.geoBraketExtR_2 : this.geoBraketExtL_2,
      height: height,
      material: MaterialManager.Instance().FASCIA_BRACKET.clone(),
    };
    if (UI.existingType == 1) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtL_2 };
    } else if (UI.existingType == 2) {
      optionBrakets = { ...optionBrakets, geoBraket_2: this.geoBraketExtR_2 };
    } else {
      optionBrakets = {
        ...optionBrakets,
        geoBraket_2: userDataPos.right
          ? this.geoBraketExtR_2
          : this.geoBraketExtL_2,
      };
    }
    new UpstandBraket({
      ...optionBrakets,
      position: new Vector3(
        offsetX,
        UI.existingWallHeight,
        offsetZ + this.offsetBraketZ
      ),
      userData: { type: GEOMETRY_TYPE.UPSTAND_BRAKET_EXT },
    });
  }

  public addPostAngelRakecut(offsetX: number, userDataPos: any) {
    if (
      UI.rakeCutLeftType != RAKECUT_TYPE.ANGLE &&
      this.APP.sltRightCutType.currentValue != RAKECUT_TYPE.ANGLE
    ) {
      return;
    }

    let height =
      UI.eaveHeight -
      this.getHeightByRoofPitch(false) -
      this.beamInfo.height +
      this.offsetYBraket;
    let offsetZ =
      UI.span +
      UI.multiSpan +
      UI.eaveWidth -
      this.beamInfo.width / 2 -
      UI.existingWidth1 / 2;

    let scaleY = height / this.postInfo.height;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.left) {
        let postLeft = new Mesh(
          this.postInfo.geometry,
          MaterialManager.Instance().POST
        );
        postLeft.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postLeft.position.set(
          offsetX + this.beamLeftCutSizeInfo.l,
          0,
          offsetZ + this.offsetBraketZ
        );
        postLeft.scale.setY(scaleY);
        let isIntersect = false;
        let boxPost = new Box3().setFromObject(postLeft);
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersect = true;
            }
          });

        if (!isIntersect) {
          this.scene.add(postLeft);
        }
      }
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      if (userDataPos.right) {
        let postRight = new Mesh(
          this.postInfo.geometry,
          MaterialManager.Instance().POST
        );
        postRight.userData = {
          category: GEOMETRY_CATEGORY.PATIOS,
          type: GEOMETRY_TYPE.SUPERIOR_POST,
          position: userDataPos,
          views: views,
        };
        postRight.position.set(
          offsetX - this.beamRightCutSizeInfo.l,
          0,
          offsetZ + this.offsetBraketZ
        );
        postRight.scale.setY(scaleY);
        let isIntersect = false;
        let boxPost = new Box3().setFromObject(postRight);
        this.scene.children
          .filter((el) => el.userData.type == GEOMETRY_TYPE.SUPERIOR_POST)
          .forEach((el) => {
            el.updateWorldMatrix(true, true);
            let boxEl = new Box3().setFromObject(el);
            if (boxPost.intersectsBox(boxEl)) {
              isIntersect = true;
            }
          });

        if (!isIntersect) {
          this.scene.add(postRight);
        }
      }
    }
  }
  private addPostStepRakecut() {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let userDataPos = { rakecut: true };

    let offsetZF =
      UI.span +
      UI.multiSpan +
      UI.eaveWidth -
      UI.existingWidth1 / 2 -
      this.postInfo.width / 2;

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetXL = -UI.totalBayLength / 2;

      let scaleYF =
        (this.utils.getHeightByAngle(
          UI.eaveHeight - this.beamInfo.height,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) +
          this.offsetYBraket) /
        this.postInfo.height;

      if (UI.isUpFasciaUpstandardBracket) {
        scaleYF =
          (this.utils.getHeightByAngle(
            UI.eaveHeight - this.beamInfo.height,
            UI.span + UI.multiSpan,
            UI.patiosPitch,
            1
          ) +
            this.offsetYBraket) /
          this.postInfo.height;
      }

      let scaleYB = scaleYF;

      let offsetZB = offsetZF;

      let moveRight = UI.rakeCutLeftHor - UI.overhangLeft;
      if (moveRight > 0) {
        offsetXL += moveRight;
      }

      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          (this.utils.getHeightByAngle(
            UI.eaveHeight - this.beamInfo.height,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) +
            this.offsetYBraket) /
          this.postInfo.height;
        if (UI.isUpFasciaUpstandardBracket) {
          scaleYB =
            (this.utils.getHeightByAngle(
              UI.eaveHeight - this.beamInfo.height,
              UI.span + UI.multiSpan - moveBack,
              UI.patiosPitch,
              1
            ) +
              this.offsetYBraket) /
            this.postInfo.height;
        }
      }

      let postFront = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postFront.position.set(
        offsetXL + this.postInfo.width / 2,
        0,
        offsetZF + this.offsetBraketZ
      );
      postFront.scale.setY(scaleYF);
      this.scene.add(postFront);

      let postBack = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postBack.position.set(
        offsetXL + this.postInfo.width / 2,
        0,
        offsetZB + this.offsetBraketZ
      );
      postBack.scale.setY(scaleYB);
      this.scene.add(postBack);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetXR = UI.totalBayLength / 2 - this.postInfo.width;

      let scaleYF =
        (this.utils.getHeightByAngle(
          UI.eaveHeight - this.beamInfo.height,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) +
          this.offsetYBraket) /
        this.postInfo.height;
      if (UI.isUpFasciaUpstandardBracket) {
        scaleYF =
          (this.utils.getHeightByAngle(
            UI.eaveHeight - this.beamInfo.height,
            UI.span + UI.multiSpan,
            UI.patiosPitch,
            1
          ) +
            this.offsetYBraket) /
          this.postInfo.height;
      }

      let scaleYB = scaleYF;

      let offsetZB = offsetZF;

      let moveLeft = UI.rakeCutRightHor - UI.overhangRight;
      if (moveLeft > 0) {
        offsetXR -= moveLeft;
      }

      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZB -= moveBack;
        scaleYB =
          (this.utils.getHeightByAngle(
            UI.eaveHeight - this.beamInfo.height,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) +
            this.offsetYBraket) /
          this.postInfo.height;
        if (UI.isUpFasciaUpstandardBracket) {
          scaleYB =
            (this.utils.getHeightByAngle(
              UI.eaveHeight - this.beamInfo.height,
              UI.span + UI.multiSpan - moveBack,
              UI.patiosPitch,
              1
            ) +
              this.offsetYBraket) /
            this.postInfo.height;
        }
      }

      let postFront = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postFront.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postFront.position.set(
        offsetXR + this.postInfo.width / 2,
        0,
        offsetZF + this.offsetBraketZ
      );
      postFront.scale.setY(scaleYF);
      this.scene.add(postFront);

      let postBack = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: userDataPos,
        views: views,
      };
      postBack.position.set(
        offsetXR + this.postInfo.width / 2,
        0,
        offsetZB + this.offsetBraketZ
      );
      postBack.scale.setY(scaleYB);
      this.scene.add(postBack);
    }
  }
  public addBeam(userDataPos: any) {
    if (userDataPos.isMultiSpan && UI.multiSpan === 0) {
      return;
    }

    let offsetY = 0;
    let offsetZ = -UI.existingWidth1 / 2 + UI.eaveWidth;
    let offsetXL = -(this.totalBaySize / 2 + UI.overhangLeft);
    let offsetXR = this.totalBaySize / 2 + UI.overhangRight;

    let beamLength = this.roofWidth;

    const condition = UI.span + UI.eaveWidth;
    let _flag = false;
    if (
      (userDataPos.isMultiSpan &&
        UI.existingWidth1 >= condition + UI.multiSpan) ||
      (!userDataPos.isMultiSpan && UI.existingWidth1 >= condition)
    ) {
      _flag = true;
    }
    // switch (this.existingType) {
    //     case 1:
    //         if (_flag) {
    //             beamLength = this.roofWidth + UI.eaveWidth;
    //             offsetXL -= UI.eaveWidth;//(this.existingTypeLeftX - this.existingTypeRightX) / 2 - (this.existingTypeLeftX / 2);
    //         }
    //         break;
    //     case 2:
    //         if (_flag) {
    //             beamLength = this.roofWidth + UI.eaveWidth;
    //             //offsetX = (this.existingTypeLeftX - this.existingTypeRightX) / 2 + (this.existingTypeRightX / 2);
    //             offsetXR += UI.eaveWidth;
    //         }
    //         break;
    //     case 3:
    //         if (_flag) {
    //             offsetXL -= UI.eaveWidth;
    //             offsetXR += UI.eaveWidth;
    //             beamLength = this.roofWidth + UI.eaveWidth * 2;
    //         }
    //         break;
    // }
    if (userDataPos.isMultiSpan) {
      offsetY =
        UI.eaveHeight - this.getHeightByRoofPitch(false) + this.offsetYBraket;
      offsetZ += UI.span + UI.multiSpan - this.beamInfo.width / 2;
    } else {
      offsetY =
        UI.eaveHeight - this.getHeightByRoofPitch(true) + this.offsetYBraket;
      offsetZ += UI.span - (UI.multiSpan > 0 ? 0 : this.beamInfo.width / 2);
    }
    //offsetY -= this.geometryManager.SUPERIOR_BEAM.S65x160.height / 2;
    let views: Print2DView[] = [];
    if (userDataPos.isMultiSpan) {
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else {
      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    //Cutout
    if (
      !userDataPos.isMultiSpan &&
      this.APP.sltCutOut.currentValue == 1 &&
      this.MANAGER.cutoutCondition
    ) {
      if (UI.span + UI.eaveWidth <= UI.existingWidth1) {
        if (this.APP.sltExistingType.currentValue == 1) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue; // + UI.eaveWidth;
        } else if (this.APP.sltExistingType.currentValue == 2) {
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue; // + UI.eaveWidth;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue * 2; // + UI.eaveWidth;
        }
      } else if (
        UI.span + UI.eaveWidth > UI.existingWidth1 &&
        UI.span + UI.eaveWidth < UI.existingWidth1 + UI.eaveWidth
      ) {
        if (this.APP.sltExistingType.currentValue == 1) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 2) {
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue;
        } else if (this.APP.sltExistingType.currentValue == 3) {
          offsetXL += this.APP.sldExistingLength2.currentValue;
          offsetXR -= this.APP.sldExistingLength2.currentValue;
          beamLength =
            this.roofWidth - this.APP.sldExistingLength2.currentValue * 2;
        }
      }
      // else{
      //     if(this.APP.sltExistingType.currentValue == 1){
      //         offsetXL -= this.APP.sldExistingLength2.currentValue;
      //         scaleX = this.roofWidth / this.beamInfo.length;
      //     }
      //     else if(this.APP.sltExistingType.currentValue == 2){
      //         offsetXR += this.APP.sldExistingLength2.currentValue;
      //         scaleX = this.roofWidth / this.beamInfo.length;
      //     }
      //     else if(this.APP.sltExistingType.currentValue == 3){
      //         offsetXL -= this.APP.sldExistingLength2.currentValue;
      //         offsetXR += this.APP.sldExistingLength2.currentValue;
      //         scaleX = this.roofWidth / this.beamInfo.length;
      //     }
      // }
    }

    //rake cut - apply only the front beam
    if (UI.multiSpan <= 0 || (UI.multiSpan > 0 && userDataPos.isMultiSpan)) {
      if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
        offsetXL += this.beamLeftCutSizeInfo.l;
        beamLength -= this.beamLeftCutSizeInfo.l;
      } else if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
        offsetXL += UI.rakeCutLeftHor;
        beamLength -= UI.rakeCutLeftHor;
      }

      if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
        offsetXR -= this.beamRightCutSizeInfo.l;
        beamLength -= this.beamRightCutSizeInfo.l;
      } else if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
        offsetXR -= UI.rakeCutRightHor;
        beamLength -= UI.rakeCutRightHor;
      }
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.beamInfo,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ + this.offsetBraketZ),
      new Vector3(),
      views,
      1,
      userDataPos,
      0,
      "x",
      this.geoBeamJoint
    );
  }
  private cutStandardBeamToEqualLengthBeam(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    jointGeo: GeometryInfo
  ) {
    const beamStartX = pos.x;
    const beamEndX = pos.x + length;

    const numOfBeam = Math.ceil(
      length / EXISTING_BUILDING_CONFIG.MAXIMUM_LENGTH_PER_BEAM
    );
    const lengthPerBeam = Math.abs(beamEndX - beamStartX) / numOfBeam;
    for (let i = 0; i < numOfBeam; i++) {
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        lengthPerBeam,
        new Vector3(beamStartX + lengthPerBeam * i, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && i == 0,
          hasEndCap:
            UI.beamType == 0 &&
            (i == numOfBeam - 1 || (i == 0 && numOfBeam == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      );
      this.scene.add(beamGroup);
    }
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamGeoTranslationZ: number,
    beamDirection: string,
    jointGeo: GeometryInfo
  ) {
    // Map list cut beam here
    const beamStartX = pos.x;
    const beamEndX = pos.x + length;

    let endOfBayX = -UI.totalBayLength / 2;
    let startCutBeamX = beamStartX;
    let endCutBeamX = beamStartX;

    const beams = this.utils.getListSeperateBeamsByBays(
      endOfBayX,
      beamStartX,
      beamEndX,
      endCutBeamX,
      startCutBeamX
    );

    for (let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1];
      startCutBeamX = beams[i][0];
      let beamGroup = this.utils.createBeamGroup2(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        beamGeoTranslationZ,
        beamDirection,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && i == 0,
          hasEndCap:
            UI.beamType == 0 &&
            (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      );
      this.scene.add(beamGroup);
    }
  }
  private addBeamAngelRakecut() {
    if (
      UI.rakeCutLeftType == RAKECUT_TYPE.NONE &&
      UI.rakeCutRightType == RAKECUT_TYPE.NONE
    ) {
      return;
    }

    let offsetZ = UI.span + UI.multiSpan + UI.eaveWidth - UI.existingWidth1 / 2;

    let offsetY =
      this.utils.getHeightByAngle(
        UI.eaveHeight,
        UI.span + UI.multiSpan,
        UI.patiosPitch,
        -1
      ) + this.offsetYBraket;
    if (UI.isUpFasciaUpstandardBracket) {
      offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          1
        ) + this.offsetYBraket;
    }

    let offsetXStart = 0;

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    let rotX = this.utils.degreesToRadians(UI.patiosPitch);
    if (UI.isUpFasciaUpstandardBracket) {
      rotX = -rotX;
    }

    if (UI.rakeCutLeftType == RAKECUT_TYPE.ANGLE) {
      let offsetX =
        -this.totalBaySize / 2 - UI.overhangLeft + this.beamLeftCutSizeInfo.l;
      let beamLength = this.beamLeftCutSizeInfo.m;
      let scaleZ = beamLength / this.beamRakecutInfoLeft.length;
      let rotYL = Math.atan(UI.rakeCutLeftHor / UI.rakeCutLeftVer);

      //beam lenght can not go behind existing wall
      if (
        UI.rakeCutLeftVer + this.beamLeftCutSizeInfo.k_ >
        this.MANAGER.patiosLength
      ) {
        beamLength -= this.beamLeftCutSizeInfo.b;
        scaleZ = beamLength / this.beamRakecutInfoLeft.length;
      }

      let offsetXEnd = beamLength;

      const mesh = new Mesh(
        this.beamRakecutInfoLeft.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      beamGroup.position.set(offsetX, offsetY, offsetZ + this.offsetBraketZ);
      beamGroup.rotation.set(0, rotYL, 0);
      beamGroup.updateMatrixWorld();
      let matrix = beamGroup.matrixWorld;

      if (beamLength > 8000) {
        this.addPostForBeamAngelRakecut(BUILDING_SIDE.LEFT, matrix);
      }

      beamGroup.rotation.set(rotX, rotYL, 0);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }

      this.scene.add(beamGroup);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.ANGLE) {
      //let beamSizeInfo = this.getBeamRakeCutInfo(BUILDING_SIDE.RIGHT);
      let offsetX =
        this.totalBaySize / 2 + UI.overhangRight - this.beamRightCutSizeInfo.l;
      let beamLength = this.beamRightCutSizeInfo.m;
      let scaleZ = beamLength / this.beamRakecutInfoLeft.length;
      let rotYR = Math.atan(UI.rakeCutRightHor / UI.rakeCutRightVer);

      //beam lenght can not go behind existing wall
      if (
        UI.rakeCutRightVer + this.beamRightCutSizeInfo.k_ >
        this.MANAGER.patiosLength
      ) {
        beamLength -= this.beamRightCutSizeInfo.b;
        scaleZ = beamLength / this.beamRakecutInfoRight.length;
      }

      let offsetXEnd = beamLength;

      const mesh = new Mesh(
        this.beamRakecutInfoRight.geometry,
        MaterialManager.Instance().BEAM
      );
      mesh.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
      };
      mesh.scale.setZ(scaleZ);

      let capL = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capL.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        -(offsetXEnd + this.geo_beamEndCap.length / 2 - 2)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let capR = new Mesh(
        this.geo_beamEndCap.geometry,
        MaterialManager.Instance().BEAM
      );
      capR.position.set(
        -this.geo_beamEndCap.width / 2,
        0,
        offsetXStart - this.geo_beamEndCap.length / 2 + 2
      );
      capR.rotateY(Math.PI);
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
        code: getBeamEndCapCode(
          this.geo_beamEndCap.name,
          HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
        ),
      };

      let beamGroup = new Group();
      beamGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_BEAM,
        views: views,
        angle: true,
      };
      beamGroup.position.set(offsetX, offsetY, offsetZ + this.offsetBraketZ);
      beamGroup.rotation.set(0, -rotYR, 0);
      beamGroup.updateMatrixWorld();
      let matrix = beamGroup.matrixWorld;
      if (beamLength > 8000) {
        this.addPostForBeamAngelRakecut(BUILDING_SIDE.RIGHT, matrix);
      }

      beamGroup.rotation.set(rotX, -rotYR, 0);
      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup.add(mesh, capL, capR);
      } else {
        beamGroup.add(mesh);
      }
      this.scene.add(beamGroup);
    }
  }
  private addBeamStepRakecut() {
    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (UI.rakeCutLeftType == RAKECUT_TYPE.STEP) {
      let offsetXL = -UI.totalBayLength / 2 - UI.overhangLeft;
      let offsetZ =
        UI.span + UI.multiSpan + UI.eaveWidth - UI.existingWidth1 / 2;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) + this.offsetYBraket;
      if (UI.isUpFasciaUpstandardBracket) {
        offsetY =
          this.utils.getHeightByAngle(
            UI.eaveHeight,
            UI.span + UI.multiSpan,
            UI.patiosPitch,
            1
          ) + this.offsetYBraket;
      }

      let moveBack = UI.rakeCutLeftVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY =
          this.utils.getHeightByAngle(
            UI.eaveHeight,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) + this.offsetYBraket;
        if (UI.isUpFasciaUpstandardBracket) {
          offsetY =
            this.utils.getHeightByAngle(
              UI.eaveHeight,
              UI.span + UI.multiSpan - moveBack,
              UI.patiosPitch,
              1
            ) + this.offsetYBraket;
        }
      }

      let beamLength = UI.rakeCutLeftHor + this.postInfo.width;
      if (UI.rakeCutLeftHor < UI.overhangLeft) {
        beamLength = UI.overhangLeft + this.postInfo.width;
      }

      let beamGroup;

      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutLeft,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXL, offsetY, offsetZ + this.offsetBraketZ),
          new Vector3(),
          views,
          1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      } else {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutLeft,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXL, offsetY, offsetZ + this.offsetBraketZ),
          new Vector3(),
          views,
          1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      }

      this.scene.add(beamGroup);
    }
    if (this.APP.sltRightCutType.currentValue == RAKECUT_TYPE.STEP) {
      let offsetXR = UI.totalBayLength / 2 + UI.overhangRight;
      let offsetZ =
        UI.span + UI.multiSpan + UI.eaveWidth - UI.existingWidth1 / 2;
      let offsetY =
        this.utils.getHeightByAngle(
          UI.eaveHeight,
          UI.span + UI.multiSpan,
          UI.patiosPitch,
          -1
        ) + this.offsetYBraket;
      if (UI.isUpFasciaUpstandardBracket) {
        offsetY =
          this.utils.getHeightByAngle(
            UI.eaveHeight,
            UI.span + UI.multiSpan,
            UI.patiosPitch,
            1
          ) + this.offsetYBraket;
      }

      let moveBack = UI.rakeCutRightVer;
      if (moveBack > 0) {
        offsetZ -= moveBack;
        offsetY =
          this.utils.getHeightByAngle(
            UI.eaveHeight,
            UI.span + UI.multiSpan - moveBack,
            UI.patiosPitch,
            -1
          ) + this.offsetYBraket;
        if (UI.isUpFasciaUpstandardBracket) {
          offsetY =
            this.utils.getHeightByAngle(
              UI.eaveHeight,
              UI.span + UI.multiSpan - moveBack,
              UI.patiosPitch,
              1
            ) + this.offsetYBraket;
        }
      }
      let beamLength = UI.rakeCutRightHor + this.postInfo.width;
      if (UI.rakeCutRightHor < UI.overhangRight) {
        beamLength = UI.overhangRight + this.postInfo.width;
      }

      let beamGroup;

      if (this.APP.sltBeamType.currentValue == 0) {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutRight,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXR, offsetY, offsetZ + this.offsetBraketZ),
          new Vector3(),
          views,
          -1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      } else {
        beamGroup = this.utils.createBeamGroup2(
          this.geo_beamStepRakecutRight,
          this.geo_beamEndCap,
          beamLength,
          new Vector3(offsetXR, offsetY, offsetZ + this.offsetBraketZ),
          new Vector3(),
          views,
          -1,
          null,
          -this.geo_beamEndCap.width / 2,
          "x",
          false,
          {
            hasStartCap: UI.beamType == 0,
            hasEndCap: UI.beamType == 0,
            hasStartJoint: false,
            hasEndJoint: false,
            jointGeo: null,
          }
        );
      }

      this.scene.add(beamGroup);
    }
  }
  private getHeightByRoofPitch(isSpan: boolean): number {
    if (isSpan) {
      if (UI.isUpFasciaUpstandardBracket) {
        return -this.utils.tan(UI.patiosPitch) * UI.span;
      } else {
        return this.utils.tan(UI.patiosPitch) * UI.span;
      }
    } else {
      if (UI.isUpFasciaUpstandardBracket) {
        return -this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan);
      } else {
        return this.utils.tan(UI.patiosPitch) * (UI.span + UI.multiSpan);
      }
    }
  }
  private addPostForBeamAngelRakecut(
    cutSide: BUILDING_SIDE,
    beamMatrix: Matrix4
  ) {
    //let inf = this.getBeamRakeCutInfo(cutSide);

    let views: Print2DView[] = [
      { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    if (cutSide == BUILDING_SIDE.LEFT) {
      let p1 = new Vector3(
        -UI.totalBayLength / 2 - UI.overhangLeft,
        2500,
        -UI.existingWidth1 / 2 + UI.span + UI.multiSpan
      );
      let p2 = new Vector3().addVectors(
        p1,
        new Vector3(0, 0, -1).multiplyScalar(this.beamLeftCutSizeInfo.j)
      );
      let p3 = new Vector3().addVectors(
        p1,
        new Vector3(1, 0, 0).multiplyScalar(this.beamLeftCutSizeInfo.l)
      );
      let angle = Math.atan(UI.rakeCutLeftHor / UI.rakeCutLeftVer);

      let ver = (Math.cos(angle) * this.beamLeftCutSizeInfo.m) / 2;
      let pMid = new Vector3().addVectors(
        p2,
        new Vector3(0, 0, 1).multiplyScalar(ver)
      );
      let pOri = new Vector3(
        -UI.totalBayLength / 2 + UI.overhangLeft,
        2500,
        -UI.existingWidth1 / 2
      );

      let dir = new Vector3().subVectors(p2, p3).normalize();
      let pMid2 = new Vector3().addVectors(
        p3,
        dir.multiplyScalar(this.beamLeftCutSizeInfo.m / 2)
      );

      let length = new Vector3().subVectors(pMid, pOri).length();
      let height = this.utils.getHeightByAngle(
        UI.eaveHeight - this.beamInfo.height,
        length,
        UI.patiosPitch,
        -1
      );

      let scaleY = height / this.postInfo.height;

      let postLeft = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postLeft.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { rakecut: true },
        views: views,
      };
      postLeft.position.set(pMid2.x, 0, pMid2.z);
      //postLeft.rotation.set(0,this.utils.degreesToRadians(UI.patiosPitch),0);
      //postLeft.applyMatrix4(beamMatrix);
      //postLeft.position.setY(-height/2);
      postLeft.scale.setY(scaleY);
      this.scene.add(postLeft);
    }
    if (cutSide == BUILDING_SIDE.RIGHT) {
      let p1 = new Vector3(
        UI.totalBayLength / 2 + UI.overhangRight,
        2500,
        -UI.existingWidth1 / 2 + UI.span + UI.multiSpan
      );
      let p2 = new Vector3().addVectors(
        p1,
        new Vector3(0, 0, -1).multiplyScalar(this.beamRightCutSizeInfo.j)
      );
      let p3 = new Vector3().addVectors(
        p1,
        new Vector3(-1, 0, 0).multiplyScalar(this.beamRightCutSizeInfo.l)
      );
      let angle = Math.atan(UI.rakeCutRightHor / UI.rakeCutRightVer);

      let ver = (Math.cos(angle) * this.beamRightCutSizeInfo.m) / 2;
      let pMid = new Vector3().addVectors(
        p2,
        new Vector3(0, 0, 1).multiplyScalar(ver)
      );
      let pOri = new Vector3(
        UI.totalBayLength / 2 + UI.overhangRight,
        2500,
        -UI.existingWidth1 / 2
      );

      let dir = new Vector3().subVectors(p2, p3).normalize();
      let pMid2 = new Vector3().addVectors(
        p3,
        dir.multiplyScalar(this.beamRightCutSizeInfo.m / 2)
      );

      let length = new Vector3().subVectors(pMid, pOri).length();
      let height = this.utils.getHeightByAngle(
        UI.eaveHeight - this.beamInfo.height,
        length,
        UI.patiosPitch,
        -1
      );

      let scaleY = height / this.postInfo.height;

      let postRight = new Mesh(
        this.postInfo.geometry,
        MaterialManager.Instance().POST
      );
      postRight.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.SUPERIOR_POST,
        position: { rakecut: true },
        views: views,
      };
      postRight.position.set(pMid2.x, 0, pMid2.z);
      postRight.scale.setY(scaleY);
      this.scene.add(postRight);
    }
  }
}
