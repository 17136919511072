import {
  HomeComponent as AppComponent,
  HomeComponent,
} from "../../containers/home/home.component";
import {
  Scene,
  Material,
  Mesh,
  BufferGeometry,
  Box3,
  Group,
  LineSegments,
  LineBasicMaterial,
  Plane,
  Vector3,
  BoxHelper,
  Geometry,
  BoxBufferGeometry,
  Object3D,
  Matrix4,
} from "three";
import { Util, getBeamEndCapCode, getRafterBeamEndCapCode } from "../utils";
import { GeometryManager } from "../geometry.manager";
import { MaterialManager } from "../material.manager";
import {
  CONFIG as env,
  GEOMETRY_TYPE,
  GEOMETRY_CATEGORY,
} from "../../app.config";
import {
  GeometryInfo,
  Printing2DGeometry,
  Printing2DLine,
  Printing2DGeometryType,
  Print2DView,
  ViewType,
  LineType,
} from "../models";
import { PatiosGableFreeStandingManager } from ".";
import { UI } from "../ui";
import { EXISTING_BUILDING_CONFIG } from "src/app/app.constants";
import _ from "lodash";

export class ColumnAndPurlinManager {
  private scene: Group;
  private APP: AppComponent;
  private material: Material;
  //private beamMaterial: Material;
  private utils: Util;

  private geometryManager: GeometryManager;

  private geo_superiorPost: GeometryInfo;
  private geo_centerSuperiorPost: GeometryInfo;
  public geo_superiorBeam: GeometryInfo;
  private geo_beamEndCap: GeometryInfo;
  private geo_rafterBeamEndCap: GeometryInfo;
  private geo_bracket: GeometryInfo;
  private geo_groundBase: GeometryInfo;

  private eventHandleId: any;
  private totalBaySize: number;
  private frontPostHeight: number;

  private geo_RafterBeam: GeometryInfo;
  private geo_downPipe: GeometryInfo;
  private geo_downPipeL: GeometryInfo;
  private downpipeGroup: Group;
  private downpipeGroupBack: Group;
  private objectSizeChangedHandle: any;
  private controlsToRegisterEvent: Array<any>;
  private controlsToRegisterEvent2: Array<any>;
  private geoBeamJoint: GeometryInfo;
  private geoHouseBeamJoint: GeometryInfo;

  private FIT_SHEETING: number = 0;
  private FIT_SHEETING_BASE: number = -60;

  constructor(app: AppComponent, manager: PatiosGableFreeStandingManager) {
    this.APP = app;
    this.utils = new Util();
    this.geometryManager = GeometryManager.Instance();

    this.scene = manager.patiosGroup;
    this.material = MaterialManager.Instance().DEFAULT.clone();
    //this.beamMaterial = MaterialManager.Instance().DEFAULT.clone();

    this.registerEvent();
  }

  public optimize(): Promise<void> {
    return new Promise((resolve, reject) => {
      let bracketHeight =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height / 2;
      let bracketWidth = this.geometryManager.FLY_OVER_BRACKET.S65x3.width;

      this.geo_bracket = new GeometryInfo();
      this.geo_bracket.geometry =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.geometry
          .clone()
          .rotateX(Math.PI / 2)
          .translate(bracketWidth / 2, bracketHeight, bracketWidth / 2);
      this.geo_bracket.width =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.width;
      this.geo_bracket.length =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.length;
      this.geo_bracket.height =
        this.geometryManager.FLY_OVER_BRACKET.S65x3.height;

      this.geo_superiorBeam = this.geometryManager.getBeam();
      this.geo_superiorBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_superiorBeam.length / 2,
          this.geo_superiorBeam.height / 2,
          this.geo_superiorBeam.width / 2
        );

      this.geoBeamJoint = this.geometryManager.getBeamJoint();
      this.geoHouseBeamJoint = this.geometryManager.getHouseBeamJoint();

      this.geo_RafterBeam = this.geometryManager.getRafterBeam();
      this.geo_RafterBeam.geometry
        .rotateY(Math.PI / 2)
        .translate(
          this.geo_RafterBeam.length / 2,
          -this.geo_RafterBeam.height / 2,
          this.geo_RafterBeam.width / 2
        );

      this.geo_beamEndCap = this.geometryManager.getBeamEndCap();
      this.geo_beamEndCap.geometry.translate(
        0,
        this.geo_beamEndCap.height / 2,
        0
      );

      this.geo_rafterBeamEndCap = this.geometryManager.getRafterBeamEndCap();
      this.geo_rafterBeamEndCap.geometry.translate(
        0,
        -this.geo_rafterBeamEndCap.height / 2,
        this.geo_rafterBeamEndCap.length / 2
      );

      this.geo_superiorPost = this.geometryManager.getPost();
      this.geo_superiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_superiorPost.width / 2,
          this.geo_superiorPost.height / 2,
          this.geo_superiorPost.width / 2
        );

      this.geo_centerSuperiorPost = this.geometryManager.getCenterPost();
      this.geo_centerSuperiorPost.geometry
        .rotateX(Math.PI / 2)
        .translate(
          this.geo_centerSuperiorPost.width / 2,
          this.geo_centerSuperiorPost.height / 2,
          this.geo_centerSuperiorPost.width / 2
        );

      this.geo_groundBase = new GeometryInfo();
      this.geo_groundBase.width = 1000;
      this.geo_groundBase.height = 1;
      this.geo_groundBase.length = 1000;
      this.geo_groundBase.geometry = new BoxBufferGeometry(
        this.geo_groundBase.width,
        this.geo_groundBase.height,
        this.geo_groundBase.length
      );
      this.geo_groundBase.geometry.translate(
        0,
        0,
        this.geo_groundBase.length / 2
      );

      this.geo_downPipe = this.geometryManager.getDownPipe();
      this.geo_downPipeL = this.geometryManager.getDownPipeL();

      this.scene.remove(
        ...this.scene.children.filter(
          (c) => c.userData.type == GEOMETRY_TYPE.DOWNPIPE
        )
      );
      this.downpipeGroup = new Group();
      this.downpipeGroup.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroup);

      this.downpipeGroupBack = new Group();
      this.downpipeGroupBack.userData = {
        category: GEOMETRY_CATEGORY.PATIOS,
        type: GEOMETRY_TYPE.DOWNPIPE,
      };
      this.scene.add(this.downpipeGroupBack);

      resolve();
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == GEOMETRY_TYPE.MESH_OUTLINE
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (x) => x.userData.type == "COLUMN_OUTLINE"
        )
      );
      this.scene.remove(
        ...this.scene.children.filter(
          (o) => o.userData.type == GEOMETRY_TYPE.GROUND_BASE
        )
      );
      this.downpipeGroup.children = [];
      this.downpipeGroupBack.children = [];

      // this.outlineGroup = new Group();
      // this.outlineGroup.userData = { category: GEOMETRY_CATEGORY.PATIOS, type: GEOMETRY_TYPE.MESH_OUTLINE };
      // this.scene.add(this.outlineGroup);

      this.totalBaySize = 0;
      for (let b of this.APP.dialogEditBay.listBay) {
        this.totalBaySize += b.value;
      }

      this.addSuperiorBeam({ front: true });
      this.addSuperiorBeam({ back: true });
      if (this.APP.sldMultiSpan.currentValue > 0) {
        this.addSuperiorBeam({ multiSpan: true });
      }

      this.addCenterSuperiorBeam();

      this.addGround();

      let offsetX = -this.totalBaySize / 2;
      let first = true;
      let last = false;
      for (let i = 0; i <= this.APP.dialogEditBay.listBay.length; i++) {
        if (i == this.APP.dialogEditBay.listBay.length) {
          last = true;
        }
        this.addPost(offsetX, { back: true, first, last });
        this.addPost(offsetX, { front: true, first, last });

        this.addCenterPost(offsetX, { center: true, first, last });

        let fitBeam = 0;

        if (i !== 0) {
          if (i == this.APP.dialogEditBay.listBay.length) {
            fitBeam = this.geo_superiorBeam.width;
          } else {
            fitBeam = this.geo_superiorBeam.width / 2;
          }
        }

        this.addHorSuperiorBeam(offsetX - fitBeam, {
          front: true,
          first,
          last,
        });
        this.addRafterSuperiorBeam(offsetX - fitBeam, {
          front: true,
          first,
          last,
        });
        this.addRafterSuperiorBeam(offsetX - fitBeam, {
          back: true,
          first,
          last,
        });

        if (this.APP.sldMultiSpan.currentValue > 0) {
          this.addPost(offsetX, { multiSpan: true, first, last });
        }
        if (i < this.APP.dialogEditBay.listBay.length) {
          let b = this.APP.dialogEditBay.listBay[i];
          offsetX += b.value;
        }
        first = false;
      }

      this.addDownPipeFront();
      this.addDownPipeBack();
      this.showBeamOutline();
      this.updateUI();
      resolve();
    });
  }
  public showBeamOutline() {
    this.APP.scene.remove(
      ...this.APP.scene.children.filter(
        (x) => x.userData.type == GEOMETRY_TYPE.BEAM_OUTLINE
      )
    );

    if (!UI.beamLayoutShow) return;

    const objs = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    const meshes = [];
    objs.forEach((el) => {
      if (el.type == "Group") {
        meshes.push(
          ...el.children.filter(
            (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
          )
        );
      }
    });

    for (let o of meshes) {
      let outlineGeo = this.utils.getOutlineGeometryFromMeshNoScale(
        o as Mesh,
        10
      );
      o.updateWorldMatrix(true, true);
      outlineGeo.applyMatrix4(o.matrixWorld);

      var line = new LineSegments(
        outlineGeo,
        MaterialManager.Instance().BEAM_OUTLINE
      );
      line.userData = { type: GEOMETRY_TYPE.BEAM_OUTLINE };
      this.APP.scene.add(line);
    }
  }
  private updateUI() {
    this.APP.sldMinHeight.setValue(_.round(this.frontPostHeight, 0));
  }
  private addDownPipeFront() {
    let offsetZ =
      this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue + 100;
    let offsetX = -this.totalBaySize / 2 + 50;

    this.downpipeGroup.position.set(offsetX, 0, offsetZ);
    let offsetY =
      this.APP.sldBuildingHeight.currentValue -
      this.APP.sldFrontOverhang.currentValue *
        this.utils.tan(this.APP.sltRoofPitch.currentValue);

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

    offsetY -= 160;

    let meshLBottom = new Mesh(
      this.geo_downPipeL.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshLBottom.userData = userData;
    meshLBottom.position.set(0, offsetY, 20);
    meshLBottom.rotateY(Math.PI / 2);

    let meshLTop = new Mesh(
      this.geo_downPipeL.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshLTop.userData = userData;
    meshLTop.rotation.set(Math.PI, Math.PI / 2, 0);
    meshLTop.position.set(
      0,
      offsetY + 230,
      this.APP.sldFrontOverhang.currentValue + this.FIT_SHEETING - 100
    );

    let meshPipeConnect = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipeConnect.userData = userData;
    meshPipeConnect.rotation.set(Math.PI / 2, 0, 0);
    meshPipeConnect.position.set(0, offsetY + 115, 50);
    meshPipeConnect.scale.set(
      1,
      (this.APP.sldFrontOverhang.currentValue + this.FIT_SHEETING - 140) /
        this.geo_downPipe.height,
      1
    );

    this.downpipeGroup.add(meshLBottom, meshLTop, meshPipeConnect);

    let scaleY = offsetY / this.geo_downPipe.height;

    let meshPipe = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = userData;
    meshPipe.scale.setY(scaleY);

    this.downpipeGroup.add(meshPipe);
  }
  private addDownPipeBack() {
    let offsetZ = -100;
    let offsetX = -this.totalBaySize / 2 + 50;

    this.downpipeGroupBack.position.set(offsetX, 0, offsetZ);
    let offsetY =
      this.APP.sldBuildingHeight.currentValue -
      this.APP.sldBackOverhang.currentValue *
        this.utils.tan(this.APP.sltRoofPitch.currentValue);

    if (this.APP.sltGutterType.currentValue == 0) {
      offsetY -= 80;
    } else {
      offsetY -= 60;
    }

    let userData = { type: GEOMETRY_TYPE.DOWNPIPE };

    offsetY -= 160;

    let meshLBottom = new Mesh(
      this.geo_downPipeL.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshLBottom.userData = userData;
    meshLBottom.position.set(0, offsetY, 20);
    meshLBottom.rotateY(Math.PI / 2);

    let meshLTop = new Mesh(
      this.geo_downPipeL.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshLTop.userData = userData;
    meshLTop.rotation.set(Math.PI, Math.PI / 2, 0);
    meshLTop.position.set(
      0,
      offsetY + 230,
      this.APP.sldBackOverhang.currentValue + this.FIT_SHEETING - 100
    );

    let meshPipeConnect = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipeConnect.userData = userData;
    meshPipeConnect.rotation.set(Math.PI / 2, 0, 0);
    meshPipeConnect.position.set(0, offsetY + 115, 50);
    meshPipeConnect.scale.set(
      1,
      (this.APP.sldBackOverhang.currentValue + this.FIT_SHEETING - 140) /
        this.geo_downPipe.height,
      1
    );

    this.downpipeGroupBack.add(meshLBottom, meshLTop, meshPipeConnect);

    let scaleY = offsetY / this.geo_downPipe.height;

    let meshPipe = new Mesh(
      this.geo_downPipe.geometry,
      MaterialManager.Instance().DOWNPIPE
    );
    meshPipe.userData = userData;
    meshPipe.scale.setY(scaleY);

    this.downpipeGroupBack.rotation.set(0, Math.PI, 0);
    this.downpipeGroupBack.add(meshPipe);
  }
  private addGround() {
    let offsetZ = -this.APP.sldBackOverhang.currentValue;
    let width =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;
    let length =
      this.APP.sldSpan.currentValue +
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldFrontOverhang.currentValue +
      this.APP.sldBackOverhang.currentValue;
    let offsetX =
      (this.APP.sldRightOverhang.currentValue -
        this.APP.sldLeftOverhang.currentValue) /
      2;

    let base = new Mesh(
      this.geo_groundBase.geometry,
      MaterialManager.Instance().BASE
    );
    base.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.GROUND_BASE,
    };
    base.position.set(offsetX, 0, offsetZ);
    base.scale.set(
      width / this.geo_groundBase.width,
      1,
      length / this.geo_groundBase.length
    );

    this.scene.add(base);
  }
  private addCenterPost(offsetX, userDataPos) {
    let meshPost = new Mesh(
      this.geo_centerSuperiorPost.geometry,
      MaterialManager.Instance().BEAM
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_CENTER_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let postOffsetZ = 0;

    let scalePostY = 1;

    let views: Print2DView[];

    postOffsetZ =
      (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2 -
      this.geo_centerSuperiorPost.width / 2;

    this.frontPostHeight =
      this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;

    scalePostY =
      (((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) /
        2 -
        this.geo_superiorBeam.width / 2) *
        this.utils.tan(this.APP.sltRoofPitch.currentValue) -
        this.geo_superiorBeam.height) /
      this.geo_centerSuperiorPost.height;
    views = [
      // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];
    let postOffsetX = offsetX - this.geo_centerSuperiorPost.width / 2;

    if (userDataPos.first) {
      postOffsetX += this.geo_centerSuperiorPost.width / 2;
    } else if (userDataPos.last) {
      postOffsetX -= this.geo_centerSuperiorPost.width / 2;
    } else {
      // postOffsetX -= this.geo_superiorPost.width / 2
    }

    meshPost.userData.views = views;
    meshPost.position.set(
      postOffsetX,
      this.APP.sldBuildingHeight.currentValue,
      postOffsetZ
    );
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }
  private addPost(offsetX: number, userDataPos: any): void {
    let meshPost = new Mesh(
      this.geo_superiorPost.geometry,
      MaterialManager.Instance().POST
    );
    meshPost.userData = {
      ...userDataPos,
      type: GEOMETRY_TYPE.SUPERIOR_POST,
      category: GEOMETRY_CATEGORY.PATIOS,
    };

    let postOffsetZ = 0;

    let scalePostY = 1;

    let views: Print2DView[];

    if (userDataPos.front) {
      postOffsetZ = this.APP.sldSpan.currentValue;
      this.frontPostHeight =
        this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;

      scalePostY =
        this.APP.sldBuildingHeight.currentValue / this.geo_superiorPost.height;

      if (this.APP.sldMultiSpan.currentValue > 0) {
        postOffsetZ -= this.geo_superiorPost.width / 2;
      } else {
        postOffsetZ -= this.geo_superiorPost.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    if (userDataPos.back) {
      postOffsetZ = 0;
      scalePostY =
        this.APP.sldBuildingHeight.currentValue / this.geo_superiorPost.height;
      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }
    if (userDataPos.multiSpan) {
      postOffsetZ =
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.geo_superiorPost.width;
      // scalePostY = this.utils.getHeightByAngle(this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height,
      //     this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue,
      //     this.APP.sltRoofPitch.currentValue, -1)/this.geo_superiorPost.height;

      scalePostY =
        this.APP.sldBuildingHeight.currentValue / this.geo_superiorPost.height;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    meshPost.userData.views = views;

    let postOffsetX = offsetX;
    if (userDataPos.first) {
    } else if (userDataPos.last) {
      postOffsetX -= this.geo_superiorPost.width;
    } else {
      postOffsetX -= this.geo_superiorPost.width / 2;
    }

    meshPost.position.set(postOffsetX, 0, postOffsetZ);
    meshPost.scale.setY(scalePostY);

    this.scene.add(meshPost);
  }

  public addSuperiorBeam(userDataPos: any) {
    let offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;

    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY =
        this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;

      //this.APP.sldBuildingHeight.currentValue + this.getBracketHeight(this.APP.sldSpan.currentValue);
      offsetZ = this.APP.sldSpan.currentValue;
      if (this.APP.sldMultiSpan.currentValue > 0) {
        offsetZ -= this.geo_superiorBeam.width / 2;
      } else {
        offsetZ -= this.geo_superiorBeam.width;
      }

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      offsetY =
        this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;
      offsetZ = 0;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.multiSpan) {
      offsetY =
        this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;
      offsetZ =
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.geo_superiorBeam.width;

      views = [
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.geo_superiorBeam,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      userDataPos,
      "EB",
      this.geoBeamJoint
    );
  }

  public addCenterSuperiorBeam() {
    let offsetXL = -(
      this.totalBaySize / 2 +
      this.APP.sldLeftOverhang.currentValue
    );
    let offsetZ = 0;
    let offsetY = 0;

    let beamLength =
      this.totalBaySize +
      this.APP.sldLeftOverhang.currentValue +
      this.APP.sldRightOverhang.currentValue;

    offsetY =
      this.APP.sldBuildingHeight.currentValue +
      ((this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) /
        2 -
        this.geo_superiorBeam.width / 2) *
        this.utils.tan(this.APP.sltRoofPitch.currentValue) -
      this.geo_superiorBeam.height;

    offsetZ =
      (this.APP.sldSpan.currentValue + this.APP.sldMultiSpan.currentValue) / 2 -
      this.geo_superiorBeam.width / 2;

    let views: Print2DView[] = [
      { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
      { viewType: ViewType.PLAN, lineType: LineType.DASHED },
    ];

    this.cutStandardBeamByCutBeamWithinBayControl(
      this.geo_superiorBeam,
      this.geo_beamEndCap,
      beamLength,
      new Vector3(offsetXL, offsetY, offsetZ),
      new Vector3(),
      views,
      1,
      { center: true },
      "RB",
      this.geoBeamJoint
    );
  }
  private cutStandardBeamByCutBeamWithinBayControl(
    beamGeo: GeometryInfo,
    beamCapGeo: GeometryInfo,
    length: number,
    pos: Vector3,
    rot: Vector3,
    views: any,
    directionOffset: number,
    userDataPos: any,
    beamName = "",
    jointGeo: GeometryInfo
  ) {
    // Map list cut beam here
    const beamStartX = pos.x;
    const beamEndX = pos.x + length;

    let endOfBayX = -UI.totalBayLength / 2;
    let startCutBeamX = beamStartX;
    let endCutBeamX = beamStartX;

    const beams = this.utils.getListSeperateBeamsByBays(
      endOfBayX,
      beamStartX,
      beamEndX,
      endCutBeamX,
      startCutBeamX
    );

    for (let i = 0; i < beams.length; i++) {
      endCutBeamX = beams[i][1];
      startCutBeamX = beams[i][0];
      let beamGroup = this.utils.createBeamGroup(
        beamGeo,
        beamCapGeo,
        endCutBeamX - startCutBeamX,
        new Vector3(startCutBeamX, pos.y, pos.z),
        rot,
        views,
        directionOffset,
        userDataPos,
        UI.beamLayoutShow,
        {
          hasStartCap: UI.beamType == 0 && i == 0,
          hasEndCap:
            UI.beamType == 0 &&
            (i == beams.length - 1 || (i == 0 && beams.length == 1)),
          hasStartJoint: i !== 0,
          hasEndJoint: false,
          jointGeo: jointGeo,
        }
      );
      beamGroup.userData = {
        ...beamGroup.userData,
        name: beamName,
      };
      this.scene.add(beamGroup);
    }
  }

  public addRafterSuperiorBeam(offsetX, userDataPos: any) {
    if (
      this.APP.sltWindClass.currentValue == 0 ||
      this.APP.sltWindClass.currentValue == 1
    ) {
      if (this.totalBaySize <= 6000 && this.APP.sldSpan.currentValue <= 7000) {
        return;
      }
    }
    if (
      this.APP.sltWindClass.currentValue == 3 ||
      this.APP.sltWindClass.currentValue == 4 ||
      this.APP.sltWindClass.currentValue == 5
    ) {
      return;
    }
    let mesh;

    let offsetXL = 0;
    let offsetXR =
      ((this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) /
        2 -
        this.geo_superiorBeam.width / 2) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue);
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      ((this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) /
        2 -
        this.geo_superiorBeam.width / 2) /
      this.utils.cos(this.APP.sltRoofPitch.currentValue) /
      this.geo_RafterBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(0, this.APP.sldBuildingHeight.currentValue, 0);
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, 1).normalize();
      let pVer = new Vector3(0, 0, this.geo_superiorBeam.width / 2);
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY = this.APP.sldBuildingHeight.currentValue;
      offsetZ =
        this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    } else if (userDataPos.back) {
      let material = MaterialManager.Instance().BEAM.clone();

      let nBottom = new Vector3(0, 1, 0).normalize();
      let pBottom = new Vector3(0, this.APP.sldBuildingHeight.currentValue, 0);
      let planeBottom = new Plane().setFromNormalAndCoplanarPoint(
        nBottom,
        pBottom
      );

      let nVer = new Vector3(0, 0, -1).normalize();
      let pVer = new Vector3(0, 0, -this.geo_superiorBeam.width / 2);
      let planeVer = new Plane().setFromNormalAndCoplanarPoint(nVer, pVer);

      material.clippingPlanes = [planeBottom, planeVer];

      mesh = new Mesh(this.geo_RafterBeam.geometry, material);

      offsetY =
        this.APP.sldBuildingHeight.currentValue +
        ((this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) /
          2 -
          this.geo_superiorBeam.width / 2) *
          this.utils.tan(this.APP.sltRoofPitch.currentValue);
      offsetZ =
        (this.APP.sldMultiSpan.currentValue + this.APP.sldSpan.currentValue) /
          2 -
        this.geo_superiorBeam.width / 2;

      views = [
        // { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let checkExec = /\w{1}\d{1,3}x(\d{1,3})/.exec(this.geo_RafterBeam.name);
    let beamSize = checkExec && checkExec.length == 2 ? checkExec[1] : "";
    let lowerBraketName = getRafterBeamEndCapCode(
      EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE,
      false
    );
    let higherBraketName = getRafterBeamEndCapCode(
      EXISTING_BUILDING_CONFIG.RAFTER_BEAM_SIZE,
      true
    );

    let capL;
    let capR;
    if (userDataPos.front) {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        this.APP.sldBuildingHeight.currentValue +
        (this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue)) /
          2;
      let heightPlane =
        heightReal * this.utils.cos(this.APP.sltRoofPitch.currentValue);
      let translateZ =
        this.utils.sin(this.APP.sltRoofPitch.currentValue) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        this.APP.sldBuildingHeight.currentValue -
        this.geo_RafterBeam.height /
          this.utils.cos(this.APP.sltRoofPitch.currentValue) +
        (this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue)) /
          2;
      let heightPlaneBottom =
        heightRealBottom * this.utils.cos(this.APP.sltRoofPitch.currentValue);
      let translateZBottom =
        this.utils.sin(this.APP.sltRoofPitch.currentValue) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height /
          this.utils.sin(this.APP.sltRoofPitch.currentValue) +
          this.geo_rafterBeamEndCap.length /
            this.utils.tan(this.APP.sltRoofPitch.currentValue)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(
        -Math.PI / 2 +
          this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height /
          this.utils.cos(this.APP.sltRoofPitch.currentValue) +
          this.geo_rafterBeamEndCap.length *
            this.utils.tan(this.APP.sltRoofPitch.currentValue)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };
    } else {
      let matEndCap = MaterialManager.Instance().BEAM.clone();

      let heightReal =
        this.APP.sldBuildingHeight.currentValue +
        (this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue)) /
          2;
      let heightPlane =
        heightReal * this.utils.cos(this.APP.sltRoofPitch.currentValue);
      let translateZ =
        this.utils.sin(this.APP.sltRoofPitch.currentValue) * heightPlane;

      let planeTop = new Plane(new Vector3(0, -1, 0));
      let matrixTranslateTop = new Matrix4().makeTranslation(
        0,
        heightPlane,
        -translateZ / 2
      );
      let matrixRotateTop = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      ); //tao voi z pitch do
      let matrixTotalTop = matrixTranslateTop.multiply(matrixRotateTop);
      planeTop.applyMatrix4(matrixTotalTop);

      let heightRealBottom =
        this.APP.sldBuildingHeight.currentValue -
        this.geo_RafterBeam.height /
          this.utils.cos(this.APP.sltRoofPitch.currentValue) +
        (this.utils.tan(this.APP.sltRoofPitch.currentValue) *
          (this.APP.sldSpan.currentValue +
            this.APP.sldMultiSpan.currentValue)) /
          2;
      let heightPlaneBottom =
        heightRealBottom * this.utils.cos(this.APP.sltRoofPitch.currentValue);
      let translateZBottom =
        this.utils.sin(this.APP.sltRoofPitch.currentValue) * heightPlaneBottom;

      let planeBottom = new Plane(new Vector3(0, 1, 0));
      let matrixTranslateBottom = new Matrix4().makeTranslation(
        0,
        heightPlaneBottom,
        -translateZBottom / 2
      );
      let matrixRotateBottom = new Matrix4().makeRotationX(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      ); //tao voi z pitch do
      let matrixTotalBottom =
        matrixTranslateBottom.multiply(matrixRotateBottom);
      planeBottom.applyMatrix4(matrixTotalBottom);

      matEndCap.clippingPlanes = [planeTop, planeBottom];

      capL = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capL.position.set(offsetXL - 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capL.scale.setY(
        (this.geo_RafterBeam.height /
          this.utils.cos(this.APP.sltRoofPitch.currentValue) +
          this.geo_rafterBeamEndCap.length *
            this.utils.tan(this.APP.sltRoofPitch.currentValue)) /
          this.geo_rafterBeamEndCap.height
      );
      capL.rotateY(Math.PI / 2);
      capL.rotateX(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
      capL.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: higherBraketName,
      };

      capR = new Mesh(this.geo_rafterBeamEndCap.geometry, matEndCap);
      capR.position.set(offsetXR + 2, 0, this.geo_rafterBeamEndCap.width / 2);
      capR.scale.setY(
        (this.geo_RafterBeam.height /
          this.utils.sin(this.APP.sltRoofPitch.currentValue) +
          this.geo_rafterBeamEndCap.length /
            this.utils.tan(this.APP.sltRoofPitch.currentValue)) /
          this.geo_rafterBeamEndCap.height
      );
      capR.rotateY(-Math.PI / 2);
      capR.rotateX(
        -Math.PI / 2 +
          this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
      capR.userData = {
        type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM_END_CAP,
        name: lowerBraketName,
      };
    }

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM,
      position: userDataPos,
      views: views,
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    if (userDataPos.front) {
      beamGroup.rotateZ(
        this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
    } else if (userDataPos.back) {
      beamGroup.rotateZ(
        -this.utils.degreesToRadians(this.APP.sltRoofPitch.currentValue)
      );
    }

    this.scene.add(beamGroup);
  }

  public addHorSuperiorBeam(offsetX, userDataPos: any) {
    let mesh = new Mesh(
      this.geo_superiorBeam.geometry,
      MaterialManager.Instance().BEAM
    );

    let offsetXL = 0;
    let offsetXR =
      this.APP.sldMultiSpan.currentValue +
      this.APP.sldSpan.currentValue -
      2 * this.geo_superiorBeam.width;
    let offsetZ = 0;
    let offsetY = 0;

    let scaleX =
      (this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        2 * this.geo_superiorPost.width) /
      this.geo_superiorBeam.length;

    let views: Print2DView[];

    if (userDataPos.front) {
      offsetY =
        this.APP.sldBuildingHeight.currentValue - this.geo_superiorBeam.height;
      offsetZ =
        this.APP.sldMultiSpan.currentValue +
        this.APP.sldSpan.currentValue -
        this.geo_superiorPost.width;

      views = [
        { viewType: ViewType.FRONT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.LEFT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.RIGHT, lineType: LineType.CONTINOUS },
        { viewType: ViewType.PLAN, lineType: LineType.DASHED },
      ];
    }

    mesh.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
    };
    mesh.position.set(offsetXL, 0, 0);
    mesh.scale.setX(scaleX);

    let capL = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capL.position.set(
      offsetXL + this.geo_beamEndCap.length / 2 - 2,
      0,
      this.geo_beamEndCap.width / 2
    );
    capL.rotateY(Math.PI / 2);
    capL.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        this.geo_beamEndCap.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };
    let capR = new Mesh(
      this.geo_beamEndCap.geometry,
      MaterialManager.Instance().BEAM
    );
    capR.position.set(
      offsetXR - this.geo_beamEndCap.length / 2 + 2,
      0,
      this.geo_beamEndCap.width / 2
    );
    capR.rotateY(-Math.PI / 2);
    capR.userData = {
      type: GEOMETRY_TYPE.SUPERIOR_BEAM_END_CAP,
      code: getBeamEndCapCode(
        this.geo_beamEndCap.name,
        HomeComponent.ins.sltColourBeam.currentBeamEndCapColorCode
      ),
    };

    let beamGroup = new Group();
    beamGroup.userData = {
      category: GEOMETRY_CATEGORY.PATIOS,
      type: GEOMETRY_TYPE.SUPERIOR_BEAM,
      position: userDataPos,
      views: views,
      name: "GB",
    };
    beamGroup.position.set(offsetX, offsetY, offsetZ);
    if (this.APP.sltBeamType.currentValue == 0) {
      beamGroup.add(mesh, capL, capR);
    } else {
      beamGroup.add(mesh);
    }

    beamGroup.rotateY(Math.PI / 2);

    this.scene.add(beamGroup);
  }

  public getSection(): Printing2DGeometry {
    let objs = this.scene.children.filter(
      (o) =>
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_POST ||
        o.userData.type == GEOMETRY_TYPE.FLY_OVER_BRACKET ||
        o.userData.type == GEOMETRY_TYPE.SUPERIOR_CENTER_POST
    );
    let beamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_BEAM
    );
    let beamObjs: Object3D[] = [];
    for (let g of beamGroups) {
      beamObjs = beamObjs.concat(g.children);
    }

    objs = [...objs, ...beamObjs];
    let lsGeometries: Printing2DLine[] = [];

    for (let o of objs) {
      let box = new BoxHelper(o);
      box.geometry.translate(0, 5000, 0);
      // box.userData = {type: "COLUMN_OUTLINE"};
      // this.scene.add(box);

      let outlineGeo = this.simplifyGeo(box.geometry as BufferGeometry);
      const planView = _.find(
        o.userData.views,
        (el) => el.viewType == ViewType.PLAN
      );
      const anotherViews = _.filter(
        o.userData.views,
        (el) => el.viewType != ViewType.PLAN
      );
      if (anotherViews.length > 0) {
        lsGeometries.push({
          objectType: o.userData.type,
          vertices: outlineGeo.vertices,
          views: anotherViews,
        });
      }
      if (planView) {
        lsGeometries.push({
          objectType: o.userData.type,
          vertices: outlineGeo.vertices
            .slice(0, 8)
            .filter((el, index) => [0, 1, 4, 5].includes(index)),
          views: [planView],
        });
      }
    }

    let rafterBeamGroups = this.scene.children.filter(
      (o) => o.userData.type == GEOMETRY_TYPE.SUPERIOR_RAFTER_BEAM
    );
    for (let o of rafterBeamGroups) {
      o.children.forEach((el) => {
        this.utils.getOutlineRafterBeam(el as Mesh, lsGeometries);
      });
    }
    return { lines: lsGeometries, texts: [] };
  }
  public simplifyGeo(geo: BufferGeometry): Geometry {
    let vertices = geo.getAttribute("position").array;
    let lineGeo = new Geometry();
    for (let i = 0; i < vertices.length; i += 3) {
      lineGeo.vertices.push(
        new Vector3(vertices[i], vertices[i + 1] - 5000, vertices[i + 2])
      );
    }

    //2-3
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    //3-7
    lineGeo.vertices.push(
      new Vector3(vertices[6], vertices[7] - 5000, vertices[8])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    //7-6
    lineGeo.vertices.push(
      new Vector3(vertices[18], vertices[19] - 5000, vertices[20])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    //6-2
    lineGeo.vertices.push(
      new Vector3(vertices[15], vertices[16] - 5000, vertices[17])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[3], vertices[4] - 5000, vertices[5])
    );

    //1-4
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    //4-8
    lineGeo.vertices.push(
      new Vector3(vertices[9], vertices[10] - 5000, vertices[11])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    //8-5
    lineGeo.vertices.push(
      new Vector3(vertices[21], vertices[22] - 5000, vertices[23])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    //5-1
    lineGeo.vertices.push(
      new Vector3(vertices[12], vertices[13] - 5000, vertices[14])
    );
    lineGeo.vertices.push(
      new Vector3(vertices[0], vertices[1] - 5000, vertices[2])
    );
    return lineGeo;
  }

  private getBracketHeight(offset): number {
    let angle = this.APP.sltRoofPitch.currentValue;
    return (
      this.APP.sldFlyOverBracketHeight.currentValue -
      this.utils.tan(angle) * offset
    );
  }

  public destroy(): void {
    this.unregisterEvent();
  }
  private registerEvent(): void {
    this.eventHandleId = this.uiChanged.bind(this);
    this.objectSizeChangedHandle = this.objectSizeChanged.bind(this);

    this.controlsToRegisterEvent = [
      this.APP.sldSpan,
      this.APP.sldBuildingHeight,
      this.APP.sltExistingType,
      this.APP.dialogEditBay,
      this.APP.sldLeftOverhang,
      this.APP.sldRightOverhang,
      this.APP.sltRoofPitch,
      this.APP.sldMultiSpan,
      this.APP.sldFlyOverBracketHeight,
      this.APP.sldBackOverhang,
      this.APP.sldFrontOverhang,
      this.APP.sltRoofThickness,
      this.APP.sltGutterType,
    ];
    //this.controlsToRegisterEvent.forEach(c => c.addAction(this.eventHandleId));

    this.controlsToRegisterEvent2 = [
      this.APP.sltBeamType,
      this.APP.sltBeamSize,
      this.APP.sltColumnType,
      this.APP.sltRoofPitch,
      this.APP.sltWindClass,
    ];
    this.controlsToRegisterEvent2.forEach((c) =>
      c.addAction(this.objectSizeChangedHandle)
    );
  }
  private unregisterEvent(): void {
    // this.controlsToRegisterEvent.forEach(c => c.removeAction(this.eventHandleId));
    // this.controlsToRegisterEvent = undefined;

    this.controlsToRegisterEvent2.forEach((c) =>
      c.removeAction(this.objectSizeChangedHandle)
    );
    this.controlsToRegisterEvent2 = undefined;
  }
  public uiChanged(preVal: number, curVal: number): void {
    this.load();
  }
  private objectSizeChanged(pre: number, cur: number) {
    this.optimize().then(() => {
      this.load();
    });
  }
}
